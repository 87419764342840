<template lang="pug">
footer.links
    small
        login-action(v-if="leftAction.type" :action="leftAction")
        span(v-if="leftAction.type && rightAction.type") &bull;
        login-action(v-if="rightAction.type" :action="rightAction")
</template>
<script>
export default {
    name: 'FooterAction',

    props: {
        branding: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            leftAction: this.branding.left_footer_action || {},
            rightAction: this.branding.right_footer_action || {}
        };
    }
};
</script>
