// Providers
import WindowProvider from '@/libs/providers/window';

// Services
import AccountService from '@/libs/services/account';
import BrandingService from '@/libs/services/branding';
import EventService from '@/libs/services/event';
import PublicLoginService from '@/libs/services/public-login';
import RoutingService from '@/libs/services/routing';
import SessionService from '@/libs/services/session';
import SessionsRegistrationService from '@/libs/services/sessions-registration';
import StorageService from '@/libs/services/storage';

export const services = {
    account: new AccountService(),
    branding: new BrandingService(),
    event: new EventService(),
    publicLogin: new PublicLoginService(),
    routing: new RoutingService(new WindowProvider()),
    session: new SessionService(),
    sessionsReg: new SessionsRegistrationService(),
    storage: new StorageService(window.localStorage)
};

export function createServices(Vue) {
    // Installing services
    Vue.prototype.$services = services;

    return services;
}
