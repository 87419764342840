/**
 * Provides an interface to the browser's window.location.
 */
export class WindowLocationProvider {
    /**
     * window.location.assign
     *
     * @param {string} url the URL to open
     */
    assign(url) {
        window.location.assign(url);
    }

    /**
     * window.location.reload
     */
    reload() {
        window.location.reload();
    }
}

/**
 * Provides an interface to the browser's window.
 *
 * @param {WindowLocationProvider} [location=new Location()] optional location object
 *
 * @example
 * import WindowProvider from 'libs/providers/window';
 * ...
 * const windowProvider = new WindowProvider();
 */
export default class WindowProvider {
    constructor(location) {
        this.location = location || new WindowLocationProvider();
    }

    open(...args) {
        window.open(...args);
    }
}
