import { object, times } from 'lodash';
import moment from 'moment';

export default {
    NOW: moment(),

    ALL_MONTHS: object(
        times(12, n => [
            moment()
                .month(n)
                .format('MMM'),
            []
        ])
    ),

    install(Vue) {
        Vue.prototype.$time = this;
    },

    toMomentDate(timestamp) {
        return moment(timestamp);
    },

    formatTimeInterval(start, end) {
        let formatted = start.format('ddd, MMM D');
        if (start.year() !== this.NOW.year()) {
            formatted += ' ' + start.year();
        }

        if (start.day() !== end.day()) {
            formatted += ' &ndash; ';

            if (start.month() !== end.month()) {
                formatted += end.format('MMM ');
            }

            formatted += end.format('D');
        }

        return formatted;
    }
};
