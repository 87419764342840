// External libraries
import { googleCalendarUrl } from '@app/utils/external-calendar-services';

// Constants
import { API_BASE_PATH } from '@/libs/utils/constants';

// Common url utils
export * from '@app/utils/url';

/** @const {string} REGISTRATION_PATH the registration page PATH */
const REGISTRATION_PATH = '/{{branding}}/{{pathName}}';

/** @const {string} CALENDAR_ICS_API_ENDPOINT the API endpoint to get the event's ICS file */
const CALENDAR_ICS_API_ENDPOINT = `${API_BASE_PATH}/eid/{{eid}}/calendar.ics?registration=true`;

/**
 * Build URL by interpolating given key value pairs
 *
 * @param {string} url the URL prototype to interpolate with replacements
 * @param {Object} [replacements={}] the replacements object
 *
 * @returns {string}
 */
export function interpolateUrl(url, replacements = {}) {
    return Object.keys(replacements).reduce((acc, key) => {
        return acc
            .replace(`{{${key}}}`, replacements[key])
            .replace(`{${key}}`, replacements[key]); // legacy format
    }, url);
}

/**
 * Given a branding and a path name this method builds
 * the corresponding registration page URL
 *
 * @param {string} branding the event ID
 * @param {string} pathName the registration path for the event
 * @param {Window} [browserWindow=window] the browser window object
 *
 * @returns {string} the URL to the registration page
 */
export function getRegistrationLink(branding, pathName, browserWindow = window) {
    const registrationUrl = `${browserWindow.APP.loginPage}${REGISTRATION_PATH}`;
    return interpolateUrl(registrationUrl, { branding, pathName });
}

/**
 * Given an ID this method returns the URL for downloading the ICS file
 *
 * @param {string} eid the event ID
 *
 * @returns {string} the URL for the ICS file
 */
export function getCalendarICS(eid) {
    return interpolateUrl(CALENDAR_ICS_API_ENDPOINT, { eid });
}

/**
 * Given a event configuration this method returns a
 * valid google calendar URL
 *
 * @param {object} config the event configuration
 * @param {string} config._id the unique ID for this event
 * @param {string} config.name the name for the event
 * @param {string} config.start the starting time of the event
 * @param {string} config.end the ending time of the event
 * @param {string} config.content the message to append to the calendar
 * @param {string} [config.location] the location of the event
 *
 * @param {string} [timezone] the desired timezone
 * @returns {string} a formatted URL for adding to google calendar
 */
export function getGoogleCalendarUrl(config, timezone) {
    return googleCalendarUrl(config, timezone);
}
