/**
 * Use this util to manipulate or validate strings.
 */

const Strings = {
    validateEmail(email) {
        return this.EMAIL_REG_EXP.test(String(email).toLowerCase());
    },

    // eslint-disable-next-line
    EMAIL_REG_EXP: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
};

export default {
    install(Vue) {
        Vue.prototype.$strings = Strings;
    }
};
