<template lang="pug">
.landing(:class="{ 'has-big-banner': fullWidthBanner }")
    main-header(:config="config" :session="session" :has-section-anchors="Boolean(sectionsWithAnchors.length)")
        .toggler.sm-down-only-inline(v-if="!legacy && sectionsWithAnchors.length" )
            button.btn.btn-icon.single(type="button" @click="menuIn = !menuIn" :aria-label="$t('registration.actions.open_menu').toString()" ref="menuToggler")
                i.icon-menu

        scrollactive.menu(v-if="sections.length > 1" :highlight-first-item="true" active-class="active" :modify-url="false" :offset="72" :class="{ in: menuIn }" ref="menu")
            template(v-if="legacy")
                a.btn.anchor.scrollactive-item(v-for="section in sections" :href="`#${section}`" :key="`${section}-anchor`")
                    | {{ $t(`registration.sections.${section}.title`) }}

            template(v-if="!legacy && sectionsWithAnchors.length")
                a.btn.anchor.scrollactive-item(v-for="section in sectionsWithAnchors" :href="`#${section.id}`" :key="`${section.id}-anchor`" @click="menuIn = false")
                    | {{ getSectionTitle(section) }}


            logout-button.anchor(v-if="showLogoutButton")

    main
        main-banner(:config="config" :full-width="fullWidthBanner")
        .container
            .panel.event-details(v-if="hasDescription")
                template(v-if="legacy")
                    h2.hidden {{ $t('registration.sections.description.title') }}
                    legacy-description(:config="config")

                template(v-else)
                    component(
                        v-for="section in sections"
                        :is="section.type"
                        :id="section.id"
                        :key="section.id"
                        :config="config"
                        :section="section")

            .panel.spotlight
                event-card(:config="config")

                update-profile-card(:config="config")

                registration-card(:config="config" :session="session")

    main-footer(v-if="config" :config="config")
</template>
<script>
// Utils
import { get, isEmpty } from 'lodash';

// Components
import Editorial from '@/components/registration/sections/Editorial.vue';
import EventCard from '@/components/registration/EventCard.vue';
import LegacyDescription from '@/components/registration/sections/LegacyDescription.vue';
import LogoutButton from '@/components/registration/LogoutButton.vue';
import MainBanner from '@/components/layout/MainBanner.vue';
import MainFooter from '@/components/layout/MainFooter.vue';
import MainHeader from '@/components/layout/MainHeader.vue';
import Presenter from '@/components/registration/sections/Presenter.vue';
import RegistrationCard from '@/components/registration/RegistrationCard.vue';
import Session from '@/components/registration/sections/Session.vue';
import Sponsor from '@/components/registration/sections/Sponsor.vue';
import UpdateProfileCard from '@/components/registration/UpdateProfileCard.vue';

export default {
    name: 'Landing',

    components: {
        Editorial,
        EventCard,
        LegacyDescription,
        LogoutButton,
        MainBanner,
        MainFooter,
        MainHeader,
        Presenter,
        RegistrationCard,
        Session,
        Sponsor,
        UpdateProfileCard
    },

    props: {
        config: {
            type: Object,
            required: true
        },

        session: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            hasDescription: true,
            legacy: true,
            sections: [],
            sectionsWithAnchors: [],
            menuIn: false,
            fullWidthBanner: get(this.config, 'settings.full_width_banner', true),
            showLogoutButton: false,
            clickawayHandler: ({ target }) => {
                // @ts-ignore
                const menu = this.$refs.menu.$el;
                const toggler = this.$refs.menuToggler;

                let el = target;
                let away = true;
                while (el.parentNode) {
                    if ([menu, toggler].includes(el)) {
                        away = false;
                        break;
                    }

                    el = el.parentNode;
                }

                // @ts-ignore
                this.menuIn = !away;
            }
        };
    },

    watch: {
        menuIn(value) {
            if (value) {
                document.body.addEventListener('click', this.clickawayHandler);
            } else {
                document.body.removeEventListener('click', this.clickawayHandler);
            }
        },

        config: {
            immediate: true,
            handler() {
                this.showLogoutButton =
                    this.$services.publicLogin.isLanguageSelectorVisible(this.config)
                    && this.$services.publicLogin.isAuthenticated(this.config)
                    && !this.$services.publicLogin.isRSVPPendingUser(this.config);
            }
        }
    },

    beforeDestroy() {
        document.body.removeEventListener('click', this.clickawayHandler);
    },

    created() {
        if (this.config.event_details.hasOwnProperty('sections') && this.config.event_details.sections.length) {
            this.sections = this.config.event_details.sections;
            this.sections.forEach((s, i) => s.id = `${s.type}-${i}`);
            this.sectionsWithAnchors = this.sections.filter(s => s.has_anchor).slice(0, 4);
            this.legacy = false;
        }

        this.hasDescription = !isEmpty(this.sections) || !isEmpty(get(this, 'config.event_details.description'));
    },

    methods: {
        /**
         * Gets the section title in the supported language
         *
         * @param {object} section the section to get the description for
         *
         * @returns {string} the localized section title
         */
        getSectionTitle(section) {
            return section.title[this.$i18n.locale] || section.title[this.$i18n.fallbackLocale];
        }
    }
};
</script>
