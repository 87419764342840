<template lang="pug">
.update-profile-card(v-if="enabled")
    h3 {{ $t('registration.welcome_back_user', [username]) }}

    .rsvp(v-if="showRsvp")
        p {{ $t('registration.update_profile.rsvp_question') }}

        .answers
            button.btn.btn-primary.btn-medium(type="button" @click="rsvpAnswer('yes')") {{ $t('registration.form.options.fp_rsvp_status.yes') }}
            button.btn.btn-medium(type="button" @click="rsvpAnswer('no')") {{ $t('registration.form.options.fp_rsvp_status.no') }}

    template(v-if="!showRsvp && canUpdate")
        p {{ $t('registration.update_profile.update_intro') }}
        button.btn.btn-primary.btn-medium(type="button" @click="submit") {{ $t('registration.update_profile.call_to_action') }}

    template(v-if="!showRsvp && !canUpdate")
        p {{ $t('registration.form.updates_not_allowed')}}
</template>
<script>
// Utils
import { get } from 'lodash';

export default {
    name: 'UpdateProfileCard',

    props: {
        config: {
            type: Object,
            required: true
        },
    },

    computed: {
        canUpdate() {
            return this.$services.publicLogin.canUpdateRegistration(this.config);
        },

        enabled() {
            const userLimmitReached = this.$services.publicLogin.isUserLimmitReached(this.config);
            const isUpdating = this.$services.publicLogin.isUpdating(this.config);
            return (this.canUpdate || isUpdating) && !userLimmitReached;
        },

        username() {
            return get(this.config, 'user.fname');
        },

        showRsvp() {
            const didntAnswer = this.$services.publicLogin.isRSVPPendingUser(this.config);
            return didntAnswer && this.$services.publicLogin.hasRsvp(this.config);
        }
    },

    methods: {
        rsvpAnswer(answer) {
            this.rsvp = answer;
            this.submit();
        },

        submit() {
            this.$router.push({
                name: 'registration-form',
                query: {
                    fp_rsvp_status: this.rsvp,
                    ...this.$route.query
                }
            });
        },
    }
}
</script>
