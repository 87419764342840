<template lang="pug">
.modal-wrapper.login-modal
    .modal-header
        button.close(type="button" @click="$emit('close')" :aria-label="$t('a11y.actions.close').toString()")
        h3 {{ title }}

    .modal-body
        template(v-if="!emailAuthDisabled")
            p(v-if="message") {{ message }}

        template(v-if="showForm")
            validation-observer(v-if="!emailAuthDisabled" tag="form" ref="form" @submit.prevent="login")
                fieldset
                    validation-provider(v-if="eventDetails.allow_non_unique_email" slim rules="required" v-slot="{ errors }" name="fname" mode="passive")
                        base-input(:errors="errors" v-model="fname" :disabled="disabled" :placeholder="$t('registration.modals.login.fname_placeholder')" name="fname" type="text" ref="input")

                    validation-provider(v-if="eventDetails.allow_non_unique_email" slim rules="required" v-slot="{ errors }" name="lname" mode="passive")
                        base-input(:errors="errors" v-model="lname" :disabled="disabled" :placeholder="$t('registration.modals.login.lname_placeholder')" name="lname" type="text" ref="input")

                    validation-provider(slim rules="required" v-slot="{ errors }" name="email" mode="passive")
                        base-input(:errors="errors" v-model="email" :disabled="disabled" :placeholder="$t('registration.modals.login.email_placeholder')" name="email" type="email" ref="input")

                    button.btn.btn-primary.btn-medium.important(:disabled="disabled") {{ $t('registration.modals.login.submit') }}

            .separator(v-if="!emailAuthDisabled && ssoProviders.length"): span {{ $t('registration.base.or') }}

            .sso(v-if="ssoProviders.length")
                a.btn.btn-medium.important(v-for="ssoProvider in ssoProviders"
                    :class="emailAuthDisabled ? 'btn-primary' : 'btn-default'"
                    :key="ssoProvider.id"
                    :href="ssoProvider.url")
                    | {{ $t('registration.form.sso_login_button_title', [ssoProvider.label]) }}

</template>
<script>
// Components
import BaseInput from '@/components/form-elements/BaseInput.vue';

export default {
    name: 'LoginModal',

    components: {
        BaseInput
    },

    props: {
        eventDetails: {
            type: Object,
            required: true
        },

        settings: {
            type: Object,
            required: true
        }
    },

    data() {
        const config = {
            settings: this.settings,
            eventDetails: this.eventDetails,
        };

        return {
            emailSent: false,
            disabled: false,
            email: null,
            fname: null,
            lname: null,
            error: false,
            emailAuthDisabled: this.$services.publicLogin.isEmailAuthDisabled(config),
            ssoProviders: this.$services.publicLogin.getSsoProviders(config)
        };
    },

    computed: {
        /** @returns {import('vue-i18n').TranslateResult} */
        title() {
            if (this.emailSent) {
                return this.$i18n.t('registration.modals.login.email_sent_title');
            }
            return this.$i18n.t('registration.modals.login.title');
        },

        /** @returns {boolean} */
        showForm() {
            return !this.emailSent && !this.error;
        },

        /** @returns {import('vue-i18n').TranslateResult|undefined} */
        message() {
            if (this.emailSent) {
                return this.$i18n.t('registration.modals.login.email_sent_text');
            }

            if (this.error) {
                return this.$i18n.t('login.error-internal-error');
            }

            return this.$i18n.t('registration.modals.login.description');
        }
    },

    mounted() {
        // @ts-ignore
        this.$refs.input?.focus();
    },

    methods: {
        /**
         * Tries to perform the user's login
         */
        async login() {
            try {
                this.disabled = true;
                // @ts-ignore
                if (!await this.$refs.form.validate()) return;
                console.info('[LoginModal] Performing the login for user:', this.email);

                const branding = this.$route.params.branding;
                const eid = this.eventDetails._id;
                this.emailSent = await this.$services.publicLogin.requestLogin(branding, eid, this.email, this.fname, this.lname);

            } catch (error) {
                console.error('[LoginModal] An error occurred while logging in:', error.message);
                this.error = true;
            }
        }
    }
}
</script>
