<template lang="pug">
.empty-event-list
    svg(viewBox="0  0 348 247" fill="none" xmlns="http://www.w3.org/2000/svg")
        defs
            clipPath(id="a")
            path(fill="#fff" d="M54 105h25v25H54z")
        circle(cx="93.8" cy="153" r="93.8" fill="#fff" fill-opacity=".4")
        circle(cx="254.2" cy="122.4" r="93.8" fill="#fff" fill-opacity=".4")
        path( fill-rule="evenodd" clip-rule="evenodd" d="m147.2 101.9 19 65.5 9 49.2h9l-9-114.7h-28Z" fill="#BA8B72")
        path( fill-rule="evenodd" clip-rule="evenodd" d="M138.7 101.9c1.3 34 .8 52.6.5 55.7-.3 3-2 23.8-13.5 60h9.3c15.3-34.8 20.6-55.5 22.3-60 1.8-4.6 7-23.2 14.4-55.7h-33Z" fill="#D4A181")
        path( fill-rule="evenodd" clip-rule="evenodd" d="M146.8 101.9c5 22.5 13.5 58.5 25.7 108h13.1c1-50.8-3.2-84.2-10.3-108h-28.5Z" fill="#69A1AC")
        path( fill-rule="evenodd" clip-rule="evenodd" d="M139.4 101.9c1.3 34-2.1 67.5-12.5 108.5h14a459.6 459.6 0 0 0 33.6-108.5h-35.1Z" fill="#89C5CC")
        path( fill-rule="evenodd" clip-rule="evenodd" d="m124.4 224.4.4-7.7c0-.3.4-.6.7-.5 4.5 1 10.7-1.2 10.7-1.2a51.9 51.9 0 0 0 18.5 7c.6.2 1 .8.8 1.4l-.7 2.5H125.4l-1-1.5ZM173.2 224.4l.4-7.7c0-.3.4-.6.7-.5 4.5 1 10.7-1.2 10.7-1.2a52 52 0 0 0 18.5 7c.6.2 1 .8.8 1.4l-.7 2.5H174.2l-1-1.5Z" fill="#191847")
        circle(cx="226.9" cy="57.4" r="9.7" stroke="#4B4B5A" stroke-width="3")
        path( d="m222.2 67.6-5 14.9" stroke="#4B4B5A" stroke-width="5" stroke-linecap="round")
        path( d="m191.4 83.8 18.8-8.3c3.3-3.3 4.4-5.2 7.5-7 .9-.2 2-.7 1.5.8-1.3 2.5-2 5.9-1.3 6.7.2.4.8.4 1.4.5.7 0 1.5 0 2 .8.4 1-2.8 2-9.9 3.4l-14 10.7-6-7.6Z" fill="#B28B67")
        path( fill-rule="evenodd" clip-rule="evenodd" d="m158.3 47.4 4.3-.7c9.3 9 13.1 30.9 19.4 33.8 5.9 2.7 14.3 0 24.2-3.5l3 6.8c-9 9.8-28.2 18-36 13.3-12.5-7.5-15.4-34.4-15-49.7Z" fill="#E87613")
        path( fill-rule="evenodd" clip-rule="evenodd" d="M160.4 38.3c2.2 3 4.8 5.1 6.4 5.3 3.8.3 9.6-13 9-19.3-.5-6.3-13.4-13.9-18.3-3.6a14.6 14.6 0 0 0-.6 10.8l-11.5 17.4 11.2 4.2 3.8-14.8Z" fill="#B28B67")
        path( fill-rule="evenodd" clip-rule="evenodd" d="M183.7 19c.2-1-.2-2-1-2.5l-.5-.3h-.2v-.3a3.7 3.7 0 0 0-.2-2c-.4-1-1.1-1.9-2-2.3-.5-.2-1-.4-1.6-.4h-1s-.3.2-.4.1l-.2-.3c-.5-1-1.4-1.6-2.4-2-1.2-.4-2.3-.8-3.5-.4a7.2 7.2 0 0 0-1.4.6h-.2c-.4-.5-.8-.8-1.4-1H166l-.3-.4c-.5-.4-1.1-.7-1.8-.8a5 5 0 0 0-2 0l-1 .4-.3.3h-.3l-.6-.6a2 2 0 0 0-1.1-.3c-.8 0-1.5.2-2.2.6a5.5 5.5 0 0 0-1.4 1l-.2.2h-.2a3.6 3.6 0 0 0-3.2 1.8l-.1.2h-.2a3.8 3.8 0 0 0-1.3-.1c-1.1 0-2.1.7-2.8 1.6a5.4 5.4 0 0 0-1.3 3.9v.2l-.3.2-.8 1-.8 1.9a3 3 0 0 0 0 1.4l.1.2.1.1v.2l-.4.4a9.4 9.4 0 0 0-1.1 1.5c-.4.7-.7 1.4-.7 2.2a2.2 2.2 0 0 0 .6 1.4l.2.2-.2.3c-.4.7-.8 1.4-.8 2.2-.1.7 0 1.5.5 2a3.7 3.7 0 0 0 3.6 1.6l1-.3c.3 1.1 1.6 1.6 2.6 1.7 1.2 0 2.5-.4 3.5-1.3.3 1.2 2 1.5 2.8.7l.6-.7c0-.1 0-.3.2-.4 0-.2.2-.3.4-.5.2-.3.5-.6.6-1l.2-.5c0-.2.2-.3.3-.5-.4 0-.7-.3-1-.5-.2-.1-.3-.5-.4-.7-.3-.7-.3-1.4-.2-2.1.1-1.6 1-3.1 2.6-3.4a7 7 0 0 1 3.3 1c.7.5.9 2.6.8 2.8v.4c.2.2.6 0 .8 0 .4-.1.7-.4 1-.6.6-.5 1.1-1.1 1.6-1.8l1.1-1.5c.4-.5 1-.8 1.6-1h2c.6 0 1.3 0 1.9-.3l.4-.2.3-.3c.2 0 .3.3.4.4a6 6 0 0 1 1 2.3l.2 2.3v1.9c.1.2 1.3.8 1.8.8.4 0 1-.3 1.3-.5.2-.3.4-.6.4-.9l.1-.6v-.4a1 1 0 0 0-.4-.8c.4-.1.8-.4 1-.7l1-1c.1-.4.2-.7.1-1.1 0-.4-.3-.7-.5-1 .6-.2 1.1-.4 1.6-.8 1-.7 1.8-1.7 2-3Z" fill="#191847")
        path( fill-rule="evenodd" clip-rule="evenodd" d="M117.7 124.2c1.5-3 6.7-33.1 6.7-33.1h10.9s-10 32.4-10.9 34.4c-1 2.6.7 6.3 2 8.8l.4 1.1c-1.6.8-2.4-.1-3.3-1-1-1.1-2-2.3-4.3-1.2l-2.6 1.4c-3 1.7-5.6 3.3-9.2 1-.6-.3-1.3-1.7.3-2.7a49 49 0 0 0 10-8.7Z" fill="#B28B67")
        path( fill-rule="evenodd" clip-rule="evenodd" d="M135.7 102h39.6c1.8 0 1.5-2.7 1.2-4-3-15.2-14.8-31.6-14.8-53.3l-11.4-1.8c-9.5 15.2-12.8 34-14.6 59Z" fill="#DDE3E9")
        path( fill-rule="evenodd" clip-rule="evenodd" d="M131.2 112.8c-1.8 4.3-3.5 8-5.2 11h-8.5a111 111 0 0 1 19.7-66.1c-2.1-.2-4-1-5.2-3.2-4.1-7.5-2.6-11.7 1.8-13 2.5-.8 4.8-.3 7.6.3 2.2.5 4.8 1 8 1l.9.1 3.8.3s15.4 52.2 8.5 69.6h-31.4Z" fill="#FF9B21")
        path( fill-rule="evenodd" clip-rule="evenodd" d="M131.2 112.8c2.3-5.6 4.7-12.1 7-18.9 1 7.5 2.3 15 4.7 19h-11.7Z" fill="#000" fill-opacity=".1")
        path( opacity=".3" fill-rule="evenodd" clip-rule="evenodd" d="M64.2 200c3.7-1 6-4.8 5-8.4a6.9 6.9 0 0 0-8.4-4.8c-3.8 1-6 4.7-5 8.3.9 3.6 4.6 5.8 8.4 4.8Z" fill="#2B44FF")
        g(clip-path="url(#a)" fill="#BBEDCF")
            path(d="M54.8 110.2c-.5 0-.8.4-.8.8v13c0 .4.3.8.8.8h14c.5 0 .8-.4.8-.8v-13c0-.4-.3-.8-.8-.8h-14ZM77.6 111.6l-5.2 1.5a1 1 0 0 0-.7 1v6.8c0 .4.3.8.7 1l5.2 1.4a1 1 0 0 0 1.4-1v-9.7a1 1 0 0 0-1.4-1Z")
        path( fill-rule="evenodd" clip-rule="evenodd" d="M316.8 71.6v-1.3h-2.2a.7.7 0 0 1 0-1.4h2.2v-2h-2.2a.7.7 0 0 1 0-1.5h2.2c0-2.4-2-4.3-4.3-4.3H311c-2.4 0-4.3 2-4.3 4.3h2.2a.7.7 0 0 1 0 1.4h-2.2v2h2.2a.7.7 0 0 1 0 1.5h-2.2v1.3c0 2.1 1.7 3.9 3.8 3.9h2.5c2.1 0 3.9-1.7 3.9-3.9Zm-8.8 12c0 .4.4.8.8.8h5.8a.7.7 0 0 0 0-1.5h-2.2v-4.4h.7c3.7 0 6.6-3 6.6-6.5v-3a.7.7 0 0 0-1.4 0v3c0 2.8-2.3 5-5.2 5h-2.9a5.1 5.1 0 0 1-5-5v-3a.7.7 0 0 0-1.6 0v3c0 3.6 3 6.5 6.6 6.5h.8V83h-2.2c-.4 0-.8.3-.8.7Z" fill="#F7D9B6")
        path( fill-rule="evenodd" clip-rule="evenodd" d="M225 146.2h15.7c1 0 1.8.8 1.8 1.8v12c0 1-.8 1.9-1.8 1.9h-12l-4.6 3.5c-.4.3-1 0-1-.4v-17.1c0-1 .9-1.7 1.9-1.7Zm7.8 9.6a3.4 3.4 0 1 0-3.4-3.4 1 1 0 0 0 2 .1 1.4 1.4 0 1 1 1.5 1.4 1 1 0 1 0 0 2Zm0 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" fill="#BBC2FC")
        path( opacity=".3" fill-rule="evenodd" clip-rule="evenodd" d="M24.5 69.3a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17Z" fill="#2DD269")
        path( opacity=".3" fill-rule="evenodd" clip-rule="evenodd" d="M97 7.8a4.6 4.6 0 0 0 5.7 3.1 4.6 4.6 0 0 0 3-5.7A4.6 4.6 0 0 0 100 2a4.6 4.6 0 0 0-3 5.8ZM301.7 181a4.6 4.6 0 0 0 5.6 3.1 4.6 4.6 0 0 0 3-5.7 4.6 4.6 0 0 0-5.7-3.2 4.6 4.6 0 0 0-2.9 5.8ZM226 97.3l-.8-8 8-.7.8 8-8 .7Z" fill="#FF9B21")
    p {{$t('events.no_events')}}
</template>
<script>
export default {
    name: 'EmptyEventList',
};
</script>