<template lang="pug">
.paragraph(v-if="text" v-html="text")
</template>
<script>
export default {
    name: 'Paragraph',

    props: {
        text: {
            type: String,
            default: null
        }
    }
}
</script>
