<template lang="pug">
control-decorator(:label="label" :tip="tip" :hint="hint" :uid="uid" :errors="errors")
    multiselect.form-control(
        ref="select"
        @input="input"
        @open="open"
        :value="innerValue"
        v-on="listeners"
        v-bind="attrs"
        :class="{'has-error': errors.length}"
        :id="uid"
        :label="optionsLabel")

        template(#caret="{ toggle }")
            .toggler
                i.icon-chevron-down(@mousedown.prevent.stop="toggle")

        template(#noResult="") {{ $t('general.no_elements') }}

</template>
<script>
// Utils
import { isArray } from 'lodash';

// Components
import BaseControl from './BaseControl.vue';

/**
 * Base component to wrap select with a common markup.
 *
 * Note that this is a wrapper component to VueMultiselect: for this purpose we
 * bind attributes and listeners directly to the plugin component via `v-bind` and `v-on`.
 * This is basically how you do pass-through in Vue.js.
 *
 * It has all `BaseControl` options, plus all vue-multiselect props, plus:
 *
 * @vue-prop {String} [optionsLabel=null] the key path to use for the label of every option, if not found the value field is used
 *
 * @example
 *
 * base-select(:label="t('field')" :placeholder="t('field_placeholder')" name="field" v-model="form.field" validations="required" :options="[]")
 *
 * @see https://vue-multiselect.js.org/#sub-props
 *
 * @category ui.components.form-elements
 * @exports BaseSelect
 * @component
 */
export default {
    name: 'BaseSelect',

    extends: BaseControl,

    inheritAttrs: false,

    props: {
        // Have to overwrite this here because BaseControl
        // declares one.
        value: {
            type: [Object, Array, String, Number],
            default: null
        },

        optionsLabel: {
            type: String,
            default: 'label'
        },

        options: {
            type: Array,
            default: () => []
        },

        trackBy: {
            type: String,
            default: null
        },

        emitTracked: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        // Pass-through listeners
        /** @returns {{} | { get?(): any; set?(value: any): void; cache?: boolean; }} */
        listeners() {
            // Here we skip the `input` listener, since we provide our own
            // and we don't want it to be overwritten.

            //@ts-ignore
            const { input, ...listeners } = this.$listeners;

            return listeners;
        },

        // Pass-through attributes
        /** @returns {{ [x: string]: string; }} */
        attrs() {

            /* eslint-disable no-unused-vars */

            // Here we remove all the keys that we don't want `multiselect`
            // to inherit from this very component. All other options and attributes
            // are passed through. Basically an underscore.pluck, but since it has been
            // deprecated we are doing it manually.
            const {
                label,
                hint,
                focused,
                optionsLabel,
                value,
                ...attrs
            } = Object.assign(this.$options.propsData, this.$attrs);
            /* eslint-enable no-unused-vars */

            // @ts-ignore
            attrs.options = isArray(attrs.options) ? attrs.options : [];

            return attrs;
        },

        /** @returns {object} */
        innerValue() {
            if (!this.value) return undefined;
            if (typeof this.value === 'string') {
                return this.options.find(o => o.value === this.value);
            }

            return this.value;
        }
    },

    methods: {
        /**
         * Triggered when user interacts with input components.
         *
         * @overwrites BaseControl.input
         *
         * @param {Object} payload the selected payload
         */
        input(payload) {
            let value = payload;
            if (this.hasDefault && !payload) {
                value = this.innerValue;
            }

            if (value === null || value === undefined) return;
            this.$emit('input', this.emitTracked ? value[this.trackBy] : value);
        },

        /**
         * Tries to grab the focus on the input element
         */
        attemptFocus() {
            const select = this.$refs.select;

            if (this.focused && select) {
                select.$refs.search.focus();
            }
        },

        /**
         * Fixes an issue with vue-multiselect where the pointer doesn't start on the highlighted element.
         * @param {*} payload
         */
        open(payload) {
            try {
                const select = this.$refs.select;
                if (select && this.value) {
                    let index;
                    if (typeof this.value === 'string') {
                        index = this.options.indexOf(this.value);
                    } else {
                        index = this.options.findIndex(option => option[this.trackBy] === this.value[this.trackBy]);
                    }
                    if (index && index >= 0) {
                        select.pointerSet(index);
                    }
                }
                this.$emit('open', payload);
            } catch (err) {
                console.error('[vue-multiselect] error on "open"', err)
            }
        }
    }
};
</script>
