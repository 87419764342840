<template lang="pug">
.standard-plus-login
  h2 {{$t(page.title)}}
  p.intro {{$t(page.text)}}
  .form-group
    label {{$t(page.pax_field_label)}}
    input.form-control.validate(
      type="text"
      v-model="paxValue"
      :class="{ 'is-invalid': paxValueError }"
      :disabled="submitting"
      :placeholder="$t(page.email_placeholder)"
      autocomplete="off"
      required
      @keyup="touch('paxValue')")
    .invalid-feedback(v-if="loginErrors")
      p(v-for="(error) in loginErrors") {{$t(error)}}


  .form-group
    label {{$t(page.password_label || 'login.act_code')}}
    input.form-control.validate(
      type="text"
      v-model="actCode"
      :class="{ 'is-invalid': actCodeError }"
      :disabled="submitting"
      :placeholder="$t(page.password_placeholder)"
      maxlength="255",
      autocomplete="off"
      required
      @keyup="touch('actCode')")
    .invalid-feedback
      p {{$t('login.wrong_provided_values')}}

</template>
<script>
/** Also called event login */

import { LoginException } from '@/exceptions';

export default {
    name: 'StandardPlus',

    props: [ 'page' ],

    data() {
        return {
            paxValue: '',
            paxValueError: false,
            actCode: '',
            actCodeError: false,
            type: this.page.type,
            submitting: false,
            loginErrors: [],
            touched: { paxValue: false, actCode: false }
        };
    },

    watch: {
        paxValue() {
            this.validate();
        },
        actCode() {
            this.validate();
        }
    },

    activated() {
        if (this.page.login_button_title) {
            this.$emit('nextChanged', this.page.login_button_title);
        }

        this.$emit('toggleLegals', true);
    },

    methods: {
        touch(field) {
            this.touched[field] = true;
        },

        validate() {
            this.loginErrors = [];
            let valid = true;

            if (this.touched['paxValue']) {
                if (this.paxValue.length === 0) {
                    valid = false;
                    this.paxValueError = true;
                } else {
                    this.paxValueError = false;
                }
            }

            if (this.touched['actCode']) {
                if (this.actCode.length <= 4) {
                    this.actCodeError = true;
                    valid = false;
                } else {
                    this.actCodeError = false;
                }
            }

            this.$emit('validity', valid);
            return valid;
        },

        onRequestSuccess(response) {
            this.$track.endTrackLogin(true);
            const url = response.body.url;
            if (url && url.length) {
                this.$services.routing.redirect(url);
            } else {
                return this.onRequestError(response);
            }
        },

        onRequestError({ body }) {
            this.actCodeError = true;
            this.loginErrors.push('login.email_send_failed');
            throw new LoginException(body.error, body.error_code, this.type);
        },

        getWebappLink() {
            this.submitting = true;
            return this.$http
                .get('/api/v1/webapp/login-link', {
                    params: {
                        pax_value: this.paxValue,
                        pax_field: this.page.pax_field,
                        actcode: this.actCode,
                        app_id: this.$const.APP_ID
                    }
                })
                .then(a => a, this.onRequestError)
                .finally(() => (this.submitting = false));
        },

        submit() {
            if (!this.validate()) {
                return false;
            }
            return this.getWebappLink().then(this.onRequestSuccess);
        }
    }
};

</script>
