<template lang="pug">
.sessions-registration(:class="{ 'has-errors': hasErrors }")
    expandable-content(v-if="text" :start-expanded="startExpanded || hasErrors")
        template(#title="{}")
            paragraph(:text="text")

        session-control(
            v-for="session in sessions"
            :config="config"
            :disabled="isControlDisabled"
            :errors="innerErrors[session.fp_ext_id]"
            :key="`${uid}-${session._id}`"
            :name="name"
            :orchestrator="orchestrator"
            :session="session"
            :value="innerValue[session.fp_ext_id]"
            @error="setError"
            @input="value => setValue(session, value)"
        )

    template(v-else)
        session-control(
            v-for="session in sessions"
            :config="config"
            :disabled="isControlDisabled"
            :errors="innerErrors[session.fp_ext_id]"
            :key="`${uid}-${session._id}`"
            :name="name"
            :orchestrator="orchestrator"
            :session="session"
            :value="innerValue[session.fp_ext_id]"
            @error="setError"
            @input="value => setValue(session, value)"
            @waitlist-updated="$emit('waitlist-updated')"
        )

    p.help.error(v-if="errors.length")
        i.icon-warning-fill
        | {{ $t(errors[errors.length - 1]) }}

</template>
<script>
// Components
import Checkbox from '@/components/form-elements/Checkbox.vue';
import Paragraph from '@/components/form-elements/Paragraph.vue';
import SessionControl from '@/components/form-elements/SessionControl.vue';
import ExpandableContent from '@/components/common/ExpandableContent.vue';

export default {
    name: 'SessionsRegistration',

    components: { Checkbox, ExpandableContent, Paragraph, SessionControl },

    props: {
        config: {
            type: Object,
            required: true
        },

        control: {
            type: Object,
            required: true
        },

        name: {
            type: String,
            required: true
        },

        orchestrator: {
            type: Object,
            required: true
        },

        startExpanded: {
            type: Boolean,
            default: true
        },

        disabled: {
            type: Boolean,
            default: false
        },

        value: {
            type: Array,
            default: () => []
        },

        errors: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            component: SessionControl,
            innerErrors: {},
            innerValue: {},
            text: this.control.content[this.$i18n.locale] || this.control.content.en,
            sessions: this.orchestrator.getMatchingSessions(this.control),
        };
    },

    computed: {
        uid() {
            return this._uid;
        },

        isControlDisabled() {
            return this.disabled || this.control.disabled;
        },

        hasErrors() {
            const errorsCount = Object.keys(this.innerErrors).length + this.errors.length;
            return errorsCount > 0;
        }
    },

    watch: {
        value: {
            immediate: true,
            handler() {
                this.innerValue = {};
                this.setSelectedValues(this.value);
            }
        }
    },

    methods: {
        /**
         * Sets the selected values for the component.
         *
         * @param {string[]} values - An array of fp ext ids to be set as selected.
         */
        setSelectedValues(values) {
            values.forEach(fp_ext_id => {
                this.innerValue[fp_ext_id] = true;
            });
        },

        /**
         * Sets the error for a session.
         *
         * @param {string[]} errors - The error messages.
         * @param {import('@/libs/utils/models/session').default} session - The session object.
         */
        setError(errors, session) {
            if (errors?.length > 0) {
                console.warn('[SessionsRegistration] Error(s) detected', errors, session);
                this.$set(this.innerErrors, session.fp_ext_id, errors);
            } else {
                this.$delete(this.innerErrors, session.fp_ext_id);
            }
        },

        /**
         * Sets the value of a session.
         *
         * @param {import('@/libs/utils/models/session').default} session - The session object.
         * @param {any} value - The value to set for the session.
         */
        setValue(session, value) {
            const selected = value && this.orchestrator.selectSession(session);

            if (!selected) {
                this.orchestrator.unselectSession(session);
            }

            this.$set(this.innerValue, session.fp_ext_id, selected);

            this.$emit('input', {
                register: this.orchestrator.getAllSelectedIds(),
                unregister: this.orchestrator.getAllUnregisterIds()
            });
        }
    }
};
</script>
