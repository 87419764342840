<template lang="pug">
.password-score
    .score-tab(:data-score="score")
        small.score {{ $t(scoreLabel) }}
</template>
<script>
// Utils
import { debounce } from 'lodash';

const PASSWORD_STRENGTH_LABELS = [
    'login.password_too_weak',
    'login.password_weak',
    'login.password_strong',
    'login.password_very_strong',
    'login.password_very_strong'
];

export default {
    name: 'PasswordMeter',

    props: {
        password: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            score: -1,
            cache: []
        };
    },

    computed: {
        scoreLabel() {
            return PASSWORD_STRENGTH_LABELS[this.score] || '';
        }
    },

    watch: {
        password() {
            if (typeof this.cache[this.password] !== 'undefined') {
                this.score = this.cache[this.password];
                return this.password;
            }

            if (this.password.length > 0) {
                this.check(this);
            } else {
                this.score = -1;
            }
        }
    },

    methods: {
        check: debounce((self) => {
            self.checkStrength.apply(self);
        }, 200, { maxWait: 1000 }),

        /**
         * Checks the strength of the password.
         *
         * @async
         * @returns {Promise<void>} A promise that resolves once the strength check is complete.
         */
        async checkStrength() {
            try {
                const { score } = await this.$services.account.checkPasswordStrength(this.password);
                this.score = score;
                this.cache[this.password] = score;
            } catch (error) {
                this.score = -1;
            }
        }
    }
};
</script>
