<template lang="pug">
section.description(v-html="legacyDescription")
</template>
<script>
// Mixins
import section from './section.mixin';

// Utils
import { get } from 'lodash';

export default {
    name: 'LegacyDescription',

    mixins: [section],

    computed: {
        /** @returns {string} */
        legacyDescription() {
            return get(this, 'config.event_details.description', '');
        }
    }
}
</script>
