<template lang="pug">
.loading(:class="{in: running}")
    .spinner
        .pulsar.p1
        .pulsar.p2
        .pulsar.p3
</template>
<script>
export default {
    name: 'Loading',

    created() {
        window.addEventListener('loading:start', () => this.running = true);
        window.addEventListener('loading:stop', () => this.running = false);
    },

    data() {
        return {
            running: false
        };
    }
};

</script>
