// Utils
import moment from 'moment-timezone';
import { getFirstEligibleLocale } from './locales';

// Type definitions

/**
 * @typedef {Object} FormattedEventDates
 *
 * @property {string} startTime the formatted start time
 * @property {string} [endTime] the formatted end time
 * @property {string} timezone the human readable timezone
 * @property {string} [day] the formatted month day
 * @property {string} [endDay] the formatted month day (end date)
 */

/**
 * Builds an object containing the formatted dates
 *
 * @param {string} locale the locale to use for the formatting
 * @param {number} startTs the starting unix timestamp
 * @param {number} endTs the ending unix timestamp
 * @param {string} [eventTimezone] the event's timezone, if not provideded the user's TZ is used
 * @param {string} [userTimezone] optional user's timezone
 * @param {boolean} [useEventTimezone=false] whether to show the dates in the event's timezone
 * @param {number} [currentYear] optional year to compare dates to
 *
 * @returns {FormattedEventDates} an object containing the formatted part of the dates
 */
export function formatEventDates(locale, startTs, endTs, eventTimezone, userTimezone, useEventTimezone = false, currentYear = new Date().getFullYear()) {
    const startDate = new Date(startTs * 1000);
    const endDate = new Date(endTs * 1000);

    userTimezone = userTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    eventTimezone = eventTimezone || userTimezone;

    const timeZone = useEventTimezone ? eventTimezone : userTimezone;
    const options = {
        timeZone,
        month: 'short',
        day: 'numeric'
    };

    // We use either event timezone or usertimezone to determine if it's same day or not to be coherent with the display & with the settings.
    const eventStartDate = moment(startDate).tz(timeZone);
    const eventEndDate = moment(endDate).tz(timeZone);
    const sameYear = startDate.getFullYear() === endDate.getFullYear();
    const thisYear = startDate.getFullYear() === currentYear;
    const sameDay = eventStartDate.isSame(eventEndDate, 'day');

    if (!sameYear || !thisYear) {
        options.year = 'numeric'; // if years are different then display them
    }

    let endDateOptions = options;
    let abbr = '';
    if (sameDay) {
        // Show the time
        options.hour = 'numeric';
        options.minute = 'numeric';

        // @ts-ignore
        endDateOptions = {
            timeZone,
            hour: 'numeric',
            minute: 'numeric'
        };

        if (eventTimezone !== userTimezone) {
            // Specify the friendly abbreviation of the user's TZ
            abbr = moment(startDate).tz(timeZone).zoneAbbr();
            if (abbr.startsWith('+') || abbr.startsWith('-')) {
                abbr = `GMT${abbr}`;
            }
        }
    }

    return {
        // @ts-ignore
        startDate: Intl.DateTimeFormat(locale, options).format(startDate),
        // @ts-ignore
        endDate: sameDay ? Intl.DateTimeFormat(locale, endDateOptions).format(endDate) : Intl.DateTimeFormat(locale, options).format(endDate),
        timezone: abbr
    };
}

/**
 * Builds an object containing the formatted dates
 *
 * @param {string} locale the locale to use for the formatting
 * @param {number} startTs the starting unix timestamp
 * @param {number} endTs the ending unix timestamp
 * @param {string} [eventTimezone] the event's timezone, if not provideded the user's TZ is used
 * @param {boolean} [useEventTimezone=false] whether to show the dates in the event's timezone
 *
 * @returns {FormattedEventDates} an object containing the formatted part of the dates
 */
export function formatSessionTimes(locale, startTs, endTs, eventTimezone, useEventTimezone = false) {
    const startDate = new Date(startTs * 1000);
    const endDate = new Date(endTs * 1000);
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    eventTimezone = eventTimezone || userTimezone;

    const timeZone = useEventTimezone ? eventTimezone : userTimezone;
    const options = {
        timeZone,
        hour: 'numeric',
        minute: 'numeric'
    };

    let abbr = '';
    if (eventTimezone !== userTimezone) {
        // Specify the friendly abbreviation of the user's TZ
        abbr = moment(startDate).tz(timeZone).zoneAbbr();
        if (abbr.startsWith('+') || abbr.startsWith('-')) {
            abbr = `GMT${abbr}`;
        }
    }

    return {
        day: startDate.toLocaleString(locale, { month: 'short', day: 'numeric', timeZone }),
        endDay: endDate.toLocaleString(locale, { month: 'short', day: 'numeric', timeZone }),
        // @ts-ignore
        startTime: startDate.toLocaleString(locale, options),
        // @ts-ignore
        endTime: endDate.toLocaleString(locale, options),
        timezone: abbr
    };
}

/**
 * Returns the current instant
 *
 * @returns {import('moment-timezone').Moment} the current instant
 */
export function now() {
    return moment();
}

/**
 * Transform a unix timestamp into a manipulable object
 *
 * @param {number} timestamp the unix timestamp to transform
 *
 * @returns {import('moment-timezone').Moment}
 */
export function toMomentDate(timestamp) {
    return moment(timestamp);
}

/**
 * Gets the localized date format based on the browser configuration
 *
 * @param {String} [locale] the desired locale. If not provided the system will try to guess.
 * @param {import("moment").LongDateFormatKey} [format='L'] the generic desired format
 *
 * @return {String} the specified date format
 */
export function getBrowserDateFormat(locale, format = 'L') {
    if (!locale) {
        locale = getFirstEligibleLocale({ locales: moment.locales() });
    }

    moment.locale(locale);
    const localeData = moment.localeData();

    return localeData.longDateFormat(format);
}

/**
 * Performs a difference between the two given times
 *
 * @param {String|Date|import('moment-timezone').Moment} timeA starting time
 * @param {String|Date|import('moment-timezone').Moment} timeB ending time
 * @param {'years'|'months'|'weeks'|'days'|'hours'|'minutes'|'seconds'|'milliseconds'} [measurement='milliseconds']
 * the unit of measurement in which to get the difference
 *
 * @returns {Number} the difference between the two instants in the measurement unit specified
 */
export function timeDiff(timeA, timeB, measurement = 'milliseconds') {
    if (measurement === 'milliseconds') {
        return moment(timeA).diff(moment(timeB));
    }
    return moment(timeA).diff(moment(timeB), measurement);
}

/**
 * Checks if the given initialDate comes before the given date.
 *
 * @param {String|Date|import('moment-timezone').Moment} initialDate the starting date
 * @param {String|Date|import('moment-timezone').Moment} date the date to check against
 *
 * @returns {Boolean} true if `initialDate` comes before `date`
 */
export function dateIsBefore(initialDate, date) {
    return moment(initialDate).isBefore(date);
}

/**
 * Checks if the given initialDate comes after the given date.
 *
 * @param {String|Date|import('moment-timezone').Moment} initialDate the starting date
 * @param {String|Date|import('moment-timezone').Moment} date the date to check against
 *
 * @returns {Boolean} true if `initialDate` comes after `date`
 */
export function dateIsAfter(initialDate, date) {
    return moment(initialDate).isAfter(date);
}
