import axios from 'axios';
import { get, set } from 'lodash';
import { interpolateUrl } from '@/libs/utils/url';
import { requestErrorInterceptor } from '@/libs/utils/interceptors';

// XHR library global configuration
axios.interceptors.response.use(response => response, requestErrorInterceptor);
axios.defaults.withCredentials = true;

// Constants

/**
 * How long (in milliseconds) we keep long lasting API requests cached
 * Currently 5 minutes.
 * @constant {string} XHR_CACHE_DURATION
 */
const XHR_CACHE_DURATION = 1000 * 60 * 5;

/**
 * Base Service with basic functionality every http service should extends,
 * it is for mocking/testing and future Architecture
 */
export default class BaseService {

    constructor() {
        this.__CACHE = {};
    }

    /**
     * Async HTTP head request
     *
     * @param {String} url the server URL that will be used for the request
     * @param {Object} [config] optional configuration for making requests
     *
     * @returns {Promise<import("axios").AxiosResponse>} the server response
     */
    async head(url, config) {
        return axios.head(url, config);
    }

    /**
     * Async HTTP get request
     *
     * @param {String} url the server URL that will be used for the request
     * @param {Object} [config] optional configuration for making requests
     *
     * @returns {Promise<import("axios").AxiosResponse>} the server response
     */
    async get(url, config) {
        return axios.get(url, config);
    }

    /**
     * Async HTTP post request
     *
     * @param {String} url the server URL that will be used for the request
     * @param {Object} data the data to be sent as the request body
     * @param {Object} [config] optional configuration for making requests
     *
     * @returns {Promise<import("axios").AxiosResponse>} the server response
     */
    async post(url, data, config) {
        return axios.post(url, data, config);
    }

    /**
     * Async HTTP put request
     *
     * @param {String} url the server URL that will be used for the request
     * @param {Object} data the data to be sent as the request body
     * @param {Object} [config] optional configuration for making requests
     *
     * @returns {Promise<import("axios").AxiosResponse>} the server response
     */
    async put(url, data, config) {
        return axios.put(url, data, config);
    }

    /**
     * Async HTTP delete request
     *
     * @param {String} url the server URL that will be used for the request
     * @param {Object} [config] optional configuration for making requests
     *
     * @returns {Promise<import("axios").AxiosResponse>} the server response
     */
    async delete(url, config) {
        return axios.delete(url, config);
    }

    /**
     * Performs a cached GET request to the server
     *
     * @param {String} url the server URL that will be used for the request
     * @param {Object} [config] optional configuration for making requests
     *
     * @returns {Promise<import("axios").AxiosResponse>} the server response
     */
    async getCached(url, config = {}) {

        // requires credentials for cors
        config.withCredentials = true;

        // Here we `await` for a possible stored promise (in case of a race condition)
        let cached = this.__CACHE[url];

        if (cached) {
            cached = await cached;
            const currentTime = Date.now();

            // Even if we find a cached request we still have to check if it's not expired.
            const date = get(cached, 'headers.date');
            const reqTime = date ? new Date(date).getTime() : currentTime;

            if (!date) {
                // If no date was set, store for next time
                set(cached, 'headers.date', currentTime);
            }

            if (currentTime - reqTime <= XHR_CACHE_DURATION) {
                console.debug(`[${this.constructor.name}] Serving response from the cache`, url);
                return cached;
            }
        }

        console.debug(`[${this.constructor.name}] Cached request not found or expired, hitting the server`, url);

        // Here we don't `await` in order to handle racing conditions.
        // We store the Promise as soon as we get the request without blocking the execution.
        this.__CACHE[url] = this.get(url, config);

        return this.__CACHE[url];
    }

    /**
     * Build URL by interpolating given key value pairs
     *
     * @param {String} url
     * @param {Object} [replacements={}]
     * @returns {String}
     */
    buildUrl(url, replacements = {}) {
        return interpolateUrl(url, replacements);
    }
}
