<template lang="pug">
.expandable-list
    .items(ref="list" :class="{ open: showMore }")
        .inner-list(ref="innerList" :class="{ [listClass]: listClass }")
            component(
                v-for="(item, index) in visibleItems"
                v-bind="{ [itemKey]: item, ...params }"
                :is="component"
                :key="item[trackBy]"
                :class="{ extra: index + 1 > maxItems }")

                template(#heading="{ item }")
                    slot(name="heading" :item="item")

    .more(v-if="showMoreToggler")
        slot(v-if="$scopedSlots['more']" name="more" :show-more="showMore" :toggle="expandCollapse")

        a.has-action(v-else @click="expandCollapse" :class="{ open: showMore }")
            i.icon-chevron-down
            | {{ $t(`registration.actions.show_${showMore ? 'less' : 'more'}`) }}
</template>
<script>
const MAX_ITEMS = 6;

export default {
    name: 'ExpandableList',

    props: {
        items: {
            type: Array,
            required: true
        },

        itemKey: {
            type: String,
            required: true
        },

        component: {
            type: [Object, String],
            required: true
        },

        params: {
            type: Object,
            default: () => ({})
        },

        trackBy: {
            type: String,
            default: '_id'
        },

        listClass: {
            type: String,
            default: null
        },

        maxItems: {
            type: Number,
            default: MAX_ITEMS
        },

        showMoreAction: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            animating: false,
            showMore: false
        };
    },

    computed: {
        visibleItems() {
            return this.showMore ? this.items : this.items.slice(0, this.maxItems);
        },

        showMoreToggler() {
            return this.showMoreAction && this.items.length > this.maxItems;
        }
    },

    watch: {
        items() {
            this.showMore = false;
        },

        maxItems() {
            this.setListHeight(this.showMore ? this.$refs.innerList.getBoundingClientRect().height : 0);
            this.expandCollapse();
        }
    },

    mounted() {
        this.$refs.list.addEventListener('transitionend', this.onTransitionEnd)
    },

    beforeDestroy() {
        this.$refs.list.removeEventListener('transitionend', this.onTransitionEnd)
    },

    methods: {
        expandCollapse() {
            requestAnimationFrame(() => {
                if (this.animating) {
                    return;
                }

                this.animating = true;

                // Always set the actual current height
                const height = this.$refs.innerList.getBoundingClientRect().height;
                this.setListHeight(height);

                this.showMore = !this.showMore;

                // Let the 'showMore' change paint the new elements
                this.$nextTick(() => {
                    const newHeight = this.$refs.innerList.getBoundingClientRect().height;
                    if (Number.parseInt(this.$refs.list.style.height) === newHeight) {
                        this.animating = false;
                        return;
                    }
                    this.setListHeight(newHeight);
                });
            });
        },

        setListHeight(height) {
            this.$refs.list.style.height = `${height}px`;
        },

        onTransitionEnd() {
            this.$refs.list.style.height = '';
            this.animating = false;
        }
    }
};
</script>
