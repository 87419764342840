// Here we set up the main application but everything starts with the
// router magics. Go check there ;)

import Vue from 'vue';

// Core instsallers
import { createLocales } from '@/core/locales';
import { createRouter } from '@/core/router';

// Generic Plugins & Global Components
import { registerPlugins } from '@/core/plugins';
import { registerComponents } from '@/core/global-components';
import { registerConfig } from '@/core/config';

// In-house installers
import { createServices } from '@/core/services';
import { createUtils } from '@/core/utils';

// Root component
import App from '@/App.vue';

// // [LEGACY] ---8<----------

import Branding from './models/branding';
import Track from './models/track';

import { Strings, Time } from './utils';

// Utils
Vue.use(Strings);
Vue.use(Time);

// // Models
Vue.use(Branding);
Vue.use(Track);

// // [LEGACY] --------->8----

console.debug('[App] Starting auth webapp...');

const i18n = createLocales(Vue);
const router = createRouter(Vue);

window.APP.utils = createUtils(Vue);
window.APP.services = createServices(Vue);

registerConfig(Vue);
registerPlugins(Vue);
registerComponents(Vue, i18n, window.APP.services);

window.APP.runtime = new Vue({
    el: '#app',
    name: 'Auth',

    i18n,
    router,

    data() {
        return {
            errors: []
        };
    },

    watch: {
        '$route.params.branding': {
            immediate: true,
            handler() {
                console.debug('[main] Branding changed, loading new messages');
                this.loadMessages();
            }
        }
    },

    created() {
        this.$brand.init(this);
    },

    mounted() {
        if (this.$route.query.i18n) {
            const flat = key => key;
            this.$i18n.t = flat;
            Vue.prototype.$t = flat;

            const yes = () => true;
            this.$i18n.te = yes;
            Vue.prototype.$te = yes;
        }
    },

    methods: {
        async loadMessages() {
            const branding = this.$route.params.branding;
            if (branding) {
                const brand = await this.$services.branding.getAppConfig(branding);
                this.$updateMessages(brand.i18n);
            }
        }
    },

    render(h) { return h(App, { props: { errors: this.errors } }); }
});
