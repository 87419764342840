// @ts-nocheck
// Utils
import { memoize } from 'lodash';

// Global components
import { ValidationObserver, ValidationProvider, configure, extend } from 'vee-validate/dist/vee-validate.full';
import Multiselect from 'vue-multiselect';
import LoginAction from '@/components/LoginAction.vue';
import LegalRequirement from '@/components/LegalRequirement.vue';
import SessionDetails from '@/components/registration/sections/SessionDetails.vue';

// Directives
import ClickOutside from '@/directives/click-outside';

// Constants
const MIN_DOMAIN_LENGTH = 3;

// Types
/**
 * @typedef {object} EmailDomainValidatorConfig
 *
 * @property {string} eventId the ID of the event
*  @property {import('@/types').RegistrationSettings} settings the registration's settings
 */

// Methods and exports

/**
 * Configures VeeValidate with the provided i18n and services.
 *
 * @param {object} i18n - The i18n object used for generating messages.
 * @param {import("@/types").Services} services - The services object.
 */
function configureVeeValidate(i18n, services) {
    configure({
        // this will be used to generate messages.
        defaultMessage: (field, values) => {
            const tKey = `validations.names.${field}`;
            values._field_ = i18n.te(tKey) ? i18n.t(tKey) : field;
            return i18n.t(`validations.messages.${values._rule_}`, values).toString();
        }
    });

    registerAllValidators(services);
}

/**
 * Registers validators for the authentication module.
 *
 * @param {import("@/types").Services} services - The services object.
 */
function registerAllValidators(services) {
    registersEmailDomainValidator(services);
    registerSessionsBlockRequiredValidator();
}

/**
 * Registers the email domain validator.
 *
 * @param {import("@/types").Services} services - The services object.
 */
function registersEmailDomainValidator(services) {
    extend('email_domain', {
        /**
         * Validates the email domain against the event's configuration.
         *
         * @param {string} email the email domain to validate
         * @param {EmailDomainValidatorConfig} options the registration's settings
         *
         * @returns {Promise<boolean>}
         */
        validate: async (email, options) => {
            // Formal check
            if (!email?.length || !email.includes('@')) {
                return false;
            }

            const [ , emailDomain ] = email.split('@');

            if (!emailDomain) {
                return false;
            }

            // Domain check
            return await validateEmailDomain(services, emailDomain.toLowerCase(), options);
        },
        params: [ 'eventId', 'settings' ]
    });
}

/**
 * Registers the sessions block required validator.
 */
function registerSessionsBlockRequiredValidator() {
    extend('session_required', {
        validate: (value, { control }) => {
            if (control.type !== 'sessions') {
                throw new Error('This validator can only be used with SessionsRegistration.');
            }
            const fpExtIds = control.fp_ext_ids || [];
            return value.some(id => fpExtIds.includes(id));
        },
        computesRequired: true,
        params: [ 'control' ],
        message: 'validations.messages.session_required'
    });
}

/**
 * Validates the email domain against the domains policies.
 *
 * @param {import("@/types").Services} services - The services object.
 * @param {string} emailDomain the email domain to validate
 * @param {EmailDomainValidatorConfig} options the validator options
 *
 * @returns {Promise<boolean>}
 */
const validateEmailDomain = memoize(
    /**
     * @param {import("@/types").Services} services - The services object.
     * @param {string} emailDomain the email domain to validate
     * @param {EmailDomainValidatorConfig} options the validator options
     * @returns {Promise<boolean>}
     */
    async (services, emailDomain, { eventId, settings }) => {
        const policy = settings.email_domains_policy || 'any';

        if (policy === 'any') {
            // No further checks required
            return true;
        }

        let domains = [];

        if (policy === 'pass_list') {
            domains = settings.email_domains_pass;
            return domains.includes(emailDomain);
        }

        if (policy === 'block_list') {
            domains = settings.email_domains_block;

            const blockFree = settings.email_domains_block_free;
            let valid = !domains.includes(emailDomain);

            if (blockFree && valid && emailDomain.length > MIN_DOMAIN_LENGTH) {
                valid = !await services.publicLogin.verifyFreeEmailDomain(eventId, emailDomain);
            }

            return valid;
        }

        return true;
    },
    /**
     * @param {import("@/types").Services} _ - The services object.
     * @param {string} emailDomain the email domain to validate
     * @returns {string} the email domain
     */
    (_, emailDomain) => emailDomain);

/**
 * Registers global components in Vue.
 *
 * @param {import('vue').VueConstructor} Vue - The Vue instance.
 * @param {unknown|import('vue-i18n').IVueI18n} i18n - The i18n instance.
 * @param {import("@/types").Services} services - The services object.
 */
export function registerComponents(Vue, i18n, services) {
    const GLOBAL_COMPONENTS = {
        LegalRequirement,
        LoginAction,
        Multiselect,
        SessionDetails,
        ValidationObserver,
        ValidationProvider
    };

    // Global registration of components
    for (const componentName of Object.keys(GLOBAL_COMPONENTS)) {
        Vue.component(componentName, GLOBAL_COMPONENTS[componentName]);
    }

    configureVeeValidate(i18n, services);

    Vue.directive('click-outside', ClickOutside);
}
