<template lang="pug">
control-decorator(:label="label" :tip="tip" :hint="hint" :uid="uid" :errors="errors" :hint-after-label="hintAfterLabel")
    template(#after-label="")
        slot(name="after-label")

    template(#default="")
        .input-wrapper(:class="{'with-content': $slots.default}")
            i.input-left-icon(v-if="placeholderIconClass" :class="placeholderIconClass")
            input.form-control(
                ref="input"
                :id="uid"
                v-on="listeners"
                @input="input"
                :name="name"
                :value="value"
                :type="type"
                :disabled="disabled"
                :autocomplete="autocomplete"
                :class="{'has-error':  errors.length, 'with-icon': !!placeholderIconClass}"
                :placeholder="placeholder"
                :aria-label="placeholder && !label ? placeholder : null"
                :step="step"
                v-bind="$attrs")
            slot
</template>
<script>
// Components
import BaseControl from './BaseControl.vue';

/**
 * Base component to wrap text inputs with a common markup.
 *
 * It has all `BaseControl` options plus:
 *
 * @vue-prop {String} [type="text"] the type of the input
 *
 * @example
 *
 * base-input(:label="t('name')" type="text" v-model="form.name" validations="required" name="name")
 *
 * @category ui.components.form-elements
 * @exports BaseInput
 * @component
 */
export default {
    name: 'BaseInput',

    extends: BaseControl,

    props: {
        type: {
            type: String,
            default: 'text'
        },

        value: {
            type: [String, Number],
            default: null
        },

        hintAfterLabel: {
            type: Boolean,
            default: false
        },

        placeholderIconClass: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            step: null
        };
    },

    created() {
        if (this.type === 'number') {
            this.step = 'any';
        }
    },

    methods: {
        /**
         * Triggered when user interacts with input components.
         *
         * @param {Object} [$event] the event that triggered the change
         */
        input($event) {
            let value = this.value;

            if ($event && $event.target) {
                value = $event.target.value;
            }

            if (value && this.type === 'number' && value !== '-') {
                value = this.$utils.strings.toNumber(value);
            }

            this.$emit('input', value);
        }
    }
};
</script>
