<template lang="pug">
.sponsor-details.modal-wrapper
    .modal-header
        a.close(@click="$emit('close')")
        picture
            img(v-if="logo" :srcset="`${logo} 2x`" :alt="sponsor.title" @error="logo = null")
            sponsor-placeholder-image(v-else)

        .details
            .title {{ sponsor.title }}

        .level(v-if="sponsor.level" :style="{ color: sponsor.level.text_color, 'background-color': sponsor.level.level_color }")
            | {{ sponsor.level.level_name }}

        .meta(v-if="showMeta" :class="{ 'has-bio': hasBio }")
            span.location(v-if="sponsor.location") {{ sponsor.location }}
            a.website(v-if="sponsor.website" :href="sponsor.website" target="_blank" rel="noopener noreferrer") {{ formatedWebsite() }}
            a.email(v-if="sponsor.email" :href="`mailto:${sponsor.email}`") {{ sponsor.email }}

    .modal-body
        .desc(v-if="sponsor.description" v-html="sponsor.description")

    .modal-footer
</template>
<script>
// Components
import SponsorPlaceholderImage from '@/components/registration/sections/SponsorPlaceholderImage.vue';

export default {
    name: 'SponsorDetails',

    components: { SponsorPlaceholderImage },

    props: {
        sponsor: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            logo: this.sponsor.logo,
            hasBio: Boolean(this.sponsor.description?.trim().length),
            showMeta: this.sponsor.location || this.sponsor.website || this.sponsor.email
        }
    },

    methods: {
        formatedWebsite() {
            return this.sponsor.website.replace(/^http(s)?:\/\//, '');
        }
    }
};
</script>
