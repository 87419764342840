<template lang="pug">
control-decorator.legal-document(:label="topLabel" :tip="tip" :hint="hint" :uid="uid" :errors="errors")
    template(#label="")
        template(v-for="requirement in requirements")
            label.checkbox(:class="{'has-error': errors.length}")
                input.form-control(
                    ref="input"
                    :id="`${uid}-${requirement.id}`"
                    type="checkbox"
                    :name="requirement.id"
                    @change="change($event, requirement)"
                    :checked="requirement.accepted"
                    :disabled="disabled")

                i18n(tag="span" :path="`requirements.${requirement.id}`")
                    a(:href="getUrl(requirement)" target="_blank" rel="noopener noreferrer") {{ getLinkLabel(requirement) }}
</template>
<script>
// Components
import BaseControl from './BaseControl.vue';
import Checkbox from '@/components/form-elements/Checkbox.vue';

export default {
    name: 'LegalDoc',

    extends: BaseControl,

    components: { Checkbox },

    props: {
        topLabel: {
            type: String,
            default: null
        },

        value: {
            type: [ String, Boolean ],
            default: false
        },

        required: {
            type: Boolean,
            default: true
        },

        requirements: {
            type: Array,
            required: true
        }
    },

    created() {
        if (this.value === 'true' || this.value) {
            this.requirements.forEach(r => r.accepted = true);
        }
    },

    methods: {
        /**
         * Handler of the change event
         *
         * @param {InputEvent} $event
         * @param {object} requirement
         */
        change($event, requirement) {
            //@ts-ignore
            requirement.accepted = $event.target.checked;
            const allAccepted = this.requirements.every(r => r.accepted);
            this.$emit('input', allAccepted);
        },

        getUrl(requirement) {
            const key = `requirement_labels.${requirement.id}.url`;
            return this.$te(key) ? this.$t(key) : requirement.url;
        },

        getLinkLabel(requirement) {
            const key = `requirement_labels.${requirement.id}.link_label`;
            return this.$te(key) ? this.$t(key) : requirement.link_label;
        }
    }
}
</script>
