<template lang="pug">
.debug(v-if="session.disabled_reason")
    dl
        dt Reason
        dd {{ session.disabled_reason }}

        template(v-if="session.offending_sessions.length")
            dt Offenders:
            dd(v-for="offending in session.offending_sessions" :key="offending._id" :title="offending.fp_ext_id") {{ offending.title }}

</template>
<script>
export default {
    name: 'SessionDebug',

    props: {
        session: {
            type: Object,
            required: true
        }
    }
};
</script>
