<template lang="pug">
.standard-login
    .email-group(v-if="!success && step === 1")
        .form-group(v-if="allowNonUniqueEmail")
            label {{$t(page.fname_label || 'activation.email_login_screen.fname_label')}}
            input.form-control.validate(
                name="fname"
                type="text"
                v-model="fname"
                :class="{ 'is-invalid': emailError }"
                :disabled="submitting"
                :placeholder="$t(page.fname_placeholder || 'activation.email_login_screen.fname_placeholder')"
                maxlength="255",
                autocomplete="given-name"
                required)
        .form-group(v-if="allowNonUniqueEmail")
            label {{$t(page.lname_label || 'activation.email_login_screen.lname_label')}}
            input.form-control.validate(
                name="lname"
                type="text"
                v-model="lname"
                :class="{ 'is-invalid': emailError }"
                :disabled="submitting"
                :placeholder="$t(page.lname_placeholder || 'activation.email_login_screen.lname_placeholder')"
                maxlength="255",
                autocomplete="family-name"
                required)
        .form-group
            label {{$t(page.label || 'activation.email_login_screen.label')}}
            input.form-control.validate(
                name="email"
                type="email"
                v-model="email"
                :class="{ 'is-invalid': emailError }"
                :disabled="submitting"
                :placeholder="$t(page.placeholder || 'activation.email_login_screen.placeholder')"
                maxlength="255",
                autocomplete="email"
                required)
            .invalid-feedback(v-if="loginErrors")
                p(v-for="(error) in loginErrors") {{$t(error)}}

        small.form-description.text-muted(v-if="!success && step === 1")
            | {{$t( page.hint || 'activation.email_login_screen.hint')}}

    .form-group(v-if="!success && step === 2" v-for="(field, i) in fields")
        label {{field.label || field.placeholder}}
        input.form-control(
            v-if="field.type !== 'select'"
            v-model="fields[i].value"
            :type="field.type"
            :name="field.name"
            :autocomplete="field.type"
            :placeholder="field.placeholder"
            :class="{ 'is-invalid': fields[i].error, 'validate': !field.optional }"
            :disabled="submitting"
            :required="!field.optional"
            @input="validate"
            @keyup="touch(field)")

        .select(v-if="field.type === 'select'")
            select.form-control(
                v-model="fields[i].value"
                :name="field.name"
                :class="{ 'is-invalid': fields[i].error, 'validate': !field.optional }"
                :disabled="submitting"
                :required="!field.optional"
                @change="touch(field);validate()")
                option(selected disabled hidden) {{ field.placeholder }}
                option(
                    v-for="value in field.values"
                    :value="value.identifier"
                    v-text="value.text")

    .email-sent(v-if="success")
        h2 {{$t(page.confirmation_title || 'login.check_email')}}
        p {{$t(page.confirmation_text || 'login.email_sent', [email])}}
        hr
        p {{$t(page.confirmation_secondary_text || 'login.magic_link_not_received')}}
        a(@click.prevent="submit") {{$t(page.confirmation_secondary_button_title || 'login.resend')}}

</template>
<script>
import { LoginException } from '@/exceptions';

export default {
    name: 'Standard',

    props: [ 'page', 'allowNonUniqueEmail' ],

    data() {
        return {
            email: this.$route.query.email || '',
            fname: this.$route.query.fname || '',
            lname: this.$route.query.lname || '',
            emailError: false,
            type: this.page.type,
            submitting: false,
            loginErrors: [],
            success: false,
            step: 1,
            fields: [],
            createAccount: false,
            token: null
        };
    },

    watch: {
        email() {
            this.validate();
        },
        fname() {
            this.validate();
        },
        lname() {
            this.validate();
        }
    },

    created() {
        this.initFields(this.page.form);

        // This is true when email is provided via url
        if (this.email.length !== 0) {
            this.validate();
        }
    },

    activated() {
        if (this.page.continue) {
            this.$emit('nextChanged', this.page.continue);
        }

        this.validate();
    },

    methods: {
        initFields(form = {}) {
            this.fields = [];

            // Note: the support for forms was deleted https://github.com/Spotme/backstage-app/commit/4ac187982a7261a234dfcc42dbf83462942a3511
            // but we keep this in case some old app still uses it.
            if (form) {
                Object.keys(form).forEach(key => {
                    const field = form[key];
                    field.name = key;
                    field.type =
                        field.type === 'textfield' ? 'text' : field.type;
                    field.order = Number.isInteger(field.order)
                        ? field.order
                        : 0;
                    this.fields.push(field);
                });

                this.fields.sort((a, b) => a.order - b.order);
            }
        },

        touch(field) {
            if (field) {
                field.touched = true;
            } else {
                this.fields.forEach(f => {
                    f.touched = true;
                });
            }
        },

        validate() {
            this.loginErrors = [];
            let valid = this.$strings.validateEmail(this.email);

            if (this.allowNonUniqueEmail) {
                valid &&= this.fname && this.lname;
            }

            if (this.step === 2) {
                for (const f of this.fields) {
                    f.error = false;
                    if (!f.optional && (!f.value || f.value.length === 0)) {
                        f.error = f.touched;
                        valid = false;
                    }
                }
            }

            this.$emit('validity', valid);
            return valid;
        },

        onRequestSuccess() {
            if (!this.fields.length || this.step === 2) {
                this.$track.endTrackLogin(true);
                this.success = true;
                this.$emit('toggleLegals', false);
                this.$emit('nextChanged', true);
                this.$emit('backChanged', true);
            } else if (this.step === 1) {
                this.$emit('toggleLegals', false);
                this.step = 2;
                this.validate();
            }
        },

        onRequestError({ body }) {
            this.emailError = true;
            this.loginErrors.push('login.email_send_failed');
            throw new LoginException(body.error, body.error_code, this.type);
        },

        requestInvitationsLink() {
            this.submitting = true;

            return this.$http
                .post('/api/v1/request-invitations?with_testing=true', {
                    email: this.email,
                    fname: this.fname,
                    lname: this.lname,
                    branding: this.$route.params.branding,
                    app_id: this.$const.APP_ID,
                    page: this.page.index,
                    redirect: this.$route.query.redirect
                    // strict: false
                })
                .then(resp => {
                    this.token = resp.body.token;
                    this.createAccount = resp.body.create_account;
                    this.initFields(resp.body.form);
                    return resp;
                }, this.onRequestError)
                .finally(() => (this.submitting = false));
        },

        sendFormfields() {
            this.submitting = true;
            const data = {
                email: this.email,
                branding: this.$route.params.branding,
                app_id: this.$const.APP_ID,
                token: this.token,
                createAccount: this.create_account,
                form: {}
            };

            this.fields.forEach(f => (data.form[f.name] = f.value));

            return this.$http
                .post('/api/v1/tokens/invitations/validate', data)
                .then(a => a, this.onRequestError)
                .finally(() => (this.submitting = false));
        },

        back() {
            this.success = false;
            this.$emit('toggleLegals', true);
            this.$emit('nextChanged', 'login.submit');
            this.$emit('backChanged', false);
            this.validate();
        },

        submit() {
            if (this.step === 2) {
                this.touch();
            }

            if (!this.validate()) {
                return false;
            }

            if (this.fields.length && this.step === 2) {
                return this.sendFormfields().then(this.onRequestSuccess);
            } else {
                return this.requestInvitationsLink().then(
                    this.onRequestSuccess
                );
            }
        }
    }
};
</script>
