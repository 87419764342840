<template lang="pug">
.expandable-content
    .heading(@click="expandCollapse()" :class="{ collapsed: !expanded }")
        i.icon-chevron-down
        slot(name="title")

    .pre-content(:class="{ collapsed: !expanded }" v-if="$scopedSlots['pre-content']")
        slot(name="pre-content")

    .content(ref="content" :class="{ collapsed: !expanded }")
        .content-inner(ref="innerContent")
            slot

    .post-content(:class="{ collapsed: !expanded }" v-if="$scopedSlots['post-content']")
        slot(name="post-content")
</template>
<script>
export default {
    name: 'ExpandableContent',

    props: {
        startExpanded: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            expanded: this.startExpanded
        };
    },

    mounted() {
        this.$refs.content.addEventListener('transitionend', this.onTransitionEnd)
    },

    beforeDestroy() {
        this.$refs.content.removeEventListener('transitionend', this.onTransitionEnd)
    },

    methods: {
        expandCollapse() {
            requestAnimationFrame(() => {
                if (this.animating) {
                    return;
                }

                this.animating = true;

                // Always set the actual current height
                const height = this.$refs.innerContent.getBoundingClientRect().height;
                this.setContentHeight(height);

                this.expanded = !this.expanded;

                // Let the 'showMore' change paint the new elements
                this.$nextTick(() => {
                    const newHeight = this.$refs.innerContent.getBoundingClientRect().height;
                    this.setContentHeight(newHeight);
                });
            });
        },

        setContentHeight(height) {
            this.$refs.content.style.height = `${height}px`;
        },

        onTransitionEnd() {
            this.$refs.content.style.height = '';
            this.animating = false;
        }
    }
};
</script>
