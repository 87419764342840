<template lang="pug">
.form-group
    .actions(v-if="$slots['control-actions']")
        slot(name="control-actions")
    label.control-label(v-if="label" :for="uid" :class="{'has-error': errors.length}")
        span.text(v-text="label")
        i.tip.ic-info(v-if="tip")
    slot(name="label")

    .control-hint(v-if="hint && hintAfterLabel") {{ hint }}

    slot(name="after-label")

    slot(name="default")

    p.help.error(v-if="errors.length")
        i.icon-warning-fill
        | {{ errors[errors.length - 1] }}
    .control-hint(v-if="hint && !hintAfterLabel") {{ hint }}

</template>
<script>
/**
 * This is a functional component used to decorate the
 * markup of all most common form fields.
 *
 * It provides the HTML wrapper for inputs, selects, and textareas.
 *
 * @vue-prop {String} uid the unique id of the control
 * @vue-prop {String} [label=] the control label
 * @vue-prop {String} [tip=] tips of the control (tooltip on the label)
 * @vue-prop {String} [hint=] hints of the control (small text below)
 * @vue-prop {String[]} [errors=] eventual control errors
 *
 * @example
 *
 * control-decorator(:uid="uid" :label="label" :tip="tip" :hint="hint" :errors="errors")
 *      ...
 *
 * @category ui.components.form-elements
 * @exports ControlDecorator
 * @component
 */
export default {
    name: 'ControlDecorator',

    props: {
        uid: {
            type: String,
            required: true
        },

        label: {
            type: String,
            default: null
        },

        tip: {
            type: String,
            default: null
        },

        hint: {
            type: String,
            default: null
        },

        errors: {
            type: Array,
            default: () => []
        },

        hintAfterLabel: {
            type: Boolean,
            default: false
        }
    }
};
</script>
