<template lang="pug">
dropdown-button.language-selector-button(
    :button-classes="{ 'btn-icon': true }"
    :close-on-select="true"
)
    .button-content
        i.icon-globe.ic-globe
        | {{ selectedLanguage }}

    template(#menu="")
        ul
            li.language(
                v-for="lang in languageCodes"
                :key="lang"
                :class="{ 'highlighted-language': lang === selectedLanguage }"
                @click="() => onLanguageSelected(lang)"
            )
                | {{ $utils.locales.getLanguageNativeName(lang) }}
</template>
<script>
import DropdownButton from '@/components/common/DropdownButton.vue';

export default {
    name: 'LanguageSelectorButton',

    components: {
        DropdownButton
    },

    props: {
        languageCodes: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            selectedLanguage: this.$i18n.locale
        };
    },

    methods: {
        onLanguageSelected(langCode) {
            this.selectedLanguage = langCode;
            this.$services.storage.setItem('locale_selected', langCode);
            this.$i18n.locale = langCode;
        }
    }
};
</script>
