/**
 * @typedef {object} Availability
 * @property {object} [seats] - The seats availability object.
 * @property {number} seats.available - The number of available seats.
 * @property {object} [waitlist] - The waitlist availability object.
 * @property {boolean} waitlist.enabled - The waitlist availability status.
 * @property {number} [waitlist.capacity] - The waitlist capacity.
 */
/**
 * This class represents a SpotMe session object.
 * It contains the session properties and methods to manipulate them.
 * @class
 * @module Session
 * @exports Session
 */
export default class Session {
    /**
     * Represents a session object.
     *
     * @constructor
     *
     * @param {object} data - The data object containing session properties.
     * @param {boolean} [debug=false] - The debug flag.
     */
    constructor(data, debug = false) {
        /** @type {string} */
        this._id = data._id;

        /** @type {string[]} */
        this._errors = data._errors || [];

        /** @type {boolean} */
        this.can_register = data.can_register;

        /** @type {boolean} */
        this.can_unregister = data.can_unregister;

        /** @type {object} */
        this.dates = data.dates;

        /** @type {number} */
        this.ends_at = data.ends_at;

        /** @type {string} */
        this.fp_ext_id = data.fp_ext_id;

        /** @type {number} */
        this.max_capacity = data.max_capacity;

        /** @type {object[]} */
        this.speakers = data.speakers || [];

        /** @type {number} */
        this.starts_at = data.starts_at;

        /** @type {string} */
        this.title = data.title;

        /** @type {string} */
        this.description = data.description;

        /** @type {number} */
        this.available_seats = data.available_seats;

        /** @type {boolean} */
        this.enabled = data.enabled;

        /** @type {string} */
        this.disabled_reason = data.disabled_reason;

        /** @type {Session[]} */
        this.offending_sessions = [];

        /** @type {string[]} */
        this.mutually_exclusive = data.mutually_exclusive || [];

        /** @type {string[]} */
        this.mutually_inclusive = data.mutually_inclusive || [];

        /** @type {boolean} */
        this.waitlist_enabled = data.waitlist_enabled;

        /** @type {boolean} */
        this.originally_selected = false;

        /** @type {boolean} */
        this.debug = debug;

        this.setAvailability(data.availability || {});
        this.enable();
    }

    /**
     * Marks the session as origially selected.
     */
    markAsOriginallySelected() {
        this.originally_selected = true;
    }

    /**
     * Sets the errors for the session.
     *
     * @param {Array|String} errors - The error(s) to be set.
     */
    setErrors(errors) {
        if (typeof errors === 'string') {
            errors = [ errors ];
        }

        this._errors = errors;
    }

    /**
     * Sets the availability for the session.
     *
     * @param {Availability} availability
     */
    setAvailability(availability) {
        this.available_seats = availability.seats?.available ?? Infinity;
        this.waitlist_enabled = availability.waitlist?.enabled ?? false;
    }

    /**
     * Checks if the session was originally selected.
     * @returns {boolean} True if the session was originally selected, false otherwise.
     */
    wasOriginallySelected() {
        return this.originally_selected;
    }

    /**
     * Determines if a session is selectable based on certain conditions.
     *
     * @returns {boolean} - True if the session is selectable, false otherwise.
     */
    isSelectable() {
        if (this._errors.length) {
            for (const error of this._errors) {
                this.disable(error);
            }
            return false;
        }

        let selectable = this.checkRegistrationStatus();

        if (selectable) {
            selectable = this.checkAvailabilityStatus();
        }

        return selectable;
    }

    /**
     * Checks if a session is unselectable.
     *
     * A session is considered unselectable if:
     * - the user already registered to this session (originally selected)
     * - cannot be unregistered
     *
     * @returns {boolean} - Returns true if the session is unselectable, otherwise false.
     */
    isUnselectable() {
        // This intrinsically tells us if the user is updating or not
        // because in a fresh registration, the original selection would be empty.
        let unselectable = true;
        if (this.originally_selected && this.can_unregister === false) {
            this.disable('cannot_unregister');
            unselectable = false;
        }

        return unselectable;
    }

    /**
     * Toggles the enabled state of a session.
     *
     * @param {boolean} value - The new enabled state for the session.
     *
     * @returns {boolean} - Returns true if the session is enabled, false otherwise.
     */
    toggleEnable(value) {
        const enabled = this.isUnselectable() ? value : false;
        this.enabled = enabled;

        if (enabled) {
            this.enable();
        } else {
            this.disable();
        }

        return enabled;
    }

    /**
     * Enables the session.
     * Sets the `enabled` property to `true` and resets the session.
     */
    enable() {
        this.enabled = true;
        this.reset();
    }

    /**
     * Disables the session.
     *
     * @param {string} [reason] - The reason for disabling the session.
     * @param {Session} [offendingSession] - The session that caused the current session to be disabled.
     */
    disable(reason, offendingSession) {
        this.enabled = false;
        if (reason) {
            this.disabled_reason = reason;
        }

        if (this.debug && offendingSession) {
            const allOffending = new Set([ ...this.offending_sessions, offendingSession ]);
            this.offending_sessions = Array.from(allOffending);
            console.debug('[SM] Session disabled:', this.title, this.disabled_reason);
        }
    }

    /**
     * Resets the session by clearing errors and disabling reasons.
     */
    reset() {
        this.setErrors([]);
        this.disabled_reason = '';
        this.offending_sessions = [];
    }

    /**
     * Checks the registration status of a session.
     *
     * @returns {boolean} - Returns true if the session can be registered, false otherwise.
     *
     * @private
     */
    checkRegistrationStatus() {
        if (!this.can_register) {
            this.disable('registration_closed');
            return false;
        }

        return true;
    }

    /**
     * Checks the availability status of a session.
     *
     * @returns {boolean} - Returns true if the session is available, false otherwise.
     *
     * @private
     */
    checkAvailabilityStatus() {
        if (this.available_seats <= 0 && !this.wasOriginallySelected()) {
            this.disable('no_seats_left');
            return false;
        }

        return true;
    }

    /**
     * Checks the exclusivity of a session by comparing it with other selected sessions.
     *
     * @param {Session[]} selectedSessions - The list of selected sessions.
     * @param {Session[]} allSessions - The list of all available sessions.
     * @param {object} settings - The settings object.
     *
     * @returns {boolean} - Returns `true` if the session is exclusive and can be selected, otherwise `false`.
     */
    checkExlusivity(selectedSessions, allSessions, settings) {
        let selectable = true;

        for (const session of selectedSessions) {
            if (this.isOverlappingWith(session, settings)) {
                this.disable('overlapping_sessions', session);
                selectable = false;
            }

            if (this.isExclusiveWith(session)) {
                selectable = false;
                this.disable('in_exclusion_group', session);
            }
        }

        for (const companionId of this.getInclusiveCompanions()) {
            const companion = allSessions.find(s => s.fp_ext_id === companionId);
            if (companion?.enabled === false && companion?.disabled_reason !== 'blocked_by_excluded_companion') {
                selectable = false;
                this.disable('blocked_by_excluded_companion', companion);
            }
        }

        return selectable;
    }

    /**
     * Checks if this session is time-overlapping with the given one.
     *
     * @param {Session} session - The first session object.
     * @param {object} settings - The settings object.
     *
     * @returns {boolean} - True if the sessions are overlapping, false otherwise.
     *
     * @private
     */
    isOverlappingWith(session, settings) {
        if (!settings.prevent_concurrent_registration) {
            return false;
        }

        if (this.fp_ext_id === session.fp_ext_id) {
            return false;
        }

        const startsWhileAnotherOngoing = (s1, s2) => s1.starts_at >= s2.starts_at && s1.starts_at < s2.ends_at;
        return startsWhileAnotherOngoing(this, session) || startsWhileAnotherOngoing(session, this);
    }

    /**
     * Retrieves the list of exclusive companions for a given session.
     *
     * @returns {Array} - The list of mutual companions.
     */
    getExclusiveCompanions() {
        return this.mutually_exclusive?.filter(id => this.fp_ext_id !== id) || [];
    }

    /**
     * Checks if the current session is exclusive with another session.
     *
     * @param {Session} session - The session to compare with.
     *
     * @returns {boolean} - True if the current session is exclusive with the provided session, false otherwise.
     */
    isExclusiveWith(session) {
        return this.getExclusiveCompanions().includes(session.fp_ext_id);
    }

    /**
     * Retrieves the list of mutual companions for a given session.
     *
     * @returns {Array} - The list of mutual companions.
     */
    getInclusiveCompanions() {
        return this.mutually_inclusive?.filter(id => this.fp_ext_id !== id) || [];
    }

    /**
     * Decreases the capacity of the session by reducing the available seats.
     */
    decreaseCapacity() {
        if (this.hasAvailableSeats()) {
            this.available_seats--;
        }
    }

    /**
     * Increases the capacity of the session.
     */
    increaseCapacity() {
        if (Number.isFinite(this.available_seats) && this.available_seats < this.max_capacity) {
            this.available_seats++;
        }
    }

    /**
     * Checks if the session has available seats.
     *
     * @returns {boolean} Returns true if there are available seats, false otherwise.
     */
    hasAvailableSeats() {
        return !Number.isFinite(this.available_seats) || this.available_seats > 0;
    }
}
