<template lang="pug">
.account
    .text-right
        i.ic-user(v-if="email" @click="menuIn = !menuIn")
        ul.menu(v-bind:class="{'in': menuIn}")
            li: span.email {{email}}
            li(v-if="token"): router-link(:to="{name: 'invitations', params: { branding: $route.params.branding, token } }") {{$t('events.title')}}
            li(v-if="showChangePasswd"): a(@click="changePassword") {{$t('login.change_password_label')}}
            li: a(@click="logout") {{$t('login.logout')}}
</template>
<script>
export default {
    name: 'AccountBar',

    props: {
        email: {
            type: String,
            default: null
        },

        token: {
            type: String,
            default: null
        },

        pages: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            menuIn: false,
            showChangePasswd: false
        };
    },

    computed: {
        /** @returns {boolean} */
        accountPagePresent() {
            return Boolean(this.pages.find(p => p.type === 'account_login'));
        }
    },

    methods: {
        changePassword() {
            console.info('[Account] Account info:', this.email);
            this.$router.push({
                name: 'changepass',
                params: {
                    branding: this.$route.params.branding
                }
            });
            this.menuIn = false;
        },

        async logout() {
            this.menuIn = false;

            const branding = this.$route.params.branding;
            await this.$services.session.logout(branding);

            this.$router.push({
                name: 'welcome',
                params: { branding }
            });
        }
    }
};
</script>
