// Utilities
import * as assets from '@/libs/utils/assets';
import * as colors from '@/libs/utils/colors';
import * as controls from '@/libs/utils/controls';
import * as locales from '@/libs/utils/locales';
import * as sessions from '@/libs/utils/sessions';
import * as strings from '@/libs/utils/strings';
import * as theme from '@/libs/utils/theme';
import * as time from '@/libs/utils/time';
import * as url from '@/libs/utils/url';

// Constants
import * as constants from '@/libs/utils/constants';

export const utils = {
    assets,
    colors,
    controls,
    locales,
    sessions,
    strings,
    theme,
    time,
    url
};

export const CONSTANTS = constants;

export function createUtils(Vue) {
    Vue.prototype.$utils = utils;
    Vue.prototype.$const = constants;

    return utils;
}

