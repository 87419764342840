// Classes
import BaseService from './base-service';


// Constants
import { API_BASE_PATH } from '@/libs/utils/constants';

/**
 * Path to add to the registration URL to retrieve the dynamic info about some sesssions
 * @constant {String} SESSIONS_INFO_PATH
 */
const SESSIONS_INFO_PATH = '/sessions';

/**
 * User's status API endpoint
 * @constant {String} USER_STATUS_API_ENDPOINT
 */
const USER_STATUS_API_ENDPOINT = `${API_BASE_PATH}/user-registration-status/{{eventId}}`;

/**
 * Service class for managing sessions registration.
 */
export default class SessionsRegistrationService extends BaseService {

    /**
     * Retrieves the availability of sessions for a given event and fingerprint extension IDs.
     *
     * @param {object} config - The event's config object
     * @param {string[]} fpExtIds - An array of fingerprint extension IDs.
     *
     * @returns {Promise<Record<String, Object>>} - A promise that resolves to the availability data.
     */
    async getSessionsAvailability(config, fpExtIds) {
        const { event_details, user, registrationUrl } = config;
        const url = `${registrationUrl}${SESSIONS_INFO_PATH}`;
        const { data } = await this.post(url, { fpExtIds });

        if (user?._authenticated) {
            const { eid, invitation } = event_details;
            const sessionsStatus = await this.getSessionsStatusesForCurrentUser(eid, invitation.token, fpExtIds);
            if (!sessionsStatus) return data;

            for (const [ extId, availability ] of Object.entries(sessionsStatus)) {
                if (data[extId].seats?.hasOwnProperty('available')) {
                    data[extId].seats.available = availability.availableSeats;
                }
            }
        }

        return data;
    }

    /**
     * Gets the sessions statuses for the current user.
     *
     * @param {string} eventId the ID of the event
     * @param {string} invitationToken the invitation token for this event
     * @param {string[]} sessionFpExtIds the fp external IDs of the sessions
     *
     * @returns {Promise<object>} the user's registration status
     */
    async getSessionsStatusesForCurrentUser(eventId, invitationToken, sessionFpExtIds = []) {
        const url = this.buildUrl(USER_STATUS_API_ENDPOINT, { eventId });
        const { data } = await this.post(url, { invitationToken, sessionFpExtIds });
        return data.sessionsStatus;
    }
}
