<template lang="pug">
section.editorial(v-if="visible")
    h3(v-if="title") {{ title }}
    .description(v-if="description" v-html="description")
</template>
<script>
// Mixins
import section from './section.mixin';

export default {
    name: 'Editorial',

    mixins: [section]
};
</script>
