<template lang="pug">
.session-dates(v-if="enabled")
    .prev(v-if="showPrev" @click="prev"): i.icon-chevron-left
    .dates-viewport(ref="viewport")
        .dates(ref="dates")
            button.btn.date(
                type="button"
                v-for="date of Array.from(this.dates)"
                :class="{ 'btn-primary': currentDate === date}"
                @click="currentDate = date")
                | {{ date }}
    .next(v-if="showNext" @click="next"): i.icon-chevron-right
</template>
<script>
// Constants
const SCROLL_STEP = 80;

export default {
    name: 'SessionDates',

    props: {
        sessions: {
            type: Array,
            required: true
        },

        eventTimezone: {
            type: String,
            required: true
        },

        useEventTimezone: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            currentDate: null,
            dates: new Set(),
            showPrev: false,
            showNext: false,
            scrollLeft: 0,
            scrollListener: null
        };
    },

    computed: {
        viewportWidth() {
            return this.$refs.viewport ? Math.round(this.$refs.viewport.getBoundingClientRect().width) : 0;
        },

        datesWidth() {
            return this.$refs.dates ? Math.round(this.$refs.dates.getBoundingClientRect().width) : 0;
        },

        diff() {
            return Math.round(this.datesWidth - this.viewportWidth);
        },

        enabled() {
            return this.dates.size > 1;
        }
    },

    watch: {
        currentDate() {
            this.$emit('change', this.currentDate);
        },

        scrollLeft() {
            this.calculateScrollersVisibility();
        }
    },

    created() {
        for (const session of this.sessions) {
            this.dates.add(session.dates.day);
        }

        const locale = this.$i18n.locale;
        const tz = this.useEventTimezone ? this.eventTimezone : Intl.DateTimeFormat().resolvedOptions().timeZone;
        const currentDay = new Date().toLocaleString(locale, { month: 'short', day: 'numeric', timeZone: tz });

        for (const date of this.dates.values()) {
            if (date === currentDay) {
                this.currentDate = date;
                break;
            } else if (!this.currentDate) {
                this.currentDate = date;
            }
        }
    },

    mounted() {
        if (this.enabled) {
            this.calculateScrollersVisibility();
            this.scrollListener = () =>  this.scrollLeft = this.$refs.viewport.scrollLeft;
            this.$refs.viewport.addEventListener('scroll', this.scrollListener);
        }
    },

    beforeDestroy() {
        if (this.enabled) {
            this.$refs.viewport.removeEventListener('scroll', this.scrollListener);
        }
    },

    methods: {
        calculateScrollersVisibility() {
            if (this.datesWidth <= this.viewportWidth) return;

            this.showPrev = this.scrollLeft !== 0;
            this.showNext = this.diff > this.scrollLeft;
        },

        prev() {
            this.scroll(SCROLL_STEP * -1);
        },

        next() {
            this.scroll(SCROLL_STEP);
        },

        scroll(amount) {
            this.$refs.viewport.scroll({
                top: 0,
                left: this.scrollLeft + amount,
                behavior: 'smooth'
            });
        }
    }
};
</script>
