/**
 * @typedef {Object} RGBColor
 * @property {Number} r red value
 * @property {Number} g green value
 * @property {Number} b blue value
 */

/**
 * @typedef {Object} HSLColor
 * @property {Number} h hue value
 * @property {Number} s saturation value
 * @property {Number} l light value
 */

/**
 * Checks if the given color is in a valid HEX form
 *
 * @param color the color code string
 *
 * @returns {boolean} whether the given string is a valid hex or not
 */
export function isValidHexColor(color) {
    return /^#([0-9A-F]{3}){1,2}$/i.test(color);
}

/**
 * Transforms an hex color code into it's RGB represenation
 *
 * @param hexColor the color hexadecimal code
 *
 * @returns {RGBColor} the RGB representation of the color
 */
export function hexToRGB(hexColor) {
    /** @type {Number|String} */
    let r = 0;
    /** @type {Number|String} */
    let g = 0;
    /** @type {Number|String} */
    let b = 0;

    if (isValidHexColor(hexColor)) {
        // 3 digits
        if (hexColor.length === 4) {
            r = '0x' + hexColor[1] + hexColor[1];
            g = '0x' + hexColor[2] + hexColor[2];
            b = '0x' + hexColor[3] + hexColor[3];

        // 6 digits
        } else if (hexColor.length === 7) {
            r = '0x' + hexColor[1] + hexColor[2];
            g = '0x' + hexColor[3] + hexColor[4];
            b = '0x' + hexColor[5] + hexColor[6];
        }
    }

    return { r: +r, g: +g, b: +b };
}

/**
 * Transforms the given color into its HSL representation
 *
 * @param hexColor the color in hexadecimal format
 *
 * @returns {HSLColor} the three HSL properties representing the given color
 */
export function hexToHSL(hexColor) {
    // Convert hex to RGB first
    let { r, g, b } = hexToRGB(hexColor);

    // Then to HSL
    r /= 255;
    g /= 255;
    b /= 255;

    let cmin = Math.min(r, g, b),
        cmax = Math.max(r, g, b),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

    if (delta === 0)
        h = 0;
    else if (cmax === r)
        h = ((g - b) / delta) % 6;
    else if (cmax === g)
        h = (b - r) / delta + 2;
    else
        h = (r - g) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0)
        h += 360;

    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return { h, s, l };
}
