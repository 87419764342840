<template lang="pug">
.page(v-if="ready")
    component.card-panel(
        :is="responseComponent"
        :params="requestedMessage"
    )

    .card-panel(
        v-if="pagesFound && !requestedMessage"
    )
        .intros(v-if="textPages.length > 0")
            .intro(v-for="text in textPages")
                h3(v-if="text.title") {{text.title}}
                section(v-if="text.text") {{text.text}}

        form.login(@submit.prevent="login" autocomplete="off" novalidate="novalidate")
            .outer-box
                .content(v-if="!showLogins")
                    keep-alive
                        component(:is="currentLoginComponent"
                            :page="currentLoginPage"
                            :allow-non-unique-email="allowNonUniqueEmail"
                            ref="page"
                            @validity="formValidates"
                            @nextChanged="nextChanged"
                            @backChanged="backChanged"
                            @requestLogin="requestLogin"
                            @acceptLegals="toggleAllLegals"
                            @toggleLegals="toggleLegals")

                    .legal-acknowledgements(v-show="showLegals")
                        .form-check(v-if="legalRequirements.length > 0" v-for="(legal, index) in legalRequirements")
                            legal-requirement(:requirement="legal" @selection="legalChange")
                                login-action(:action="legal" target="_blank")

                    .notices(:v-if="showNotices" v-html="$t(notices)")

                .logins(v-if="showLogins")
                    a.btn.login-type(v-for="(page, index) in loginPages" @click.prevent="go(index)") {{$t(page.activation_button_title || 'login.types.' + page.type)}}

            .form-actions.text-right(v-if="!showLogins")
                a.btn.btn-tertiary.back(v-if="showBackButton" @click.prevent="toLogins") {{$t('login.back')}}
                a.btn.btn-tertiary.back(v-if="backEnabled" @click.prevent="back") {{$t('login.back')}}
                button.btn(v-if="showSubmit" :disabled="!submitEnabled") {{$t(nextLabel)}}

    .error.reason(v-if="!pagesFound")
        h3 {{$t('login.error-invalid-url')}}

    .error.callback.reason(v-if="callbackError")
        h4 {{ $t(`login.error.${callbackError}`) }}
        i18n(path="login.error.contact" tag="span")
            a(href="https://spotme.com/help" target="_blank" rel="noopener noreferrer") https://spotme.com/help
        br
        span(v-if="callbackRequest") {{ $t('login.error.request', [callbackRequest]) }}


</template>
<script>
/**
 * Login is an articulated page that loads different components depending
 * on the activation screen configuration.
 *
 * Since a single event can configure several access screen we have to take
 * this into consideration and display a way to choose the desired login
 * process. Once the user chooses the desired way to log in we use that process
 * only for the authentication.
 *
 * Actually the webapp supports 5 out of 7 login processes:
 *
 * 1. Standard login
 * 2. Public login -> no longer supported
 * 3. Account login
 * 4. Standard plus login
 * 5. Single Sign On login
 *
 * Each process loads a different component and handles the requests to and from
 * the server.
 *
 * This very component though is used to handle the whole process. Infact for a
 * component to be listed here must comply a contract.
 *
 * 1. it must declare a `submit` function
 * 2. when it validates it must emit `validity` event with boolean value.
 * 3. optionally can declare a `back` function if it is a multistep process.
 *
 * In this very last case the component can emit `nextChanged` with a label key
 * as payload in order to change the submit button label.
 * The same can be done for the back button with the `backChanged` event.
 *
 * NOTE: To force a login process to submit avoid calling the sub-component
 * submit routine, use `requestLogin` hook instead by emitting `requestLogin`
 * event. This way all callbacks and validations are called correctly.
 *
 * For more details see each sub-component.
 */
import Account from '@/pages/login/types/Account.vue';
import SingleSignOn from '@/pages/login/types/SingleSignOn.vue';
import Standard from '@/pages/login/types/Standard.vue';
import StandardPlus from '@/pages/login/types/StandardPlus.vue';

import DefaultResponse from '@/pages/login/responses/Default.vue';

const LOGIN_TYPES = {
    standard: Standard,
    account_login: Account,
    standard_plus: StandardPlus,
    sso: SingleSignOn
};

export default {
    name: 'Login',

    components: LOGIN_TYPES,

    data() {
        return {
            responseComponent: null,
            allRequirementsAccepted: false,
            callbackError: '',
            callbackRequest: '',
            loginInProgress: false,
            legalRequirements: [],
            textPages: [],
            loginPages: [],
            selectedPageIndex: 0,
            submitEnabled: false,
            formIsValid: false,
            requestedMessage: null,
            nextLabel: 'login.submit',
            backEnabled: false,
            showLogins: true,
            showLegals: true,
            disableSubmit: false,
            backAvailable: true,
            pagesFound: true,
            showNotices: false,
            notices: null,
            ready: false,
            allowNonUniqueEmail: false,
        };
    },

    computed: {
        /** @returns {import('vue').VueConstructor} */
        currentLoginComponent() {
            if (this.currentLoginPage) {
                console.log(
                    '[Login] Current login page:',
                    this.currentLoginPage.type,
                    this.currentLoginPage
                );
                if (this.currentLoginPage.type === 'sso' && this.currentLoginPage.hide_legals) {
                    this.showLegals = false;
                }
                const notices_html = this.currentLoginPage.notices_html
                if (notices_html && notices_html.trim !== '') {
                    this.showNotices = true;
                    this.notices = notices_html;
                } else {
                    this.showNotices = false;
                    this.notices = undefined;
                }
                return LOGIN_TYPES[this.currentLoginPage.type];
            }
        },

        /** @returns {object} */
        currentLoginPage() {
            return this.loginPages[this.selectedPageIndex];
        },

        /** @returns {boolean} */
        showSubmit() {
            return !this.showLogins && !this.disableSubmit;
        },

        /** @returns {boolean} */
        showBackButton() {
            return (
                this.loginPages.length > 1 &&
                !this.backEnabled &&
                !this.showLogins &&
                this.backAvailable
            );
        }
    },

    async created() {
        const branding = await this.$brand.get();
        if (!branding?.body?.webapp?.hideCallbackErrors) {
            this.callbackError = this.$route.query['err'];
            this.callbackRequest = this.$route.query['requestId'];
        }
        // Load logins types
        this.$brand.getLogins().then(logins => {
            if (!logins?.pages){
                this.pagesFound = false;
                console.error('[Login] Pages not found');
                return;
            }
            this.handleLoginEntries(logins.pages.entries())

            console.debug('[Login] Activation types:', this.loginPages);

            if (!this.loginPages.length) {
                // As it is not possible to configure 0 login page in backstage, it means that the
                // only login in the config are unsupported login, do we display a message, instead of showing
                // and empty page
                this.textPages.push({type: 'text', text: 'Login type no longer supported'});
            }
            // If only one activation screen is configured just go to that login.
            if (this.loginPages.length === 1) {
                this.backAvailable = false;
                this.go(0);
                return;
            }
            this.ready = true;
        });

        // Load legal stuff for this event.
        this.$brand.getLegalRequirements().then(requirements => {
            this.legalRequirements = requirements;
            if (!this.legalRequirements) {
                this.allRequirementsAccepted = true;
            }
        });
        this.allowNonUniqueEmail = branding?.body?.config?.allow_non_unique_email;
    },

    methods: {
        handleLoginEntries(entries) {
            for (const [ index, page ] of entries) {
                page.index = index;
                if (page.type === 'text') {
                    this.ready = true;
                    if (
                        (page.title && page.title.trim().length !== 0) ||
                        (page.text && page.text.trim().length !== 0)
                    ) {
                        return this.textPages.push(page);
                    }
                    return;
                }

                if (LOGIN_TYPES[page.type] || page.type === 'redirect') {
                    this.loginPages.push(page);
                } else {
                    console.warn('[Login] `%s` not implemented or no longer supported.', page.type);
                }
            }
        },

        validate() {
            this.allRequirementsAccepted = this.legalRequirements.filter(l => l.label).every(
                legal => legal.accepted
            );
            const { hide_legals } = (this.currentLoginPage || {})
            this.submitEnabled =
                hide_legals || this.allRequirementsAccepted && this.formIsValid;
            return this.submitEnabled;
        },

        formValidates(validity) {
            this.formIsValid = validity;
            this.validate();
        },

        legalChange(id, checked) {
            this.legalRequirements.forEach(legal => {
                if (legal.id === id) {
                    legal.accepted = checked;
                }
            });

            this.validate();
        },

        toggleAllLegals(toggle) {
            this.legalRequirements.forEach(legal => {
                legal.accepted = toggle;
            });
        },

        reset(resetLabels) {
            if (resetLabels) {
                this.nextChanged('login.submit');
            }
            this.toggleLegals(true);
            this.backChanged(false);
            this.formValidates(false);

            this.selectedPageIndex = -1;
        },

        /**
         * Activates the corresponding login method.
         */
        go(index) {
            if (this.selectedPageIndex !== index) {
                this.reset(true);
            }

            this.selectedPageIndex = index;

            if (this.currentLoginPage) {
                this.$track.startTrackLogin(this.currentLoginPage.type);

                const target = this.currentLoginPage.endpoint || this.currentLoginPage.url;
                if (!this.currentLoginComponent && target) {
                    this.$services.routing.redirect(target);
                    return;
                }
            }
            this.ready = true;
            this.showLogins = false;
        },

        toLogins() {
            this.reset();
            this.showLogins = true;
            this.toggleLegals(false);
            this.toggleAllLegals(false);
        },

        // Called on `nextChanged` emitted event
        nextChanged(label) {
            if (typeof label === 'string') {
                this.nextLabel = label;
                this.disableSubmit = false;
            } else {
                this.disableSubmit = label;
            }
        },

        // Called on `backChanged` emitted event
        backChanged(state) {
            this.backEnabled = state;
        },

        // Called on `toggleLegals` emitted event
        toggleLegals(visibility) {
            this.showLegals = visibility;
        },

        // Called on `requestLogin` emitted event
        requestLogin() {
            console.info('[Login] Performing delegated login...');
            this.login(true);
        },

        // ================
        // Footer callbacks
        // ================

        back() {
            const backFn = this.$refs.page.back;
            if (!backFn) {
                console.warn('[Login] back called but no back `fn()` found.');
                return;
            }
            return backFn();
        },

        login(skipRequirements) {
            if (!skipRequirements && !this.allRequirementsAccepted) {
                return;
            }

            const submitFn = this.$refs.page.submit;
            if (!submitFn) {
                console.warn(
                    '[Login] login component does not declare submit `fn()`.'
                );
                return;
            }

            const result = submitFn();
            if (!result) return;

            if (this.loginInProgress) {
                return;
            }
            this.loginInProgress = true;

            result
                .then(message => {
                    if (message) {
                        this.requestedMessage = message;
                    }
                    this.responseComponent = message.component || DefaultResponse;
                })
                .catch(() => this.requestedMessage = null)
                .finally(() => this.loginInProgress = false);
        }
    }
};

</script>
