export default {
    data() {
        return {
            highResItems: 6,
            lowResItems: 4,
            maxItems: 4,
            mediaQuery: window.matchMedia('(max-width: 767px)')
        };
    },

    created() {
        this.mediaQuery.addEventListener('change', this.setMaxItems);
        this.setMaxItems();
    },

    beforeDestroy() {
        this.mediaQuery.removeEventListener('change', this.setMaxItems);
    },

    methods: {
        /** @param {MediaQueryList} [event] */
        setMaxItems(event) {
            this.maxItems = (event || this.mediaQuery).matches ? this.lowResItems : this.highResItems;
        }
    }
};
