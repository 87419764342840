export const Branding = {
    cache: null,

    /**
     * @param {import('vue').Component} vue
     */
    init(vue) {
        console.debug('[Branding] init...');
        this.vue = vue;
    },

    async get(brand) {
        const branding = brand || this.vue.$route.params.branding;
        if (!branding) {
            throw 'no branding resolved';
        }

        try {
            const body = await this.vue.$services.branding.getAppConfig(branding);
            return { body };
        } catch (error) {
            console.error('[Branding] Could not load branding configuration:', error.message);

            return { body: { } };
        }
    },

    getLogins() {
        const mapWithLog = ({ body }) => {
            console.debug('[Branding] Brand definition:', body);
            return { pages: body.pages };
        };
        const defaultPage = () => ({
            pages: [ { type: 'standard' } ]
        });

        return this.get()
            .then(mapWithLog)
            .catch(defaultPage);
    },

    getLegalRequirements(shortcode) {
        const branding = this.vue.$route.params.branding;

        if (shortcode) {
            return this.vue.$services.branding.getEventLegalRequirements(branding, shortcode);
        } else {
            return this.vue.$services.branding.getAppLegalRequirements(branding);
        }
    }
};

export default {
    install(Vue) {
        Vue.prototype.$brand = Branding;
    },
};
