<template lang="pug">
a(:href="url" v-if="url" :target="target") {{ title }}
</template>
<script>
export default {
    name: 'LoginAction',

    props: [ 'action', 'target' ],

    data() {
        let href;

        if (this.action.type === 'url') {
            href = this.action.url;
        }

        if (this.action.type === 'email') {
            href = [];
            if (this.action.subject) {
                href.push(`subject=${encodeURIComponent(this.$i18n.t(this.action.subject))}`);
            }
            if (this.action.body) {
                href.push(`body=${encodeURIComponent(this.$i18n.t(this.action.body))}`);
            }
            href = href.length ? '?' + href.join('&') : '';
            href = `mailto:${this.action.email}${href}`;
        }

        // links could be misconfigured
        if (href && href.length) {
            href = href
                .replace('{{app_id}}', this.$const.APP_ID)
                .replace('{{branding_id}}', this.$route.params.branding)
                .replace('{{device_model}}', '')
                .replace(
                    '{{os}}',
                    encodeURIComponent(window.navigator.userAgent)
                )
                .replace('{{os_version}}', '')
                .replace('{{app_version}}', '')
                .replace('{{device_id}}', '')
                .replace('{{system_lang}}', window.navigator.language || '')
                .replace('{{system_locale}}', window.navigator.language || '');
        }

        const title =
            this.action.title.indexOf(' ') !== -1
                ? this.action.title
                : this.$i18n.t(this.action.title);
        return {
            url: href,
            title: title
        };
    }
};
</script>
