<template lang="pug">
button.btn.logout-button(type="button" @click="logout") {{ $t('registration.actions.logout') }}
</template>
<script>

export default {
    name: 'LogoutButton',

    methods: {
        async logout() {
            const branding = this.$route.params.branding;
            await this.$services.session.logout(branding);
            this.$services.routing.reload();
        }
    }
};
</script>
