import VueI18n from 'vue-i18n';
import en from './locales/en.json';
import { getFirstEligibleLocale, remapMessages } from '@/libs/utils/locales';
import { isEmpty } from 'lodash';

const AUTH_LOCALES = { en };

export function createLocales(Vue) {
    Vue.use(VueI18n);

    // Plugins configuration
    const i18n = new VueI18n({
        locale: 'en',
        fallbackLocale: 'en',
        messages: AUTH_LOCALES
    });

    /**
     * Updates the default messages with the given ones and
     * sets the new first user's/system eligible locale
     *
     * @param {object} dictionary
     * @param {string[]} [supportedLang]
     * @param {string} [preferredLocale]
     */
    Vue.prototype.$updateMessages = function(dictionary, supportedLang, preferredLocale) {
        const messages = remapMessages(this.$i18n.messages, dictionary);

        if (isEmpty(supportedLang)) {
            // Backward compatibility
            supportedLang = Object.keys(messages);
        }

        for (const lang of Object.keys(messages)) {
            if (lang === 'en' || supportedLang.includes(lang)) {
                this.$i18n.setLocaleMessage(lang, messages[lang]);
            }
        }

        const locale = getFirstEligibleLocale({ locales: supportedLang, preferredLocale });
        this.$i18n.locale = locale;
    };

    return i18n;
}
