/**
 * Provides methods necessary for browser navigation to various app URLs.
 *
 * @example
 * import RoutingService from 'libs/services/routing';
 * ...
 * const routing = new RoutingService();
 */
export default class RoutingService {
    /**
     * @param {import('@/libs/providers/window').default} windowProvider proxy to the browser's window object.
     */
    constructor(windowProvider) {
        this.window = windowProvider;
    }

    /**
     * Redirects the browser to the specified URL
     *
     * @param {string} url the URL to open
     * @param {object} [params] extra params to append to the route
     */
    redirect(url, params) {
        if (params && Object.values(params).filter(Boolean).length) {
            const search = new URLSearchParams(params);
            const appender = url.includes('?') ? '&' : '?';
            url = `${url}${appender}${search.toString()}`;
        }

        this.window.location.assign(url);
    }

    /**
     * Open targetUrl in a new tab/window.
     *
     * @param {String} targetUrl the url to open
     */
    openNewWindow(targetUrl) {
        return this.window.open(targetUrl, '_blank', 'noreferrer,noopener');
    }

    /**
     * Reloads the current URL
     */
    reload() {
        this.window.location.reload();
    }
}
