<template lang="pug">
input(
    ref="input"
    :id="uid"
    :name="name"
    :value="value"
    type="hidden"
    v-bind="$attrs")
</template>
<script>
// Components
import BaseControl from './BaseControl.vue';

export default {
    name: 'HiddenField',

    extends: BaseControl,

    props: {
        value: {
            type: [String, Number],
            default: null
        }
    }
};
</script>
