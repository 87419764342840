/**
 * This class is a wrapper around the Storage object and
 * can be specific for a given prefix, useful for storing
 * event's specific elements.
 */
export default class StorageService {

    /**
     * Builds an instance of StorageService
     *
     * @param {Storage} storage an interface to the Storage
     */
    constructor(storage) {
        this.storage = storage;
    }

    /**
     * When passed a number n, this method will return the name of the nth key in the storage.
     *
     * @param {number} index An integer representing the number of the key you want to get the name of. This is a zero-based index.
     *
     * @returns {string|null} A string containing the name of the key. If the index does not exist, null is returned.
     */
    key(index) {
        return this.storage.key(index);
    }

    /**
     * When passed a key name, will return that key's value.
     *
     * @param {string} keyName A string containing the name of the key you want to retrieve the value of.
     *
     * @returns {string|null} A string containing the value of the key. If the key does not exist, null is returned.
     */
    getItem(keyName) {
        return this.storage.getItem(keyName);
    }

    /**
     * When passed a key name, will return that key's value.
     *
     * @param {string} prefix a prefix to use for the stored item.
     * @param {string} keyName A string containing the name of the key you want to retrieve the value of.
     *
     * @returns {string|null} A string containing the value of the key. If the key does not exist, null is returned.
     */
    getItemWithPrefix(prefix, keyName) {
        return this.getItem(this.scopedKey(keyName, prefix));
    }

    /**
     * When passed a key name and value, will add that key to the storage, or update that key's value if it already exists.
     *
     * @param {string} keyName A string containing the name of the key you want to create/update.
     * @param {string} keyValue A string containing the value you want to give the key you are creating/updating.
     *
     * @throws {Error} may throw an exception if the storage is full. Developers should make sure to always catch possible exceptions from setItem().
     */
    setItem(keyName, keyValue) {
        return this.storage.setItem(keyName, keyValue);
    }

    /**
     * When passed a key name and value, will add that key to the storage, or update that key's value if it already exists.
     *
     * @param {string} prefix a prefix to use for the stored item.
     * @param {string} keyName A string containing the name of the key you want to create/update.
     * @param {string} keyValue A string containing the value you want to give the key you are creating/updating.
     *
     * @throws {Error} may throw an exception if the storage is full. Developers should make sure to always catch possible exceptions from setItem().
     */
    setItemWithPrefix(prefix, keyName, keyValue) {
        return this.setItem(this.scopedKey(keyName, prefix), keyValue);
    }

    /**
     * When passed a key name, will remove that key from the storage.
     *
     * @param {string} keyName A string containing the name of the key you want to remove.
     */
    removeItem(keyName) {
        return this.storage.removeItem(keyName);
    }

    /**
     * When passed a key name, will remove that key from the storage.
     *
     * @param {string} prefix a prefix to use for the stored item.
     * @param {string} keyName A string containing the name of the key you want to remove.
     */
    removeItemWithPrefix(prefix, keyName) {
        return this.removeItem(this.scopedKey(keyName, prefix));
    }

    /**
     * When invoked, will empty all keys out of the storage.
     */
    clear() {
        return this.storage.clear();
    }

    /**
     * Builds and returns the scoped key
     *
     * @param {string} keyName a string containing the name of the key of the desired object
     * @param {string} [prefix] an optional prefix for the key
     *
     * @returns {string} the uniquely scoped key name
     */
    scopedKey(keyName, prefix) {
        return [ prefix, keyName ].filter(p => p).join('_');
    }
}
