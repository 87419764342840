<template lang="pug">
.sponsor-card(@click="openDetails" :class="{ 'has-action': hasMoreDetails }")
    picture
        img(v-if="logo" :srcset="`${logo} 2x`" :alt="sponsor.title" @error="logo = null")
        sponsor-placeholder-image(v-else)

    .details
        .title {{ sponsor.title }}

    .level(v-if="sponsor.level" :style="{ color: sponsor.level.text_color, 'background-color': sponsor.level.level_color }")
        | {{ sponsor.level.level_name }}
</template>
<script>
// Components
import SponsorDetails from '@/components/registration/sections/SponsorDetails.vue';
import SponsorPlaceholderImage from '@/components/registration/sections/SponsorPlaceholderImage.vue';

export default {
    name: 'SponsorCard',

    components: { SponsorPlaceholderImage },

    props: {
        sponsor: {
            type: Object,
            required: true
        },

        config: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            hasMoreDetails: false,
            logo: this.sponsor.logo
        }
    },

    created() {
        const { location, website, email, description } = this.sponsor;
        this.hasMoreDetails = [location, website, email, description].some(d => Boolean((d || '').trim().length));
    },

    methods: {
        openDetails() {
            if (!this.hasMoreDetails) {
                return;
            }

            this.$modal.show(
                SponsorDetails,
                {
                    sponsor: this.sponsor
                },
                {
                    adaptive: true,
                    height: 'auto',
                    maxWidth: 584,
                    width: '90%'
                }
            );
        }
    }
};
</script>
