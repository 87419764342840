<template lang="pug">
#app(v-cloak :class="{'ready': appReady}")
    .container(v-if="errors.length")
        .error(:class="{'in': errors}")
            p(v-for="error in errors") {{ $te(error.message) ? $t(error.message) : error.message }}

    router-view(v-if="!errors.length && appReady" :session="session" :branding="branding")

    footer-actions(v-if="showFooterActions" :branding="branding")

    cookies(v-if="appReady")
    loading
    modals-container
    v-dialog
</template>
<script>
// Utils
import { get } from 'lodash';

// Components
import Cookies from '@/components/Cookies.vue';
import FooterActions from '@/components/FooterActions.vue';
import Loading from '@/components/Loading.vue';

export default {
    name: 'App',

    components: { Cookies, FooterActions, Loading },

    props: {
        errors: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            appReady: false,
            session: {},
            branding: {},
            preventRedirect: false,
            legacyThemeAppended: false,
            theme: ''
        };
    },

    computed: {
        showFooterActions() {
            return this.appReady && this.branding && !this.$route.name?.startsWith('registration');
        }
    },

    watch: {
        branding: {
            immediate: true,
            handler() {
                this.injectLegacyTheme();
            }
        },

        '$route.name'() {
            this.injectLegacyTheme();
        }
    },

    async created() {
        try {
            const routeName = this.$route.name || '';

            if (this.$route.path.startsWith('/pass')) {
                this.appReady = true;
                return;
            }

            const { query, params } = this.$route;
            const token = query.invitationToken || params.token;
            const branding = this.$route.params.branding;

            if (routeName.startsWith('registration') && branding === 'templates_registration') {
                return;
            }

            this.branding = await this.$services.branding.getAppConfig(branding);
            this.session = await this.$services.session.login(branding, this.$i18n.locale, token);

            const hasInvites = Boolean(this.session.invites.length) || Boolean(this.session.contentHubs.length);
            const hasToken = Boolean(this.session.token);

            if (!routeName.startsWith('registration')) {
                if (hasInvites && routeName !== 'invitations') {
                    console.info('[App] heading to invitations...');
                    this.$router.push({ name: 'invitations', params: { branding }, query: this.$route.query });

                } else if (!hasToken && routeName === 'invitations') {
                    console.info('[App] no valid invitations token');
                    // only redirect pages that require login
                    this.$router.push({ name: 'login', params: { branding } });
                }
            }

            // Store current branding for future visits
            this.$cookies.set(this.$const.BRANDING_COOKIE_NAME, branding);

        } catch (error) {
            console.error('[App] Could not initialize application:', error);

        } finally {
            this.appReady = true;
            console.info('[App] Auth app ready...');
        }
    },

    methods: {
        injectLegacyTheme() {
            if (!this.legacyThemeAppended && !this.$route.meta.noTheme && get(this.branding, 'theme')) {
                console.debug('[App] Appending legacy theme', this.$route.meta);
                this.theme = this.$utils.theme.legacyThemeToCss(this.branding);
                this.legacyThemeAppended = true;
            }
        }
    },

    metaInfo() {
        return {
            htmlAttrs: { lang: this.$i18n.locale },
            title: 'Login',
            titleTemplate: '%s | SpotMe',
            style: [{ cssText: this.theme, type: 'text/css', id: '_legacy_theme_' }],
            link: [
                { vmid: 'main-style', rel: 'stylesheet', href: `${window.APP.assetsPath}/css/auth${window.APP.assetSuffix}.css` },
                { rel: 'icon', href: this.$utils.assets.getStaticAssetSrc('favicon.svg'), vmid: 'favicon' }
            ]
        };
    }
};
</script>
