<template lang="pug">
.availability
    span.error(v-if="session._errors && session._errors.length")
        p {{ $t(errorLabel) }}

    span.waitlisted-available(v-else-if="showNewSlotAvailable")
        | {{ $t('registration.form.labels.availability.available') }}

    span.available(v-else-if="showAvailableSeats")
        i18n(tag="p" path="registration.form.labels.availability.available_seats")
            strong {{ session.available_seats }}

    span.closed(v-else-if="!session.can_register")
        | {{ $t('registration.form.labels.availability.closed') }}

    span.waitlist(v-else-if="isInSavedWaitlist && isInUnsavedWaitlist")
        | {{ $t('registration.form.labels.availability.waitlist_signed_up') }}

    span.waitlist(v-else-if="!isInSavedWaitlist && isInUnsavedWaitlist")
        | {{ $t('registration.form.labels.availability.save_join') }}
        i.icon-info(@mouseover="showJoinTip = true" @mouseleave="showJoinTip = false")

    span.waitlist(v-else-if="isInSavedWaitlist && !isInUnsavedWaitlist")
        | {{ $t('registration.form.labels.availability.save_leave') }}

    span.full(v-else-if="!session.hasAvailableSeats()")
        | {{ $t('registration.form.labels.availability.session_full') }}

    .tip(v-if="showJoinTip")
        p {{ $t('registration.form.labels.availability.save_leave_tip') }}

</template>
<script>
export default {
    name: 'SessionControlAvailability',

    props: {
        orchestrator: {
            type: Object,
            required: true
        },

        session: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            hasDetails: this.session.description?.length,
            showJoinTip: false
        };
    },

    computed: {
         /**
         * Checks if a session has available seats.
         *
         * @returns {string} - label
         */
        errorLabel() {
            const errorKeyParts = this.session._errors[0]?.split('.');
            const errorReason = errorKeyParts[errorKeyParts.length-1];

            let errorKey = `registration.form.labels.availability.${errorReason}`;
            if (this.$te(errorKey)) {
                return errorKey;
            }
            return 'registration.form.labels.availability.unkown_error';
        },

        /**
         * Checks if the user is already subscribed to a session waitlist and the session is now available to register.
         *
         * @returns {boolean} - True if the user is in the waitlist and the session is available, false otherwise.
         */
        showNewSlotAvailable() {
            return this.orchestrator.hasSessionNewAvailableSlots(this.session);
        },

        /**
         * Wether to show the available seats for a session or not.
         *
         * @returns {boolean} - True if the available seats should be shown, false otherwise.
         */
        showAvailableSeats() {
            const hasFiniteNumber = Number.isFinite(this.session.available_seats);
            return this.session.can_register && this.session.hasAvailableSeats() && hasFiniteNumber;
        },

        /**
         * Checks if the session is in the temporary waitlist.
         *
         * @returns {boolean} True if the user is in the waitlist, false otherwise.
         */
        isInUnsavedWaitlist() {
            return this.orchestrator.isSessionInUnsavedWaitlist(this.session);
        },

        /**
         * Checks if the user is already subscribed to a session waitlist.
         *
         * @returns {boolean} True if the user is already subscribed, false otherwise.
         */
        isInSavedWaitlist() {
            return this.orchestrator.isSessionInSavedWaitlist(this.session);
        },
    }
};
</script>
