<template lang="pug">
section.sponsor(v-if="visible")
    h3(v-if="title") {{ title }}
    .description(v-if="description" v-html="description")

    expandable-list(
        item-key="sponsor"
        list-class="sponsors"
        :items="sponsors"
        :component="component"
        :max-items="maxItems"
        :params="{ config }")
</template>
<script>
// Utils
import { get } from 'lodash';

// Mixins
import maxItems from './max-items.mixin';
import section from './section.mixin';

// Components
import SponsorCard from '@/components/registration/sections/SponsorCard.vue';

export default {
    name: 'Sponsor',

    mixins: [section, maxItems],

    data() {
        return {
            component: SponsorCard,
            sponsors: get(this, 'section.sponsors', [])
        }
    }
};
</script>
