<template lang="pug">
control-decorator(:label="label" :tip="tip" :hint="hint" :errors="errors" :uid="uid" :hint-after-label="true")
    .buttons-choice-control
        label.button(v-for="opt in options" :key="opt.value" :class="{selected: value === opt.value, disabled }")
            input(
                type="radio"
                :checked="value === opt.value"
                :disabled="disabled"
                :name="name"
                :value="opt.value"
                @input="input")
            span.text {{ opt.label }}
</template>
<script>
// Components
import BaseControl from './BaseControl.vue';

export default {
    name: 'ButtonsChoiceControl',

    extends: BaseControl,

    props: {
        options: {
            type: Array,
            required: true
        }
    }
};
</script>
