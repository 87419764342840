<template lang="pug">
.welcome
    .error.reason(v-if="reason")
        | {{$t(`activation.errors.${reason}`)}}
    .card-panel
        img.splash(v-if="splash" :src="splash" :alt="$t('activation.welcome_screen.welcome_title')")

        header
            h2 {{$t('activation.welcome_screen.welcome_title')}}

        section
            p {{$t('activation.welcome_screen.welcome_text')}}
            router-link.btn(:to="{name: 'login', query: $route.query}") {{$t('activation.welcome_screen.welcome_button_title')}}

        install

</template>
<script>
// Components
import Install from '@/components/Install.vue';

export default {
    name: 'Home',

    components: { Install },

    props: {
        session: {
            type: Object,
            required: true
        },

        branding: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            splash: null,
            reason: null
        };
    },

    created() {
        if (this.branding.splashImage) {
            this.splash = this.$utils.assets.getAssetSrc('splash-image', this.$route.params.branding);
        } else {
            this.splash = this.$utils.assets.getStaticAssetSrc('splash.png');
        }

        const reason = this.$route.query.reason;
        if (reason !== 'logout') {
            this.reason = reason;
        }
    },

    metaInfo() {
        return {
            title: this.$i18n.t('activation.welcome_screen.welcome_title').toString()
        };
    }
};

</script>
