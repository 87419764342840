<template lang="pug">
.sign-up
    main(ref="main")
        main-banner(:config="config")

        .container.page-wrapper: .inner-wrapper
            event-card(:config="config")
            registration-forms(:config="config" :editing="editing" @form-change="onFormChange")

            portal-target(name="sign-up-root")

            disclaimers(:requirements="config.event_details.requirements")
</template>
<script>
// Utils
import { get } from 'lodash';

// Components
import Disclaimers from '@/components/registration/Disclaimers.vue';
import EventCard from '@/components/registration/EventCard.vue';
import MainBanner from '@/components/layout/MainBanner.vue';
import RegistrationForms from '@/components/registration/RegistrationForms.vue';

export default {
    name: 'SignUp',

    components: {
        Disclaimers,
        EventCard,
        MainBanner,
        RegistrationForms
    },

    props: {
        config: {
            type: Object,
            required: true
        },

        session: {
            type: Object,
            required: true
        },

        editing: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            activationUrl: get(this.config, 'user.activationUrl'),
            regDisabled: !this.$services.publicLogin.canRegister(this.config),
            updateDisabled: !this.$services.publicLogin.canUpdateRegistration(this.config)
        };
    },

    created() {
        const updatingUser = this.$services.publicLogin.isUpdating(this.config);
        const userLimitReached = this.$services.publicLogin.isUserLimmitReached(this.config);
        const goToReg = !updatingUser && this.regDisabled || updatingUser && this.updateDisabled || userLimitReached;
        if (goToReg) {
            this.$router.replace({ name: 'registration' });
            return;
        }

        if (this.activationUrl && !this.editing) {
            this.$router.replace({ name: 'registration-success' });
        }
    },

    metaInfo: {
        bodyAttrs: {
            class: ['alt']
        }
    },

    methods: {
        onFormChange() {
            this.$refs.main.scroll(0, 0);
        }
    }
};
</script>
