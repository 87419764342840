<template lang="pug">
.page
  .card-panel
    header
      h2 {{$t('account.account_reset_password.title')}}

    section
      .text-center(v-if="result")
        p {{$t(result)}}
        a(@click.prevent="back") ‹ {{$t('login.back')}}

      form.password-reset(@submit.prevent="reset" v-if="!result")
        p.intro {{$t('account.account_reset_password.subtitle')}}

        .form-group(v-if="showEmail")
          label {{$t('account.account_reset_password.email_label')}}
          input.form-control.validate(
            type="email"
            v-model="email"
            :class="{ 'is-invalid': emailError }"
            :disabled="submitting"
            :placeholder="$t('account.account_reset_password.email_placeholder')"
            maxlength="255",
            autocomplete="off"
            required
            @keyup="touch('email')")
          .invalid-feedback
            p {{$t('login.email_send_failed')}}

        .form-group(v-if="!token")
          label {{$t('account.account_reset_password.old_password')}}
          input.form-control.validate(
            type="password"
            v-model="oldPassword"
            :class="{ 'is-invalid': oldPasswdError }"
            :disabled="submitting"
            :placeholder="$t('account.account_reset_password.old_password_placeholder')"
            maxlength="255",
            autocomplete="off"
            required
            @keyup="touch('oldPassword')")
          .invalid-feedback
            p {{$t(email ? 'login.password_changed_email_failed' : 'login.password_changed_token_failed')}}

        .form-group
          label {{$t('account.account_reset_password.password')}}
          input.form-control.validate(
            type="password"
            v-model="password"
            :class="{ 'is-invalid': passwdError }"
            :disabled="submitting"
            :placeholder="$t('account.account_reset_password.password_placeholder')"
            maxlength="255",
            autocomplete="off"
            required
            @keyup="touch('password')")
          .invalid-feedback
            p {{$t('login.change_password_description')}}

        .form-group
          label {{$t('account.account_reset_password.confirm_password')}}
          input.form-control.validate(
            type="password"
            v-model="passwordConfirmation"
            :class="{ 'is-invalid': passwdConfirmError }"
            :disabled="submitting"
            :placeholder="$t('account.account_reset_password.password_placeholder')"
            maxlength="255",
            autocomplete="off"
            required
            @keyup="touch('passwordConfirmation')")
          .invalid-feedback
            p {{$t('login.password_error_no_match')}}

        .form-actions.text-right
          button.btn.back.btn-tertiary(@click.prevent="back") {{$t('account.account_reset_password.cancel')}}
          button.btn(:disabled="submitting") {{$t('account.account_reset_password.save_password')}}

</template>
<script>
// *****************************************************************************
// TODO: change `backend/lib/directory-service/server/lib-account.js` to
// redirect properly:
// var url = emailConfig.webAppUrl + '/password/' + branding + '/' + token + '/' + scheme;
// around line 470
// *****************************************************************************


export default {
    name: 'Password',

    data() {
        return {
            submitting: false,
            email: null,
            emailError: false,
            showEmail: false,
            oldPassword: '',
            oldPasswdError: false,
            password: '',
            passwdError: false,
            passwordConfirmation: '',
            passwdConfirmError: false,
            token: this.$route.params.token,
            result: false,
            touched: {
                email: false,
                password: false,
                oldPassword: false,
                passwordConfirmation: false
            }
        };
    },

    created() {
        this.$root.preventRedirect = false;
        if (!this.token) {
            this.email = this.$root.account;
            this.showEmail = true;
        }
    },

    methods: {
        touch(field) {
            this.touched[field] = true;
        },

        validate() {
            let valid = true;
            if (this.touched['email']) {
                if (!this.$strings.validateEmail(this.email)) {
                    valid = false;
                    this.emailError = true;
                } else {
                    this.emailError = false;
                }
            }

            if (this.touched['password']) {
                if (this.password.length === 0) {
                    this.passwdError = true;
                    valid = false;
                } else {
                    this.passwdError = false;
                }
            }

            if (this.touched['oldPassword']) {
                if (this.oldPassword.length === 0) {
                    this.oldPasswdError = true;
                    valid = false;
                } else {
                    this.oldPasswdError = false;
                }
            }

            if (this.touched['passwordConfirmation']) {
                if (this.password !== this.passwordConfirmation) {
                    this.passwdConfirmError = true;
                    valid = false;
                } else {
                    this.passwdConfirmError = false;
                }
            }

            return valid;
        },

        back() {
            if (this.$root.invitationToken) {
                this.$router.push({
                    name: 'invitations',
                    params: {
                        branding: this.$route.params.branding,
                        token: this.$root.invitationToken
                    }
                });
            } else {
                this.$router.push({
                    name: 'login',
                    params: this.$route.params
                });
            }
        },

        async reset() {
            try {
                if (!this.validate()) {
                    return false;
                }

                this.submitting = true;

                const data = {
                    branding: this.$route.params.branding,
                    newPassword: this.password
                };

                if (this.email) {
                    data.email = this.email;
                    data.password = this.oldPassword;
                } else {
                    data.token = this.token;
                }

                await this.$services.account.changePassword(data);
                this.result = 'login.password_changed';
            } catch (error) {
                this.emailError = true;
                this.oldPasswdError = true;

            } finally {
                this.submitting = false;
            }

        }
    }
};

</script>
