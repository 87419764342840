<template lang="pug">
footer.main(v-if="enabled")
    .container
        .imprint
            span.footer-text {{ imprint }}
</template>
<script>
export default {
    name: 'MainFooter',

    props: {
        config: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            enabled: this.config.theme.hide_footer === false,
            imprint: this.config.theme.footer_text
        };
    }
};
</script>
