<template lang="pug">
.dropdown-button(
    v-click-outside="clickedOutside"
    :class="{ important }"
)
    button.btn(
        @click="menuIn = !menuIn"
        :class="{'in': menuIn, 'btn-medium': important, ...buttonClasses}"
        type="button"
    )
        slot

    transition(name="fade")
        .menu(v-if="menuIn && $slots.menu" @click="handleMenuSelected")
            slot(name="menu")
</template>
<script>
export default {
    name: 'DropdownButton',

    props: {
        buttonClasses: {
            type: Object,
            default: () => ({})
        },

        important: {
            type: Boolean,
            default: false
        },

        closeOnSelect: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            menuIn: false,
        };
    },

    methods: {
        clickedOutside() {
            this.menuIn = false;
        },
        
        handleMenuSelected() {
            if (this.closeOnSelect) {
                this.menuIn = false;
            }
        }
    }
};
</script>
