// Utils
import { isArray, isEmpty, isString, isUndefined, isObject } from 'lodash';

// Constants

/** @const {RegEx} RE_TRIM Used to match leading and trailing whitespace. */
const RE_TRIM = /^\s+|\s+$/g;

/** @const {RegEx} RE_IS_BINARY Used to detect binary string values. */
const RE_IS_BINARY = /^0b[01]+$/i;

/** @const {RegEx} RE_IS_OCTAL Used to detect octal string values. */
const RE_IS_OCTAL = /^0o[0-7]+$/i;

/** @const {RegEx} RE_IS_BAD_HEX Used to detect bad signed hexadecimal string values. */
const RE_IS_BAD_HEX = /^[-+]0x[0-9a-f]+$/i;

/** @const {string[]} NUMERIC_KINDS Used to determine which type a fields should return. */
const NUMERIC_KINDS = [ 'number', 'timestamp' ];

/** @const {string[]} FALSEY_VALUES Used to detect falsey string values. */
const FALSEY_VALUES = [ '', '0', 'false', 'no', 'off' ];

/**
 * Transforms first letter of the given value to a capital letter.
 *
 * @param {String} value the string to capitalize.
 * @return {String} the given string with a capitalized first letter.
 */
export function capitalize(value) {
    if (!isString(value)) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1);
}

/**
 * Transforms the given string to a human readable form.
 *
 * @param {String} string the string to humanize
 *
 * @returns {String} a humanized representation of the given string.
 */
export function humanize(string = '') {
    return untokenize(string, ' ');
}

/**
 * Removes all punctuation symbols from the string leaving only alphanumeric characters.
 *
 * @param {String} [string=''] the string to untokenize
 * @param {String} [replacement=''] the string to replace tokens with
 *
 * @returns {String} the given string without tokens.
 */
export function untokenize(string = '', replacement = '') {
    const rep = replacement.length ? `( |${replacement}){2,}` : '( ){2,}';
    const adjacentChecker = replacement.length ? replacement : ' ';

    return string
        .replace(/[^\w\s]|_/g, replacement) // removes all tokens
        .replace(new RegExp(rep, 'gm'), adjacentChecker); // remove all adjacents spaces
}

/**
 * Regardless the type this method checks if the given
 * value is a numeric type.
 *
 * It can be a String containing a numeric value, exponential
 * notation, or a Number object
 *
 * @param {string|number} n the number or string to check
 *
 * @returns {boolean} whether the given argument is a numeric value
 */
export function isNumeric(n) {
    // @ts-ignore
    return !isNaN(parseFloat(n)) && isFinite(n);
}

/**
 * Converts `value` to a number.
 *
 * @param {*} value The value to process.
 *
 * @returns {number} Returns the number.
 *
 * @example
 *
 * toNumber(Number.MIN_VALUE);
 * // => 5e-324
 *
 * toNumber(Infinity);
 * // => Infinity
 *
 * toNumber('3.2');
 * // => 3.2
 */
export function toNumber(value) {
    if (typeof value === 'number') {
        return value;
    }

    if (typeof value === 'symbol') {
        return NaN;
    }

    if (isObject(value)) {
        const other = typeof value.valueOf === 'function' ? value.valueOf() : value;
        value = isObject(other) ? (other + '') : other;
    }

    if (typeof value !== 'string') {
        return value === 0 ? value : +value;
    }

    value = value.replace(RE_TRIM, '');
    const isBinary = RE_IS_BINARY.test(value);

    if (isBinary || RE_IS_OCTAL.test(value)) {
        return parseInt(value.slice(2), isBinary ? 2 : 8);
    }

    if (RE_IS_BAD_HEX.test(value)) {
        return NaN;
    }

    const numeric = Number.parseFloat(value);
    const hasDecimal = numeric % 1 !== 0;

    if (hasDecimal) return numeric;

    return Number.parseInt(value);
}

/**
 * Sanitizes the parameter that comes down from the URL.
 * Parameters come as a string, with this method we
 *
 * @param {object} field the field
 * @param {any} param the value of the parameter to sanitize
 * @param {boolean} [flatten=false] whether to flatten array values into a single string
 *
 * @return {string|string[]|number|boolean} the sanitized version of the parameter value
 */
export function sanitizeParam(field, param, flatten) {
    if (isEmpty(param) || isUndefined(param)) {
        param = '';
    }

    if (isArray(param)) {
        for (const [ i, part ] of param.entries()) {
            param[i] = this.sanitizeParam(field, part);
        }

        if (flatten) {
            param = param.join(',');
        }
    }

    if (isString(param)) {
        param = param.trim();
    }

    if (NUMERIC_KINDS.includes(field.kind) && isNumeric(param)) {
        param = toNumber(param);
    }

    if (field.kind === 'boolean') {
        param = !FALSEY_VALUES.includes(param);
    }

    return param;
}


