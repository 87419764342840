<template lang="pug">
.single-sign-on
    h2 {{$t(page.title)}}
    p.intro {{$t(page.text)}}
</template>
<script>
export default {
    name: 'SingleSignOn',

    props: [ 'page' ],

    activated() {
        this.$emit('validity', true);
    },

    methods: {
        submit() {
            this.$track.endTrackLogin(true);
            try {
                const redirect = new URL(this.page.endpoint);
                redirect.searchParams.append('app_id', this.$const.APP_ID);
                if (this.$route.query.redirect) {
                    redirect.searchParams.append('redirect', this.$route.query.redirect);
                }
                this.$services.routing.redirect(redirect.toString());
            } catch (err) {
                console.error('Could not redirect to endpoint', this.page.endpoint);
            }
        }
    }
};
</script>
