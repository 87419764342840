<template lang="pug">
.main-banner(v-if="enabled")
    div(:class="{container: !fullWidth, 'page-wrapper': !fullWidth}")
        .inner-wrapper
            figure
                picture(:class="{'full-width': fullWidth}")
                    img(:src="banner" :alt="$t('event_details.title')" @error="enabled = false")
</template>
<script>
// Utils
import { get } from 'lodash';

export default {
    name: 'MainBanner',

    props: {
        config: {
            type: Object,
            required: true
        },

        fullWidth: {
            type: Boolean,
            default: false
        }
    },

    data() {
        const brand = this.$route.params.branding;
        const templateEid = this.config.templateEid;
        const banner = templateEid
            ? this.$utils.assets.getTemplateRegAssetSrc('banner', templateEid)
            : this.$utils.assets.getBrandingAssetSrc('banner', brand, this.config.event_details);
        return {
            banner,
            enabled: get(this.config, 'theme.has_banner_asset', false)
        }
    }
}
</script>
