export function registerConfig(Vue) {
    Vue.config.performance = window.APP.env !== 'production';
    Vue.config.productionTip = window.APP.env === 'production';

    // Set up global errors handler.
    Vue.config.errorHandler = function(error, vm) {
        vm.$root.errors = vm.$root.errors || [];
        vm.$root.errors.push({ message: error.message });
        console.error(error);
    };
}
