// Utils
import { isDate, isEmpty, isString } from 'lodash';
import { getBrowserDateFormat } from '@/libs/utils/time';

// Components
import DatePicker from 'vue2-datepicker';

export default {

    components: { DatePicker },

    inheritAttrs: false,

    props: {
        firstDayOfWeek: {
            type: Number,
            default: 1
        },

        format: {
            type: String,
            default: getBrowserDateFormat()
        },

        notAfter: {
            type: [ Date, String ],
            default: null
        },

        notBefore: {
            type: [ Date, String ],
            default: null
        },

        notAfterTime: {
            type: [ Date, String ],
            default: null
        },

        notBeforeTime: {
            type: [ Date, String ],
            default: null
        },

        value: {
            type: [ Date, Array, Number, String ],
            default: null
        },

        valueType: {
            type: String,
            default: 'format'
        }
    },

    computed: {
        defaultDate() {
            const now = new Date();
            if (this.notAfterEndOfDay && now > this.notAfterEndOfDay) {
                return this.notAfterEndOfDay;
            }

            if (this.notBeforeBeginOfDay && now < this.notBeforeBeginOfDay) {
                return this.notBeforeBeginOfDay;
            }
            return now;
        },

        /** @returns {any} */
        innerValue() {
            let value = this.value;

            if (this.valueType === 'X' && Number.isFinite(this.value)) {
                value = value.toString();
            }

            return value;
        },

        /**
         * @returns {Date|null}
         */
        notAfterDate() {
            let date = null;

            if (isDate(this.notAfter)) {
                date = this.notAfter;
            }

            if (!isEmpty(this.notAfter) && isString(this.notAfter)) {
                date = new Date(this.notAfter);
                date = new Date(date.toISOString().replace(/Z$/, '')); // Remove timezone
            }

            return date;
        },

        /**
         * @returns {Date|null}
         */
        notBeforeDate() {
            let date = null;

            if (isDate(this.notBefore)) {
                date = this.notBefore;
            }

            if (!isEmpty(this.notBefore) && isString(this.notBefore)) {
                date = new Date(this.notBefore);
                date = new Date(date.toISOString().replace(/Z$/, '')); // Remove timezone
            }

            return date;
        },

        /**
         * @returns {Date|null}
         */
        notAfterTimeObj() {
            if (!isString(this.notAfterTime) || isEmpty(this.notAfterTime)) {
                return null;
            }

            const today = new Date();
            const [ h, m ] = this.notAfterTime.split(':');
            today.setHours(h, m, 59, 999);

            return today;
        },

        /**
         * @returns {Date|null}
         */
        notBeforeTimeObj() {
            if (!isString(this.notBeforeTime) || isEmpty(this.notBeforeTime)) {
                return null;
            }

            const today = new Date();
            const [ h, m ] = this.notBeforeTime.split(':');
            today.setHours(h, m, 0, 0);

            return today;
        },

        /**
         * @returns {Date|null}
         */
        notAfterEndOfDay() {
            if (isDate(this.notAfterDate)) {
                const date = new Date(this.notAfterDate);
                date.setHours(23, 59, 59, 999);
                return date;
            }

            return null;
        },

        /**
         * @returns {Date|null}
         */
        notBeforeBeginOfDay() {
            if (isDate(this.notBeforeDate)) {
                const date = new Date(this.notBeforeDate);
                date.setHours(0, 0, 0, 0);
                return date;
            }

            return null;
        },

        /**
         * Pass-through listeners
         * @returns {object}
         */
        listeners() {
            // eslint-disable-next-line
            const { input, change, ...listeners } = this.$listeners;

            return listeners;
        },

        /**
         * Pass-through attributes
         * @returns {object}
         */
        attrs() {

            /* eslint-disable no-unused-vars */
            const {
                options,
                errors,
                label,
                name,
                hint,
                focused,
                notAfterTime,
                notBeforeTime,
                ...attrs
            } = Object.assign(this.$options.propsData, this.$attrs);
            /* eslint-enable no-unused-vars */

            return attrs;
        }
    },

    mounted() {
        if (this.options.times?.length && isEmpty(this.value)) {
            // This is a monkey patch to fix an issue in the
            // time picker plugin we use. The issue occurs
            // in scenarios where:
            //
            // a/ the time interval falls within the same hour
            //    (without adding the 'o clock time)
            // b/ the time format is in 12-hour format (AM/PM)
            //    and the range is exclusively in the PM period
            //
            // In these two scenarios, the time picker UI won't
            // be able to determine which cells to enable
            // resulting in every cell being disabled.
            //
            // To bypass this behavirour, we set an initial value
            // that matches with the lower bound of the time range.
            // This will enable the time picker to determine the
            // correct cells to enable.

            const min = this.options.times[0];
            const [ h, m ] = min.split(':');
            const date = new Date();
            date.setHours(h, m, 0, 0);

            const payload = this.$refs.picker?.formatDate(date) || date;
            this.input(payload);
        }
    },

    methods: {
        /**
         * Triggered when user interacts with input components.
         *
         * @param {Object} payload the selected payload
         */
        input(payload) {
            if (this.valueType === 'X' && typeof payload === 'string') {
                payload = Number.parseInt(payload);
            }

            this.$emit('input', payload);
        },

        /**
         * Checks if the given date is within
         * the before and after range
         *
         * @param {Date} date the date to check against the given range
         *
         * @returns {Boolean} whether the given date is disabled or not
         */
        disabledDate(date) {
            return this.isCellDisabled(date, this.notBeforeBeginOfDay, this.notAfterEndOfDay);
        },

        /**
         * Checks if the given time is within
         * the before and after range
         *
         * @param {Date} time the time to check against the given range
         *
         * @returns {Boolean} whether the given time is disabled or not
         */
        disabledTime(time) {
            const notBefore = this.notBeforeTimeObj;
            const notAfter = this.notAfterTimeObj;

            // Since we're deling with time only, we need to
            // set the date part of the given time object to
            // ensure that the comparison is accurate.
            if (isDate(notBefore)) {
                notBefore.setFullYear(time.getFullYear());
                notBefore.setMonth(time.getMonth());
                notBefore.setDate(time.getDate());
            }

            if (isDate(notAfter)) {
                notAfter.setFullYear(time.getFullYear());
                notAfter.setMonth(time.getMonth());
                notAfter.setDate(time.getDate());
            }

            return this.isCellDisabled(time, notBefore, notAfter);
        },

        /**
         * Determines if a date is disabled based on the provided constraints.
         *
         * @param {Date} date - The date to check.
         * @param {Date} notBefore - The minimum allowed date.
         * @param {Date} notAfter - The maximum allowed date.
         *
         * @returns {boolean} - `true` if the date is disabled, `false` otherwise.
         */
        isCellDisabled(date, notBefore, notAfter) {
            const { dateIsBefore, dateIsAfter } = this.$utils.time;

            let isBefore, isAfter = false;

            if (isDate(notBefore)) isBefore = dateIsBefore(date, notBefore);
            if (isDate(notAfter)) isAfter = dateIsAfter(date, notAfter);

            return isBefore || isAfter;
        }
    }
};
