<template lang="pug">
dropdown-button.calendar-button(
    v-if="enabled"
    :important="important"
    :button-classes="{ 'btn-icon': true, 'single': !$slots.default }"
)
    i.icon-calendar.ic-calendar
    slot(v-if="$slots.default")

    template(#menu="")
        ul
            li(v-if="iCalUrl"): a(:href="iCalUrl" download)
                i.icon-cal
                span {{ $t('registration.calendar.add_to_calendar') }}

            li(v-if="gCalUrl"): a(:href="gCalUrl" target="_blank")
                i.icon-gcal
                span {{ $t('registration.calendar.add_to_google') }}
</template>
<script>
import DropdownButton from '@/components/common/DropdownButton.vue';

export default {
    name: 'CalendarButton',

    components: {
        DropdownButton
    },

    props: {
        gCalUrl: {
            type: String,
            default: null
        },

        iCalUrl: {
            type: String,
            default: null
        },

        important: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        /** @returns {boolean} */
        enabled() {
            return Boolean(this.gCalUrl) || Boolean(this.iCalUrl);
        }
    }
};
</script>
