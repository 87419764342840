// Utils
import { get, uniq, isEmpty } from 'lodash';
import { decorateSessions } from '@/libs/utils/sessions';
import Session from '@/libs/utils/models/session';

/**
 * Class representing a Sessions Registration Orchestrator.
 *
 * This class handles the registration and management of sessions.
 */
export default class SessionsRegistrationOrchestrator {
    /**
     * Constructs a new SessionsRegistrationOrchestrator object.
     *
     * @param {import('@/types').Services} services - The services object.
     * @param {Object} config - The configuration object.
     * @param {string} locale - The locale string.
     */
    constructor(services, config, locale) {
        this.services = services;
        this.config = config;
        this.locale = locale;

        this.settings = this.config.settings;
        this.timezone = get(config, 'event_details.timezone');
        this.useEventTimezone = get(config, 'event_details.display_timezone') !== 'device';

        /** @type {Session[]} */
        this.sessions = [];

        this.initialized = false;

        /** @type {string[]} */
        this.originalSelection = get(this.config, 'user.registeredSessions', []).map(session => session.fp_ext_id);

        /** @type {string[]} */
        this.originalWaitlist = get(this.config, 'user.waitlist', []);

        /** @type {string[]} */
        this.selectedSessions = [];

        /** @type {string[]} */
        this.waitlist = [];

        this.restoreOriginalSelection();
    }

    /**
     * Retrieves all sessions from the form configuration.
     *
     * @param {object} forms - The forms object.
     * @param {boolean} [debug=false] - Indicates whether to enable debug mode.
     *
     * @returns {Session[]} - An array of sessions.
     *
     * @private
     */
    static getAllSessionsFromForms(forms, debug = false) {
        const sessions = [];

        for (const form of Object.values(forms)) {
            const sessionBlocks = form.fields.filter(field => field.type === 'sessions');
            const fieldSessions = sessionBlocks.map(field => field.sessions).flat();
            for (const data of fieldSessions) {
                sessions.push(new Session(data, debug));
            }
        }

        return sessions;
    }

    /**
     * Initializes the session registration orchestrator.
     *
     * @param {boolean} [debug=false] - Indicates whether to enable debug mode.
     */
    async init(debug = false) {
        if (this.initialized) {
            return;
        }

        this.initialized = true;
        this.collectAllSessionsFromForms(debug);

        for (const id of this.originalSelection) {
            const session = this.sessions.find(session => session.fp_ext_id === id);
            if (session) {
                session.markAsOriginallySelected();
            }
        }

        await this.refreshSessionsAvailability();
    }

    /**
     * Restores the original selection of sessions.
     *
     * @param {boolean} [refresh=false] - Indicates whether to refresh the selection.
     * @param {{session_registration_errors?: object, registered_sessions?: string[]}} [errorData={}] - The error data object.
     *
     * @returns {Array} - An array of selected session IDs.
     */
    restoreOriginalSelection(refresh = false, errorData = {}) {
        const erroredSessions = errorData.session_registration_errors || {};
        const remoteRegistered = errorData.registered_sessions || [];

        for (const sidWithError of Object.keys(erroredSessions)) {
            const session = this.sessions.find(session => session.fp_ext_id === sidWithError);
            if (session) {
                session.setErrors(erroredSessions[sidWithError]);
            }
        }

        // in case the user already registered before for a session
        // or in case the previous submission successfully registered to some
        if (this.originalSelection.length || remoteRegistered.length) {
            this.selectedSessions = [
                ...this.originalSelection,
                ...remoteRegistered
            ];
        } else if (!isEmpty(erroredSessions)) {
            // in case there are submission errors, we don't want the previous selection
            this.selectedSessions = [];
        }

        this.waitlist = [ ...this.originalWaitlist ];

        if (refresh) {
            this.refreshSelection();
        }

        return this.getAllSelectedIds();
    }

    /**
     * Collects all unique sessions from forms.
     *
     * @param {boolean} [debug=false] - Indicates whether to enable debug mode.
     *
     * @private
     */
    collectAllSessionsFromForms(debug) {
        const sessions = SessionsRegistrationOrchestrator.getAllSessionsFromForms(this.config.forms, debug);
        sessions.forEach(session => {
            if (!this.sessions.map(s => s.fp_ext_id).includes(session.fp_ext_id)) {
                this.sessions.push(session);
            }
        });

        decorateSessions(this.sessions, this.locale, this.timezone, this.useEventTimezone);
    }

    /**
     * Resets the selected sessions and clears the registration status for all sessions.
     */
    reset() {
        this.selectedSessions = [];
        this.sessions.forEach(session => {
            session.enable();
            session.setAvailability({});
        });
    }

    /**
     * Refreshes the selection of sessions.
     * Iterates through each session and checks if it is selectable.
     *
     * @private
     */
    refreshSelection() {
        this.sessions.forEach(this.isSessionSelectable.bind(this));
    }

    /**
     * Refreshes the availability of sessions by retrieving the sessions' availability from the server.
     * Updates the registration status of each session based on the retrieved availability.
     * Also checks if each session is selectable.
     *
     * @private
     */
    async refreshSessionsAvailability() {
        const fpExtIds = this.sessions.map(session => session.fp_ext_id);
        const availabilities = await this.services.sessionsReg.getSessionsAvailability(this.config, fpExtIds);
        for (const session of this.sessions) {
            for (const [ id, availability ] of Object.entries(availabilities)) {
                if (session.fp_ext_id !== id) {
                    continue;
                }

                session.enable();
                session.setAvailability(availability);

                const isSelected = this.isSessionSelected(session);
                const wasSelected = session.wasOriginallySelected();

                if (isSelected && !wasSelected) {
                    session.decreaseCapacity();
                } else if (!isSelected && wasSelected) {
                    session.increaseCapacity();
                }
            }
        }

        this.refreshSelection();
    }

    /**
     * Selects a session if it is selectable.
     *
     * @param {Session} session - The session to be selected.
     *
     * @returns {boolean} - Returns true if the session was successfully selected, false otherwise.
     */
    selectSession(session) {
        if (this.isSessionSelected(session) || (this.isSessionInUnsavedWaitlist(session) && !this.hasSessionNewAvailableSlots(session))) {
            if (this.isSessionInUnsavedWaitlist(session)) {
                console.debug('[SRO] Session in unsaved waitlist, giving up selection', session.title);
            }
            return true;
        }

        if (!this.isSessionSelectable(session)) {
            console.debug('[SRO] Session can\'t be selected:', session.title, session.disabled_reason);
            return false;
        }

        session.decreaseCapacity();
        this.selectedSessions.push(session.fp_ext_id);

        for (const include of session.getInclusiveCompanions()) {
            const includeSession = this.sessions.find(s => s.fp_ext_id === include);
            if (includeSession && !this.isSessionSelected(includeSession)) {
                console.debug('[SRO] Selecting companion session', includeSession.title);
                this.selectSession(includeSession);
            }
        }

        this.refreshSelection();

        console.debug('[SRO] Selected session', session.title, this.isSessionSelected(session));

        return true;
    }

    /**
     * Removes a session from the selectedSessions array.
     *
     * @param {Session} session - The session object to be removed.
     *
     * @returns {boolean} - Returns true if the session was successfully unselected, false otherwise.
     */
    unselectSession(session) {
        if (!this.isSessionSelected(session)) {
            return true;
        }

        if (!session.isUnselectable()) {
            return false;
        }

        session.increaseCapacity();
        this.selectedSessions = this.selectedSessions.filter(fpExtId => fpExtId !== session.fp_ext_id);

        for (const include of session.getInclusiveCompanions()) {
            const includeSession = this.sessions.find(s => s.fp_ext_id === include);
            if (includeSession && this.isSessionSelected(includeSession)) {
                console.debug('[SRO] Unselecting companion session', includeSession.title);
                this.unselectSession(includeSession);
            }
        }

        for (const exclude of session.getExclusiveCompanions()) {
            const excludeSession = this.sessions.find(s => s.fp_ext_id === exclude);
            if (excludeSession?.enabled === false && excludeSession?.disabled_reason === 'in_exclusion_group') {
                excludeSession.enable();
            }
        }

        this.refreshSessionsAvailability();

        console.debug('[SRO] Unselected session', session.title);

        return true;
    }

    /**
     * Determines if a session is selectable based on certain conditions.
     *
     * @param {Session} session - The session object to check.
     *
     * @returns {boolean} - True if the session is selectable, false otherwise.
     *
     * @private
     */
    isSessionSelectable(session) {
        let selectable = true;

        if (this.isSessionSelected(session)) {
            // Short-circuit if the session is already selected
            session.enable();
            return true;
        }

        if (selectable) {
            selectable = this.checkMaxRegistrations(session);
        }

        if (selectable) {
            selectable = session.checkExlusivity(this.getAllSelected(), this.sessions, this.settings);
        }

        if (selectable) {
            selectable = session.isSelectable();
        }

        this.toggleEnable(session, selectable);

        return selectable;
    }

    /**
     * Checks if the maximum number of registrations has been reached for a session.
     * If the maximum number of registrations has been reached, the session's `disabled_reason` property is set to 'max_registrations_total'.
     *
     * @param {Session} session - The session object to check.
     *
     * @returns {boolean} - Returns `true` if the maximum number of registrations has not been reached, otherwise `false`.
     *
     * @private
     */
    checkMaxRegistrations(session) {
        if (this.maxSelectionReached()) {
            session.disable('max_registrations_total');
            return false;
        }

        return true;
    }

    /**
     * Checks if a session is selected.
     *
     * @param {Session} session - The session object to check.
     *
     * @returns {boolean} - Returns true if the session is selected, otherwise false.
     *
     * @private
     */
    isSessionSelected(session) {
        return this.selectedSessions.includes(session.fp_ext_id);
    }

    /**
     * Checks if the number of selected session
     * has reached the maximum registrations allowed.
     *
     * @returns {boolean} - Returns true if the number of selected session has reached the
     * maximum registrations, otherwise returns false.
     *
     * @private
     */
    maxSelectionReached() {
        if (!Number.isFinite(this.settings.max_registrations_total)) {
            return false;
        }

        return this.selectedSessions.length >= this.settings.max_registrations_total;
    }

    /**
     * Returns an array of unique selected session fp ext IDs.
     *
     * @returns {string[]} An array of unique session fp ext IDs.
     *
     */
    getAllSelectedIds() {
        return uniq([ ...this.selectedSessions, ...this.waitlist ]);
    }

    /**
     * Retrieves an array of all unregister IDs.
     *
     * @returns {string[]} An array of unregister IDs.
     */
    getAllUnregisterIds() {
        const selected = this.getAllSelectedIds();
        const unregister = this.sessions.filter(session => session.wasOriginallySelected() && !selected.includes(session.fp_ext_id));
        const waitlistLeave = this.sessions.filter(session => this.originalWaitlist.includes(session.fp_ext_id) && !selected.includes(session.fp_ext_id));
        unregister.push(...waitlistLeave);
        return unregister.map(session => session.fp_ext_id);
    }

    /**
     * Retrieves all selected sessions.
     *
     * @returns {Session[]} An array of selected sessions.
     *
     */
    getAllSelected() {
        return this.sessions.filter(session => this.selectedSessions.includes(session.fp_ext_id));
    }

    /**
     * Toggles the enabled state of a session.
     *
     * @param {Session} session - The session object to toggle.
     * @param {boolean} value - The new enabled state for the session.
     *
     * @private
     */
    toggleEnable(session, value) {
        const enabled = session.toggleEnable(value);
        if (enabled) {
            this.enableCompanions(session);
        }
    }

    /**
     * Enables companions for a given session.
     *
     * @param {Session} session - The session object.
     */
    enableCompanions(session) {
        for (const include of session.getInclusiveCompanions()) {
            const includeSession = this.sessions.find(s => s.fp_ext_id === include);
            if (includeSession && includeSession.disabled_reason === 'blocked_by_excluded_companion') {
                includeSession.enable();
            }
        }
    }

    /**
     * Retrieves sessions that match the given control.
     *
     * @param {object} control - The control object.
     *
     * @returns {Session[]} - An array of sessions that match the control.
     */
    getMatchingSessions(control) {
        const ids = control.sessions.map(session => session.fp_ext_id);
        return this.sessions.filter(session => ids.includes(session.fp_ext_id));
    }

    /**
     * Checks if the selection of sessions is valid.
     *
     * @returns {Promise<boolean>} A promise that resolves to true if the selection is valid, otherwise false.
     */
    async isSelectionValid() {
        await this.refreshSessionsAvailability();
        const allSelected = this.getAllSelected();
        for (const session of allSelected) {
            if (!this.isSessionSelectable(session)) {
                session.setErrors('outdated_selection');
                return false;
            }
        }
        return this.getAllSelected().every(session => this.isSessionSelectable(session));
    }

    /**
     * Checks if a form has multiple session registration blocks.
     *
     * @param {string} formName - The name of the form.
     *
     * @returns {boolean} - True if the form has multiple blocks, false otherwise.
     */
    hasFormMultipleBlocks(formName) {
        const blocks = {};
        for (const [ formName, form ] of Object.entries(this.config.forms)) {
            const sessionBlocks = form.fields.filter(field => field.type === 'sessions');
            blocks[formName] = { multiple: sessionBlocks.length > 1 };
        }

        return blocks[formName]?.multiple;
    }

    /**
     * Checks if user subscribed to session's waitlist.
     *
     * @param {Object} session - The session object to check.
     *
     * @returns {boolean} - Returns true if the user is subscribed to the waitlist, otherwise false.
     */
    isSessionInSavedWaitlist(session) {
        return this.originalWaitlist.includes(session.fp_ext_id);
    }

    /**
     * Checks if a session is in the waitlist.
     *
     * @param {Session} session - The session object to check.
     *
     * @returns {boolean} - Returns true if the session is in the waitlist, false otherwise.
     */
    isSessionInUnsavedWaitlist(session) {
        return this.waitlist.includes(session.fp_ext_id);
    }

    /**
     * Checks if a session has new available slots.
     *
     * @param {Object} session - The session object to check.
     *
     * @returns {boolean} - Returns true if the session has new available slots, false otherwise.
     */
    hasSessionNewAvailableSlots(session) {
        const isInWaitlist = this.isSessionInSavedWaitlist(session);
        return session.can_register && session.hasAvailableSeats() && isInWaitlist;
    }

    /**
     * Adds a session to the waitlist.
     *
     * @param {Session} session - The session object to be added to the waitlist.
     */
    addToWaitlist(session) {
        const ids = [ session.fp_ext_id, ...session.mutually_inclusive ];
        this.waitlist.push(...ids);
        session.setErrors([]);
    }

    /**
     * Removes a session from the waitlist.
     *
     * @param {Session} session - The session object to be removed.
     */
    removeFromWaitlist(session) {
        const ids = [ session.fp_ext_id, ...session.mutually_inclusive ];
        this.waitlist = this.waitlist.filter(id => !ids.includes(id));
        session.setErrors([]);
    }
}
