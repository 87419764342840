<template lang="pug">
.session-details(:class="{ master: hasTracks, 'has-action': hasTracks, condensed, 'has-description': hasDescription }" @click.stop="onCardClick")
    header
        slot(v-if="!hasTracks" name="heading" :item="session")

        template(v-if="hasTracks")
            h4: a.has-action.toggler(v-if="hasTracks" :class="{ open: expandTracks }")
                i.icon-chevron-down
                | {{ session.title }}

        template(v-else)
            h4 {{ session.title }}

        .time(v-if="formattedDate") {{ formattedDate }}

    expandable-list(
        v-if="speakers.length"
        item-key="speaker"
        list-class="speakers"
        :items="speakers"
        :max-items="maxItems"
        :component="component"
        :show-more-action="!disabled"
        :params="{ config, showSubtitle, showBottom }")

        template(#more="{ showMore, toggle }")
            a.has-action(@click.stop="toggle" :class="{ open: showMore }")
                | {{ $t(`registration.actions.show_${showMore ? 'less' : 'more'}_speakers`) }}
                i.icon-chevron-down

    expandable-list.sessions(
        v-if="hasTracks"
        item-key="session"
        component="session-details"
        :items="session.track_sessions"
        :max-items="expandTracks ? Infinity : 0"
        :show-more-action="false"
        :params="{ config, condensed }")

        template(#heading="{ item }")
            slot(name="heading" :item="item")
</template>
<script>
// Utils
import { get } from 'lodash';

// Mixins
import maxItems from './max-items.mixin';

// Components
import ExpandableList from '@/components/common/ExpandableList.vue';
import SessionDetailsModal from '@/components/registration/sections/SessionDetailsModal.vue';
import SpeakerCard from '@/components/registration/sections/SpeakerCard.vue';

export default {
    name: 'SessionDetails',

    components: { ExpandableList, SessionDetailsModal, SpeakerCard },

    mixins: [maxItems],

    props: {
        session: {
            type: Object,
            required: true
        },

        config: {
            type: Object,
            required: true
        },

        stopPropagation: {
            type: Boolean,
            default: true
        },

        openDetailsOnClick: {
            type: Boolean,
            default: true
        },

        condensed: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            component: SpeakerCard,
            speakers: get(this, 'session.speakers', []),
            hasTracks: Boolean(get(this, 'session.track_sessions', []).length),
            hasDescription: Boolean(this.session?.description?.length),
            expandTracks: false,
            lowResItems: 2,
            highResItems: 3,
            showSubtitle: !this.condensed,
            showBottom: !this.condensed
        }
    },

    computed: {
        formattedDate() {
            const dates = this.session.dates;
            if (typeof dates !== 'object') {
                return;
            }

            const spansMultipleDays = dates.endDay && dates.endDay !== dates.day;
            const prefixWithDay = this.condensed || spansMultipleDays;
            let time = `${dates.startTime} - ${dates.endTime}`;

            if (spansMultipleDays) {
                time = `${dates.startTime} - ${dates.endDay}, ${dates.endTime}`;
            }

            if (prefixWithDay) {
                time = `${dates.day}, ${time}`;
            }

            return time;
        }
    },

    methods: {
        toggleTracks() {
            if (!this.hasTracks) return;
            this.expandTracks = !this.expandTracks;
        },

        openSessionDetailsModal() {
            if (!this.hasDescription || this.hasTracks) {
                return;
            }

            this.$modal.show(
                SessionDetailsModal,
                {
                    session: this.session,
                    config: this.config
                },
                {
                    adaptive: true,
                    height: 'auto',
                    maxWidth: 584,
                    classes: 'medium'
                }
            );
        },

        onCardClick() {
            if (this.disabled) return;

            this.toggleTracks();

            if (this.openDetailsOnClick) {
                this.openSessionDetailsModal();
            }

            if (!this.stopPropagation) {
                this.$emit('click', this.session);
            }
        }
    }
};
</script>
