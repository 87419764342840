<template lang="pug">
.end: main: .container
    .funnel-end
        h3
            span {{ $t(`registration.form.submissions.${type}.title`) }}
            span(v-if="emailMessage") {{ $t('registration.form.submissions.check_inbox') }}
        p.message(v-if="emailMessage") {{ $t(`registration.form.submissions.email_message`) }}

        event-card(:config="config" :show-calendar-button="false" :main-title="false")

        .actions
            a.btn.btn-medium.btn-primary(v-if="showBackButton" @click="goToReg") {{ $t('registration.actions.back_to_reg') }}
            event-calendar-button(
                v-if= "showCalendar"
                :event-details="config.event_details"
                :calendar="config.calendar"
                :important="true")
                | {{ $t('registration.actions.add_to_cal') }}
            a.btn.btn-medium.btn-primary.continue-to-branding(:href="brandingLink" v-if="brandingLink") {{ $t('registration.form.submissions.continue_to_branding') }}
</template>
<script>
// Components
import EventCalendarButton from '@/components/EventCalendarButton.vue';
import EventCard from '@/components/registration/EventCard.vue';

const END_TYPES = ['accepted', 'declined', 'registered', 'requested', 'updated', 'waiting']

export default {
    name: 'End',

    components: {
        EventCard,
        EventCalendarButton,
    },

    props: {
        config: {
            type: Object,
            required: true
        },

        session: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            type: undefined,
            emailMessage: false,
            showCalendar: false,
            showBackButton: false,
        }
    },

    created() {
        const type = this.$route.params.end;
        if (!END_TYPES.includes(type)) {
            // on unknown end go back to reg
            this.goToReg();
        }

        this.type = type;

        if (this.type === 'requested' && this.$services.publicLogin.displayWaitingList(this.config)) {
            this.type = 'waiting';
        }

        this.showCalendar = ['accepted', 'registered'].includes(this.type);
        if (this.config.theme?.hide_calendar) {
            this.showCalendar = false;
        }

        this.showBackButton = ['declined', 'updated'].includes(this.type);

        const continue_to_branding = !this.showBackButton && this.config.settings?.continue_to_branding;

        this.emailMessage = this.$route.params.emailSent === '1' && !continue_to_branding;
        this.brandingLink = continue_to_branding ? `/login/${this.config.event_details.branded_app}`: null;
    },

    methods: {
        goToReg() {
            this.$emit('updated');
            this.$router.push({ name: 'registration' });
        }
    },

    metaInfo: {
        bodyAttrs: {
            class: ['alt']
        }
    }
};
</script>
