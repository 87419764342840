<template lang="pug">
.base-control
    input(@input="input" ref="input")
    select(@change="change")
        option(value="value" selected)
</template>
<script>
// Components
import ControlDecorator from './ControlDecorator.vue';

/**
 * Base control component interface: inputs, textarea, and select common interface.
 *
 * @vue-prop {String} name the vue component to use for the step.
 * @vue-prop {String[]} errors the field errors array
 * @vue-prop {String} [label] the label for this field.
 * @vue-prop {String} [value] the field initial value
 * @vue-prop {String} [placeholder] a placeholder text for this field
 * @vue-prop {String} [tip] tips of the control (tooltip on the label)
 * @vue-prop {String} [hint] an optional explaination paragraph
 * @vue-prop {Boolean} [focused] an optional flag to set focus on the input
 * @vue-prop {Boolean} [disabled] an optional flag to disable the input
 * @vue-prop {String} [autocomplete] an optional autocomplete flag for the input
 *
 * @category ui.components.form-elements
 * @exports BaseControl
 * @component
 */
export default {
    name: 'BaseControl',

    components: { ControlDecorator },

    props: {
        name: {
            type: String,
            required: true
        },

        errors: {
            type: Array,
            default: () => []
        },

        label: {
            type: String,
            default: null
        },

        value: {
            type: String,
            default: null
        },

        placeholder: {
            type: String,
            default: null
        },

        tip: {
            type: String,
            default: null
        },

        hint: {
            type: String,
            default: null
        },

        focused: {
            type: Boolean,
            default: null
        },

        disabled: {
            type: Boolean,
            default: null
        },

        autocomplete: {
            type: String,
            default: null
        },

        options: {
            type: [Object, Array],
            default: () => {}
        },

        hasDefault: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        /** @returns {string} */
        uid() {
            return `${this.name}-${this._uid}`;
        },

        /**
         * Pass-through listeners
         * @returns {object}
         */
        listeners() {
            // Here we skip the `input` and `change` listeners, since we provide our own
            // and we don't want it to be overwritten.

            // eslint-disable-next-line
            const { input, change, ...listeners } = this.$listeners;

            return listeners;
        },
    },

    watch: {
        focused() {
            this.attemptFocus();
        }
    },

    created() {
        if (this.hasDefault) {
            // IF the control has a default value, emit the first event
            // to populate the form with that value.
            this.input();
        }
    },

    methods: {
        /**
         * Tries to grab the focus on the input element
         */
        attemptFocus() {
            if (this.focused && this.$refs.input) {
                this.focus();
            }
        },

        /**
         * Grabs the focus on the input element
         */
        focus() {
            this.$refs.input.focus();
        },

        /**
         * Blurs on the input element
         */
        blur() {
            this.$refs.input.blur();
        },

        /**
         * Triggered when user interacts with input components.
         *
         * @param {Object} [$event] the event that triggered the change
         */
        input($event) {
            let value = this.value;
            if ($event && $event.target) {
                value = $event.target.value;
            }

            this.$emit('input', value);
        },

        /**
         * Triggered when user interacts with selects component.
         *
         * @param {Object} $event the event that triggered the change
         */
        change($event) {
            this.input($event);
        }
    }
};
</script>
