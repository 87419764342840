export class LoginException {
    constructor(message, code, type) {
        this.message = message;
        this.code = code;
        this.name = 'LoginException';
        console.warn(this);

        if (type && window.app.$track) {
            window.app.$track.endTrackLogin(false);
        }
    }
}
