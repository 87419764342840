import { formatSessionTimes } from '@/libs/utils/time';

/**
 * Decorates the given sessions with formatted dates and times
 *
 * @param {object[]} sessions
 * @param {string} locale
 * @param {string} tz
 * @param {boolean} [useEventTimezone]
 */
export function decorateSessions(sessions, locale, tz, useEventTimezone) {
    sessions.sort((a, b) => {
        const starts = a.starts_at - b.starts_at;
        const ends = a.ends_at - b.ends_at;

        if (starts === 0 && ends === 0) {
            if (a.title < b.title) {
                return -1;
            }
            if (a.title > b.title) {
                return 1;
            }
            return 0;
        }

        return starts || ends;
    });

    for (const session of sessions) {
        decorateSession(session, locale, tz, useEventTimezone);
    }
}

/**
 * Decorates the given session with formatted dates and times
 *
 * @param {object} session
 * @param {string} locale
 * @param {string} tz
 * @param {boolean} [useEventTimezone]
 */
export function decorateSession(session, locale, tz, useEventTimezone) {
    const { starts_at, ends_at } = session;
    session.dates = formatSessionTimes(locale, starts_at, ends_at, tz, useEventTimezone);

    if (session.track_sessions) {
        decorateSessions(session.track_sessions, locale, tz, useEventTimezone);
    }
}
