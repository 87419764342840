<template lang="pug">
.session-control.form-group(@click="setSelected()" :class="{ selected, 'has-error': hasError, disabled: isSelectionDisabled }")
    .inner-content
        checkbox(
            :name="name"
            v-model="selected"
            :disabled="isSelectionDisabled"
        )

        section.session
            session-details(
                :config="config"
                :session="session"
                :stop-propagation="false"
                :open-details-on-click="false"
                :condensed="true"
                :disabled="isSelectionDisabled"
                :key="`${session._id}-session_details`"
                @click="setSelected()"
            )
                template(#heading="{ item }")
                    session-control-availability(:session="item" :orchestrator="orchestrator")

            .actions-wrapper(v-if="hasDetails || showWaitlistActions")
                .separator
                .actions
                    a.has-action(v-if="hasDetails" @click.prevent.stop="openSessionDetailsModal" href="#")
                        span {{ $t('registration.form.labels.view_details') }}

                    template(v-if="showWaitlistActions")
                        template(v-if="!isInSavedWaitlist")
                            a.has-action(@click.prevent.stop="toggleWaitlistSubscription" href="#" :class="{ success: isInUnsavedWaitlist }"): span
                                i.icon-check(v-if="isInUnsavedWaitlist")
                                | {{ $t('registration.form.labels.subscribe_to_waitlist') }}

                        template(v-else)
                            a.has-action(@click.prevent.stop="toggleWaitlistSubscription" href="#" :class="{ success: !isInUnsavedWaitlist }"): span
                                i.icon-check(v-if="!isInUnsavedWaitlist")
                                | {{ $t('registration.form.labels.unsubscribe_from_waitlist') }}

            session-debug(v-if="debug" :session="session")

    p.help.error(v-if="errors.length")
        i.icon-warning-fill
        | {{ $te(errors[errors.length - 1]) ? $t(errors[errors.length - 1]) : errors[errors.length - 1] }}

</template>
<script>
// Utils
import { isEmpty } from 'lodash';

// Components
import BaseControl from './BaseControl.vue';
import Checkbox from '@/components/form-elements/Checkbox.vue';
import SessionControlAvailability from '@/components/form-elements/SessionControlAvailability.vue';
import SessionDetails from '@/components/registration/sections/SessionDetails.vue';
import SessionDetailsModal from '@/components/registration/sections/SessionDetailsModal.vue';
import SessionDebug from '@/components/common/SessionDebug.vue';

export default {
    name: 'SessionControl',

    components: { Checkbox, SessionControlAvailability, SessionDetails, SessionDebug },

    extends: BaseControl,

    props: {
        config: {
            type: Object,
            required: true
        },

        orchestrator: {
            type: Object,
            required: true
        },

        session: {
            type: Object,
            required: true
        },

        value: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            debug: this.$route.query.debug,
            selected: false,
            hasDetails: this.session.description?.length
        };
    },

    computed: {
        allErrors() {
            return [...this.errors, ...(this.session._errors || [])];
        },

        hasError() {
            return !isEmpty(this.allErrors);
        },

        hasNewSlotAvailable() {
            return this.orchestrator.hasSessionNewAvailableSlots(this.session);
        },

        isSelectionDisabled() {
            const originallySelected = this.session.wasOriginallySelected();
            const disabled = this.disabled || !this.session.enabled;
            const canNotUnregister = originallySelected && !this.session.can_unregister;
            return disabled || canNotUnregister;
        },

        isInSavedWaitlist() {
            return this.orchestrator.isSessionInSavedWaitlist(this.session);
        },

        isInUnsavedWaitlist() {
            return this.orchestrator.isSessionInUnsavedWaitlist(this.session);
        },

        showWaitlistActions() {
            // A session can't be subscribed to the waitlist if:
            // - it has available seats
            // - the user is already subscribed to the waitlist
            // - the waitlist is disabled
            // - the waitlist capacity is 0
            // - the user is already subscribed to the session

            const canRegister = this.session.can_register;
            const hasAvailableSeats = this.session.hasAvailableSeats();
            const originallySelected = this.session.wasOriginallySelected();

            return canRegister && !this.value && !originallySelected && !hasAvailableSeats && this.session.waitlist_enabled || this.isInUnsavedWaitlist;
        }
    },

    watch: {
        value: {
            immediate: true,
            handler(value) {
                this.selected = value;
            }
        },

        selected() {
            this.$emit('input', this.selected);
        }
    },

    mounted() {
        if (this.hasError) {
            const errors = [...this.errors, ...(this.session._errors || [])];
            this.$emit('error', errors, this.session);
            this.selected = false;
        }

        if (this.hasNewSlotAvailable) {
            this.selected = false;

            this.orchestrator.removeFromWaitlist(this.session);

            // Force a check in this scenario to see if the session is selectable
            // because of the waitlist.
            this.orchestrator.isSessionSelectable(this.session);

            this.$emit('input', false);
        }
    },

    methods: {
        /**
         * Sets the selected value.
         */
        setSelected() {
            if (!this.isSelectionDisabled) {
                this.selected = !this.selected;

                if (this.selected) {
                    this.clearErrors();
                }
            }
        },

        /**
         * Opens the session details modal.
         */
        openSessionDetailsModal() {
            this.$modal.show(
                SessionDetailsModal,
                {
                    session: this.session,
                    config: this.config
                },
                {
                    adaptive: true,
                    height: 'auto',
                    maxWidth: 584,
                    classes: 'medium'
                }
            );
        },

        /**
         * Subscribes or unsubscribes to/from the waitlist.
         *
         * This method is responsible for subscribing and unsubscribe the user to/from the waitlist.
         */
        toggleWaitlistSubscription() {
            if (this.isInUnsavedWaitlist) {
                this.orchestrator.removeFromWaitlist(this.session);
                this.selected = false;
            } else {
                this.orchestrator.addToWaitlist(this.session);
                this.selected = true;
            }
        },

        /**
         * Clears any error messages displayed in the session control component.
         */
        clearErrors() {
            this.$emit('error', null, this.session);
        }
    }
}
</script>
