// Classes
import BaseService from './base-service';

// Constants
import { API_BASE_PATH, APP_ID } from '@/libs/utils/constants';

const PASSWORD_STRENGTH_API_ENDPOINT = `${API_BASE_PATH}/account/password/check`;

const CHANGE_PASSWORD_API_ENDPOINT = `${API_BASE_PATH}/account/password/change`;

const ACCOUNT_EXISTS_API_ENDPOINT = `${API_BASE_PATH}/account/exists`;

const REQUEST_INVITATIONS_TOKEN_API_ENDPOINT = `${API_BASE_PATH}/request-invitations`;

const SIGN_UP_API_ENDPOINT = `${API_BASE_PATH}/account/signup`;

const SIGN_IN_API_ENDPOINT = `${API_BASE_PATH}/account/signin`;

const PASSWORD_RESET_API_ENDPOINT = `${API_BASE_PATH}/account/password/reset`;

/**
 * @typedef {Object} AccountData
 *
 * @property {string} app_id - The app id.
 * @property {string} branding - The branding of the app.
 * @property {string} email - The user's email.
 * @property {string} fname - The user's first name.
 * @property {string} lname - The user's last name.
 * @property {string} password - The user's password.
 * @property {string} [redirect] - The redirect URL or path.
 */

/**
 * @typedef {Object} ResetData
 *
 * @property {string} app_id - The app id.
 * @property {string} branding - The branding of the app.
 * @property {string} newPassword - The new password.
 * @property {string} [email] - The user's email.
 * @property {string} [password] - The user's password.
 * @property {string} [token] - The reset token.
 */
export default class AccountService extends BaseService {

    /**
     * Checks the strength of a password.
     *
     * @param {string} password - The password to check.
     *
     * @returns {Promise<{score: number}>} - A promise that resolves with the password strength data.
     */
    async checkPasswordStrength(password) {
        const { data } = await this.post(PASSWORD_STRENGTH_API_ENDPOINT, { password });
        return data;
    }

    /**
     * Changes the password for the current user.
     *
     * If email is provided, password is required.
     * If token is provided, email and password are not required.
     *
     * @param {ResetData} resetData - Data needed to reset account password.
     *
     * @returns {Promise<{ok: boolean}>} - A promise that resolves with the password change data.
     */
    async changePassword(resetData) {
        Object.assign(resetData, { app_id: APP_ID });
        const { data } = await this.post(CHANGE_PASSWORD_API_ENDPOINT, resetData);
        return data;
    }

    /**
     * Checks if an account exists for the given email and branding.
     *
     * @param {string} email - The email to check.
     * @param {string} branding - The branding of the app.
     *
     * @returns {Promise<{exists: boolean}>} - A promise that resolves with the account existence data.
     */
    async accountExists(email, branding) {
        const { data } = await this.get(ACCOUNT_EXISTS_API_ENDPOINT, { params: { email, branding } });
        return data;
    }

    /**
     * Requests invitations for the given email and branding.
     * An email will be sent to the user with a link to the invitations page.
     *
     * @param {AccountData} userData - The user's data
     * @param {boolean} [loadTestEvents=true] - Whether to load test events.
     *
     * @returns {Promise<{token: string}>} - A promise that resolves with the invitations token.
     */
    async requestInvitationsLink(userData, loadTestEvents = true) {
        Object.assign(userData, { app_id: APP_ID });
        const { data } = await this.post(REQUEST_INVITATIONS_TOKEN_API_ENDPOINT, userData, { params: { with_testing: loadTestEvents } });
        return data;
    }

    /**
     * Signs up a user.
     *
     * @param {AccountData} userData - The user data.
     *
     * @returns {Promise<{ok: boolean}>} - A promise that resolves with the signup data.
     */
    async signUp(userData) {
        Object.assign(userData, { app_id: APP_ID });
        const { data } = await this.post(SIGN_UP_API_ENDPOINT, userData);
        return data;
    }

    /**
     * Signs in a user.
     *
     * @param {AccountData} userData - The user data.
     *
     * @returns {Promise<{token: string}>} - A promise that resolves with the signin data.
     */
    async signIn(userData) {
        Object.assign(userData, { app_id: APP_ID });
        const { data } = await this.post(SIGN_IN_API_ENDPOINT, userData);
        return data;
    }

    /**
     * Resets the password for the given email and branding.
     * An email will be sent to the user with a link to reset the password.
     *
     * @param {AccountData} userData - The user data.
     *
     * @returns {Promise<{ok: boolean}>} - A promise that resolves with the password reset data.
     */
    async requestPasswordReset(userData) {
        Object.assign(userData, { app_id: APP_ID });
        const { data } = await this.post(PASSWORD_RESET_API_ENDPOINT, userData);
        return data;
    }
}
