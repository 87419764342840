<template lang="pug">
.event.invitation-card(@click="$event => $emit('click', $event)")
    .inner
        event-logo(:src="invite.iconUrl" :alt="invite.titleLabel")
        .details
            .name
                span.title {{invite.titleLabel || invite.name }}
            span.dates(v-if="invite.dates")
                i.ic-calendar
                span.text
                    | {{ invite.dates.startDate }}
                    |  -
                    |  {{ invite.dates.endDate }}
                    |  {{ invite.dates.timezone }}

            span.venue(v-if="invite.venue")
                i.ic-pin
                | {{invite.venue}}
</template>
<script>
// Components
import EventLogo from '@/components/EventLogo.vue';

export default {
    name: 'InvitationCard',

    components: { EventLogo },

    props: {
        invite: {
            type: Object,
            required: true
        }
    }
}
</script>
