<template lang="pug">
component(:is="inputComponent"
    ref="inputComponent"
    v-bind="attrs"
    v-model="innerValue"
    :errors="errors"
    :focused="focused"
    @input="input"
    @change="input")
</template>
<script>
// Utils
import { get, isEmpty } from 'lodash';

// Components
import BaseInput from '@/components/form-elements/BaseInput.vue';
import BaseSelect from '@/components/form-elements/BaseSelect.vue';
import BaseTextarea from '@/components/form-elements/BaseTextarea.vue';
import Checkbox from '@/components/form-elements/Checkbox.vue';
import DatetimePicker from '@/components/form-elements/DatetimePicker.vue';
import HiddenField from '@/components/form-elements/HiddenField.vue';
import LegalDoc from '@/components/form-elements/LegalDoc.vue';
import RadioButtons from '@/components/form-elements/RadioButtons.vue';
import ButtonsChoiceControl from '@/components/form-elements/ButtonsChoiceControl.vue';

// Constants
const KIND_TO_COMPONENT = {
    boolean: Checkbox,
    choice: RadioButtons,
    'choice-list': BaseSelect,
    'formatted-datetime': DatetimePicker,
    hidden: HiddenField,
    'text-multiline': BaseTextarea
};

export default {
    name: 'MetaControl',

    props: {
        control: {
            type: Object,
            required: true
        },

        errors: {
            type: Array,
            default: () => []
        },

        value: {
            default: null
        },

        disabled: {
            type: Boolean,
            default: false
        },

        focused: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            innerValue: this.value ?? this.control.default
        };
    },

    computed: {
        /** @returns {object} */
        attrs() {
            return this.getComponentAttributes();
        },

        /** @returns {import('vue').Component} */
        inputComponent() {
            return this.getComponentType();
        },
    },

    methods: {
        /**
         * Returns the component type.
         *
         * @returns {import('vue').Component} The component type.
         */
        getComponentType() {
            let inputComponent = KIND_TO_COMPONENT[this.control.kind] || BaseInput;

            if (this.control.type === 'legal-document') {
                inputComponent = LegalDoc;
            }

            const options = get(this, 'control.kind_options');

            if (options?.list_style === 'select') {
                inputComponent = BaseSelect;
            }

            if (options?.values && options?.appearance === 'buttons') {
                inputComponent = ButtonsChoiceControl;
            }

            return inputComponent;
        },

        /**
         * Retrieves the component attributes based on the control type.
         *
         * @returns {Object} The component attributes.
         */
        getComponentAttributes() {
            if (this.control.type === 'legal-document') {
                return this.getLegalDocumentAttributes();
            }

            const attrs = {
                label: this.$utils.controls.getLabel(this.control, this.$i18n),
                name: this.$utils.controls.getControlName(this.control),
                placeholder: this.$utils.controls.getPlaceholder(this.control, this.$i18n),
                disabled: this.control.disabled || this.disabled
            };

            this.updateOptions(attrs);
            this.updateType(attrs);
            this.updateForInputComponent(attrs);
            this.updateDefault(attrs);

            return attrs;
        },

        /**
         * Retrieves the component attributes for a legal document control type.
         *
         * @returns {Object} The component attributes for a legal document control type.
         */
        getLegalDocumentAttributes() {
            return {
                requirements: this.control.requirements,
                name: this.$utils.controls.getControlName(this.control),
                required: this.control.required,
                disabled: this.control.disabled || this.disabled
            };
        },

        /**
         * Updates the options attribute in the component attributes.
         *
         * @param {Object} attrs - The component attributes.
         */
        updateOptions(attrs) {
            const options = get(this, 'control.kind_options');

            if (!options) {
                return;
            }

            const { values, values_order, appearance } = options;

            if (values) {
                if (appearance === 'buttons') {
                    this.filterNonWritableValues(values);
                }
                attrs.options = Object.keys(values).map(value => {
                    const label = this.$utils.controls.getOptionLabel(this.control, values[value], value, this.$i18n);
                    return { label, value };
                });

                if (values_order) {
                    attrs.options.sort((a, b) => values_order[a.value] - values_order[b.value]);
                }
            } else {
                attrs.options = options;
            }
        },

        /**
         * Updates the type attribute in the component attributes.
         *
         * @param {Object} attrs - The component attributes.
         */
        updateType(attrs) {
            if (this.control.kind === 'number') {
                attrs.type = 'number';
            }

            if (this.control.kind === 'email') {
                attrs.type = 'email';
            }
        },

        /**
         * Updates the inputComponent attribute in the component attributes.
         *
         * @param {Object} attrs - The component attributes.
         */
        updateForInputComponent(attrs) {
            if (this.inputComponent === BaseSelect) {
                attrs.trackBy = 'value';
                attrs.emitTracked = true;
            }

            if (this.control.kind === 'boolean' && this.control.type === 'legal-document') {
                attrs.emitInitialValue = true;
            }

            if (this.inputComponent === DatetimePicker) {
                this.updateDatetimePickerOptions(attrs);
            }
        },

        /**
         * Updates the options for the datetime picker.
         *
         * @param {Object} attrs - The component attributes.
         */
        updateDatetimePickerOptions(attrs) {
            const opts = attrs.options;

            if (opts.dates_enabled && !opts.times_enabled) {
                attrs.type = 'date';
                attrs.format = opts.date_format;
            } else if (opts.times_enabled && !opts.dates_enabled) {
                attrs.type = 'time';
                attrs.format = opts.time_format;
            } else {
                attrs.type = 'datetime';
                const format = `${opts.date_format || ''} ${opts.time_format || ''}`;
                attrs.format = format.trim();
            }

            if (opts.dates?.length) {
                attrs.notBefore = opts.dates[0];
                attrs.notAfter = opts.dates[1];
            }

            if (opts.times?.length) {
                attrs.notBeforeTime = opts.times[0];
                attrs.notAfterTime = opts.times[1];
            }
        },

        /**
         * Updates the hasDefault attribute in the component attributes.
         *
         * @param {Object} attrs - The component attributes.
         */
        updateDefault(attrs) {
            if (this.control.hasOwnProperty('default')) {
                const def = this.control.default;
                if (typeof def === 'string') {
                    if (!isEmpty(def.trim())) {
                        attrs.hasDefault = true;
                    }
                } else if (def !== null && def !== undefined) {
                    attrs.hasDefault = true;
                }
            }
        },

        /**
         * Filters non-writable values from the control's options.
         *
         * @param {Object} values - The control's options.
         */
        filterNonWritableValues(values) {
            const writables = get(this, 'control.kind_options.user_writable');
            if (writables?.length) {
                const keys = Object.keys(values);
                keys.forEach(k => {
                    if (!writables.includes(k)) {
                        delete values[k];
                    }
                });
            }
        },

        /**
         * Triggered when user interacts with input components.
         *
         * @param {*} value the value of the input
         */
        input(value) {
            if (value === null || value === undefined) return;
            this.$emit('input', value);
        }
    }
};
</script>
