
<template lang="pug">
control-decorator(:label="label" :tip="tip" :hint="hint" :uid="uid" :errors="errors")
    date-picker.date-picker.form-control(
        ref="picker"
        v-on="listeners"
        v-bind="attrs"
        :append-to-body="true"
        :class="{'has-error': errors.length}"
        :default-value="defaultDate"
        :disabled-date="disabledDate"
        :disabled-time="disabledTime"
        :format="format"
        :input-attr="{id: uid}"
        :lang="$i18n.locale"
        :minute-step="10"
        :placeholder="format"
        :value="innerValue"
        :value-type="valueType"
        @input="input"
    )

        template(#calendar-icon="{}")
            i.ic-calendar

        template(#mx-clear-icon="{}")
            i.ic-x
</template>
<script>
// Components
import BaseControl from '@/components/form-elements/BaseControl.vue';

// Mixins
import calendarMixin from '@/components/form-elements/calendar.mixin';

/**
 * Base component to wrap dates inputs with a common markup.
 *
 * It has all `BaseControl` options plus:
 *
 * @vue-prop {String} [type="date"] the type of the input
 *
 * @example
 *
 * datetime-picker(:label="t('name')" type="text" v-model="form.name" validations="required" name="name")
 *
 * @category ui.components.form-elements.pickers
 * @exports DatetimePicker
 */
export default {
    name: 'DatetimePicker',

    extends: BaseControl,

    mixins: [calendarMixin],

    methods: {
        /**
         * Tries to grab the focus on the input element
         */
        attemptFocus() {
            const picker = this.$refs.picker;

            if (this.focused && picker) {
                picker.$refs.input.focus();
            }
        }
    }

};
</script>
