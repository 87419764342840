// Utils
import { get } from 'lodash';
import { hexToHSL, isValidHexColor } from '@/libs/utils/colors';

/**
 * @typedef {Object} LegacyTheme
 *
 * @property {string} buttonsBackgroundColor
 * @property {string} buttonTextColor
 * @property {string} emphasizedColor
 */

/**
 * @const {Array<string>} CSS_THEME_FIELDS the list of fields that are transformed into CSS custom props
 */
export const CSS_THEME_FIELDS = [
    'button_background_color',
    'button_foreground_color'
];

/**
 * Transforms the theme object into a set of
 * CSS custom properties
 *
 * @param theme the theme object
 *
 * @returns {string} the compiled CSS for the theme
 */
export function themeToCss(theme) {
    const props = [];

    for (const key of Object.keys(theme)) {
        if (!CSS_THEME_FIELDS.includes(key)) continue;

        const prop = `--${key.replace(/_/g, '-')}`;
        const value = theme[key];

        if (/color/.test(key)) {
            if (isValidHexColor(value)) {
                props.push(`${prop}:${value};`);
            }
        } else {
            props.push(`${prop}:${value};`);
        }
    }

    if (theme.button_background_color) {
        const primaryHSL = hexToHSL(theme.button_background_color);

        props.push(`--utility-color:${primaryHSL.h},${primaryHSL.s}%;`);
        props.push(`--utility-color-light:${primaryHSL.l}%;`);
    }

    return `:root{${props.join('')}}`;
}

/**
 * Given a branding document this method builds the legacy CSS
 *
 * @param {object} branding the branding doc
 *
 * @returns {string} the compiled CSS
 */
export function legacyThemeToCss(branding) {
    const { buttonsBackgroundColor, buttonTextColor, emphasizedColor } = getLegacyTheme(branding);

    let mainColor = buttonsBackgroundColor;
    let buttonbkg = mainColor;
    let buttontxt = buttonTextColor;

    if (!mainColor) {
        mainColor = emphasizedColor;
        buttonbkg = 'transparent';
        buttontxt = emphasizedColor;
    }

    if (!buttontxt) {
        buttontxt = '#FFF';
    }

    return `
    .card-panel a:not(.btn),
    .account a {
        color: ${mainColor};
    }
    .panel-invites .tabs ul li.active {
        border-bottom-color: ${mainColor};
    }
    .btn:not(.btn-tertiary), li.pill.active {
        background-color: ${buttonbkg};
        color: ${buttontxt};
        border-color: ${mainColor};
    }
    .btn:not(.btn-tertiary):hover {
        color: ${buttontxt};
    }
    .pulsar {
        background-color: ${mainColor};
    }
    *:focus {
        outline-color: ${mainColor};
    }
    input[type=text]:focus,
    input[type=email]:focus,
    input[type=search]:focus,
    input[type=number]:focus,
    input[type=password]:focus,
    select:focus {
        border-color: ${mainColor} !important;
    }
    .webinar-info .menu > li > a:hover {
        color: ${mainColor};
    }`;
}

/**
 * Given a branding this method returns the legacy theme object
 *
 * @param {object} branding the branding configuration
 *
 * @returns {LegacyTheme} the theme for the branding
 *
 * @private
 */
function getLegacyTheme(branding) {
    const buttonsBackgroundColor = get(branding.theme, 'activation_screens.button.background_color');
    const emphasizedColor = get(branding.theme, 'webapp.emphasized_color') || '#fb4f15';
    let buttonTextColor = get(branding.theme, 'activation_screens.button.title.font_color');

    if (branding.is_webinar) {
        buttonTextColor = '#FFFFFF';
    }

    const theme = {
        buttonsBackgroundColor,
        buttonTextColor,
        // Legacy...
        emphasizedColor
    };

    console.debug('[Theme] Legacy theme', theme);

    return theme;
}
