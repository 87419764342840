<template lang="pug">
.evt-icon
    .icon(ref="logo" :alt="alt")
</template>
<script>

/**
 * Check if "src" loads
 * @type {(src: string) => Promise<boolean>}
 */
const checkIfImageLoads = src =>
    new Promise(resolve => {
        const image = new Image();
        image.addEventListener('load', () => resolve(true));
        image.addEventListener('error', () => resolve(false));
        image.src = src;
    });

export default {
    name: 'EventLogo',

    props: {
        src: {
            type: String,
            default: '',
        },
        alt: {
            type: String,
            default: '',
        },
    },

    created() {
        checkIfImageLoads(this.src)
            .then(itLoads => {
                // set background image if it loads (default is set with css)
                if (itLoads) {
                    this.$refs.logo.style.backgroundImage = `url('${this.src}')`;
                }
            });
    },
};
</script>
