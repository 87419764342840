<template lang="pug">
.speaker-details.modal-wrapper
    .modal-header
        a.close(@click="$emit('close')")
        .picture-bg
        picture
            img(v-if="avatar" :srcset="`${avatar} 2x`" :alt="speaker.title" @error="avatar = null")
            svg(v-else width="80" height="80" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg")
                path(fill-rule="evenodd" clip-rule="evenodd" d="M20 20C17.2433 20 15 17.7567 15 15C15 12.2433 17.2433 10 20 10C22.7567 10 25 12.2433 25 15C25 17.7567 22.7567 20 20 20ZM20 11.25C17.9325 11.25 16.25 12.9325 16.25 15C16.25 17.0675 17.9325 18.75 20 18.75C22.0675 18.75 23.75 17.0675 23.75 15C23.75 12.9325 22.0675 11.25 20 11.25ZM27.5 29.375C27.5 29.72 27.78 30 28.125 30C28.47 30 28.75 29.72 28.75 29.375C28.75 24.55 24.825 20.625 20 20.625C15.175 20.625 11.25 24.55 11.25 29.375C11.25 29.72 11.53 30 11.875 30C12.22 30 12.5 29.72 12.5 29.375C12.5 25.2392 15.8642 21.875 20 21.875C24.1358 21.875 27.5 25.2392 27.5 29.375Z" fill="white")

        .details
            .title {{ speaker.title }}
            .subtitle(v-if="speaker.subtitle") {{ speaker.subtitle }}
            .bottom(v-if="speaker.bottom") {{ speaker.bottom }}

    .modal-body
        .bio(v-html="speaker.biography")

    .modal-footer
</template>
<script>
export default {
    name: 'SpeakerDetails',

    props: {
        speaker: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            avatar: this.speaker.picture
        }
    }
};
</script>
