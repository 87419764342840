<template lang="pug">
control-decorator(:label="topLabel" :tip="tip" :hint="hint" :uid="uid" :errors="errors")
    template(#label="")
        label.checkbox(:class="{'has-error': errors.length}")
            input.form-control(
                ref="input"
                :id="uid"
                type="checkbox"
                :name="name"
                @change="change"
                :checked="value"
                :disabled="disabled")
            span.text(v-text="controlLabel")
</template>
<script>
// Components
import BaseControl from './BaseControl.vue';

/**
 * Base component to wrap checkboxes with a common markup.
 *
 * It has all `BaseControl` options, plus:
 *
 * @vue-prop {String} [topLabel=null] an optional label to be used as a control group label
 *
 * @example
 *
 * checkbox(:label="t('test_event')" v-model="form.test_event" name="test_event")
 *
 * @category ui.components.form-elements
 * @exports Checkbox
 * @component
 */
export default {
    name: 'Checkbox',

    extends: BaseControl,

    props: {
        topLabel: {
            type: String,
            default: null
        },

        value: {
            type: Boolean,
            default: false
        },

        emitInitialValue: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            controlLabel: this.label
        };
    },

    mounted() {
        if (this.emitInitialValue) {
            this.$emit('input', this.value);
        }
    },

    methods: {
        /**
         * Triggered when user interacts with selects component.
         *
         * @param {Object} payload the selected payload
         */
        change(payload) {
            this.$emit('input', payload.target.checked);
        }
    }
};
</script>
