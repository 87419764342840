<template lang="pug">
control-decorator(:label="label" :tip="tip" :name="name" :hint="hint" :errors="errors" :uid="uid" :hint-after-label="true")
    .radiogroup(:class="{ 'radiogroup-inline': inline, 'with-custom-template': useCustomTemplate }")
        label.radio(v-for="opt in options" :class="{'has-error': errors.length}" :disabled="disabled")
            input.form-control(
                type="radio"
                :name="name"
                @input="input"
                :id="opt.uid"
                :disabled="disabled"
                :checked="value === opt.value"
                :value="opt.value")
            span.text(v-if="!useCustomTemplate") {{ opt.label }}
            i.tip.icon-info-sign(v-if="!useCustomTemplate && opt.tip" v-tooltip.right="opt.tip")
            .radio-content-template(v-if="useCustomTemplate")
                slot(:name="opt.value")
</template>
<script>
// Components
import BaseControl from './BaseControl.vue';

/**
 * Base component to wrap radio inputs with a common markup.
 *
 * It has all `BaseControl` options plus:
 *
 * @vue-prop {Object[]} options the radio buttons options
 * @vue-prop {String} options.value the radio button value
 * @vue-prop {String} options.label the radio button label
 * @vue-prop {Boolean} inline order radio buttons horizontally
 * @vue-prop {Boolean} useCustomTemplate whether instead of displaying the label a custom template should be used. Do not use with `inline=true`
 *
 * @example
 *
 * radio-buttons(
 *      v-model="form.visibility"
 *      v-validate="'required'"
 *      name="visibility"
 *      :errs="$errors"
 *      :focused="focus == 'organization'"
 *      :disabled="!form.organization"
 *      :options="options"
 * )
 *
 * @category ui.components.form-elements
 * @exports Radiobuttons
 * @component
 */
export default {
    name: 'RadioButtons',

    extends: BaseControl,

    props: {
        options: {
            type: Array,
            required: true
        },

        inline: {
            type: Boolean,
            default: false,
        },

        useCustomTemplate: {
            type: Boolean,
            default: false
        }
    }
};
</script>
