<template lang="pug">
span.reqirements
    template(v-for="(req, index) in requirements")
        a(:href="req.url" target="_blank" rel="noopener noreferrer") {{ req.link_label }}
        template(v-if="index + 1 !== requirements.length")
            | ,
            |
</template>
<script>
export default {
    name: 'Requirements',

    props: {
        requirements: {
            type: Array,
            required: true
        }
    }
}
</script>
