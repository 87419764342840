<template lang="pug">
.response
    .banner(v-if="banner")
        img(:src="banner")
    .text-center
        h3(v-if="params.title") {{params.title}}
        section(v-if="params.body") {{params.body}}
</template>

<script>
export default {
    name: 'DefaultResponse',

    props: {
        banner: {
            type: String,
            default: null,
        },
        params: {
            type: Object,
            default: () => ({
                title: null,
                body: null
            })
        }
    }
}
</script>