<template lang="pug">
.disclaimers(v-if="requirements.length")
    template(v-if="$te('registration.form.disclaimer')")
        | {{ $t("registration.form.disclaimer") }}
        |
        requirements(:requirements="requirements")

    template(v-else)
        i18n(tag="p" v-if="requirements.length" path="registration.form.disclaimer_interpolation")
            requirements(:requirements="requirements")
</template>
<script>
// Components
import Requirements from '@/components/registration/Requirements.vue';

export default {
    name: 'Disclaimers',

    components: { Requirements },

    props: {
        requirements: {
            type: Array,
            default: () => []
        }
    }
}
</script>
