<template lang="pug">
.invitations-list(v-if="invitations.length != 0" :class="context")
    h5 {{ title }}
    .events
        invitation-card(
            :class="context"
            v-for="invite in invitations"
            :key="`${context}-${invite.pid}-${invite.eid}`"
            :invite="invite"
            @click="activate(invite)")
</template>
<script>
// Components
import InvitationCard from '@/components/InvitationCard.vue';

export default {
    name: 'InvitationsList',

    components: { InvitationCard },

    props: {
        title: {
            type: String,
            required: true
        },

        activate: {
            type: Function,
            required: true
        },

        context: {
            type: String,
            default: 'current'
        },

        invitations: {
            type: Array,
            default: () => []
        }
    }
}
</script>
