<template lang="pug">
p(v-if="enabled")
    label
        input(type="checkbox" v-bind:value="value" v-model="requirement.accepted" @change="legalChange")
        i18n(tag="span" :path="translationLabel")
            slot(v-if="hasLink")
            span(v-else) {{ label }}
</template>
<script>
// Utils
import { merge, set } from 'lodash';
export default {
    name: 'LegalRequirement',

    props: {
        requirement: {
            type: Object,
            required: true
        }
    },

    data() {
        const label = this.requirement.label || '';
        const enabled = Boolean(label);
        const hasLink = label.includes('{{link}}');

        return {
            enabled,
            value: this.requirement.id,
            label,
            hasLink
        };
    },

    computed: {
        /** @returns {string} */
        translationLabel() {
            return this.requirement ? `requirements.${this.requirement.id}` : '';
        }
    },

    created() {
        if (!this.enabled) return;

        const lang = this.$i18n.locale;
        const locales = this.$i18n.getLocaleMessage(lang)
        const label = this.requirement.label.replaceAll('{{link}}', '{0}');
        const req = {};
        set(req, this.translationLabel, label);

        this.$i18n.setLocaleMessage(lang, merge(locales, req));

        if (this.requirement.url) {
            this.requirement.type = 'url';
            this.requirement.title = this.requirement.link_label;
        }
    },

    methods: {
        legalChange() {
            this.$emit(
                'selection',
                this.requirement.id,
                this.requirement.accepted
            );
        }
    }
};
</script>
