<template lang="pug">
section.session(v-if="visible")
    h3(v-if="title") {{ title }}
    .description(v-if="description" v-html="description")

    session-dates(
        :sessions="sessions"
        :event-timezone="tz"
        :use-event-timezone="useEventTimezone"
        @change="d => currentDate = d")

    expandable-list(v-if="visibleSessions.length"
        item-key="session"
        list-class="sessions"
        :items="visibleSessions"
        :component="component"
        :max-items="section.max_items"
        :params="{ config }")

</template>
<script>
// Utils
import { get } from 'lodash';

// Mixins
import section from './section.mixin';

// Components
import SessionDates from '@/components/registration/sections/SessionDates.vue';
import SessionDetails from '@/components/registration/sections/SessionDetails.vue';

export default {
    name: 'Session',

    mixins: [section],

    components: { SessionDates, SessionDetails },

    data() {
        return {
            component: SessionDetails,
            currentDate: null,
            sessions: this.section.sessions || [],
            tz: get(this.config, 'event_details.timezone'),
            useEventTimezone: get(this.config, 'event_details.display_timezone') !== 'device'
        };
    },

    computed: {
        visibleSessions() {
            return this.sessions.filter(s => s.dates.day === this.currentDate);
        }
    },

    created() {
        this.$utils.sessions.decorateSessions(this.sessions, this.$i18n.locale, this.tz, this.useEventTimezone);
    }
};
</script>

