// Utils
import { capitalize, humanize } from '@/libs/utils/strings';
import { uniqueId } from 'lodash';

/**
 * Retrieves the label for the control.
 *
 * @param {object} control - The control object.
 * @param {object} $i18n - The i18n object.
 *
 * @returns {string} The label for the control.
 */
export function getLabel(control, $i18n) {
    let label = control.label || capitalize(humanize(control.name));

    const translationKey = `registration.form.fields.${control.name}`;
    const labelExists = $i18n.te(translationKey) || $i18n.te(translationKey, 'en');
    label = labelExists ? $i18n.t(translationKey) : label;

    if (control.required) {
        label = `${label} *`;
    }

    return label;
}

/**
 * Returns the proper choice-list option label for the control's kind option value.
 * @param {object} control the control
 * @param {string} label the default label
 * @param {string} value the value for which we want the label
 * @param {object} $i18n the i18n framework
 *
 * @returns {string} the correct label for the given option
 */
export function getOptionLabel(control, label, value, $i18n) {
    const replacer = (match) => '§'.repeat(match.length);
    // those two replace are made to support leading and trailing dots in the choice keys.
    value = value.replace(/^\.+/, replacer).replace(/\.+$/, replacer);
    const optionValueKey = `registration.form.options.${control.name}.${value}`;
    if ($i18n.te(optionValueKey) || $i18n.te(optionValueKey, 'en')) {
        return $i18n.t(optionValueKey);
    }
    return label;
}

/**
 * Returns the control name.
 *
 * @param {object} control - The control object.
 *
 * @returns {string} The control name.
 */
export function getControlName(control) {
    return control.id || control.name || control.label || uniqueId();
}


/**
 * Generates a unique name for a control.
 *
 * @param {Object} control - The control object.
 *
 * @returns {string} The unique name for the control.
 */
export function getControlUniqueName(control) {
    return `${getControlName(control)}-${uniqueId('control-')}`;
}

/**
 * Retrieves the placeholder for the control.
 *
 * @param {object} control - The control object.
 * @param {object} $i18n - The i18n object.
 *
 * @returns {string} The placeholder for the control.
 */
export function getPlaceholder(control, $i18n) {
    const placeholder = control.placeholder || control.example;
    let ph = $i18n.te(placeholder) ? $i18n.t(placeholder) : placeholder;

    const placeholderTKey = `registration.form.placeholders.${control.name}`;
    if ($i18n.te(placeholderTKey) || $i18n.te(placeholderTKey, 'en')) {
        ph = $i18n.t(placeholderTKey);
    }

    return ph;
}

/**
 * Retrieves the validators for a control.
 *
 * @param {object} control - The control object.
 * @param {string} eventId - The event ID.
 * @param {object} settings - The settings object.
 * @param {boolean} [editing=false] - Indicates if the control is being edited.
 *
 * @returns {object} - The validations object.
 */
export function getControlValidators(control, eventId, settings, editing = false) {
    const validations = {};
    if (control.validations) {
        control.validations.forEach(validation => validations[validation] = true);
    }

    if (control.required) {
        if (control.type === 'sessions') {
            validations['session_required'] = { control };
        } else {
            validations['required'] = true;
        }

        if (control.kind === 'boolean' || control.type === 'legal-document') {
            validations['required'] = { allowFalse: false };
        }
    }

    if (control.kind === 'number') {
        validations['double'] = true;
    }

    if (control.name === 'email' && !editing) {
        // In case of editing (profile update) we're not re-validating the domain
        // see https://spotme.atlassian.net/browse/EP-13358
        validations['email_domain'] = { eventId, settings };
    }

    if (control.kind === 'email') {
        validations['email'] = true;
    }

    return validations;
}

/**
 * Controls which mode of validation is needed for the given field
 *
 * @param {object} control the field descriptor to check the validation mode for
 *
 * @returns {'lazy'|'aggressive'|'passive'|'eager'}
 *
 * @see https://vee-validate.logaretm.com/v3/guide/interaction-and-ux.html#interaction-and-ux
 */
export function getValidatorsMode(control) {
    if (control.name === 'email') {
        return 'passive';
    }

    if (control.kind === 'boolean' || control.type === 'legal-document') {
        return 'lazy';
    }

    return 'aggressive';
}
