// Classes
import BaseService from './base-service';

// Utils
import { uniq, get } from 'lodash';

// Constants
import { API_BASE_PATH } from '@/libs/utils/constants';
import { addQueryParamsToUrl } from '@/libs/utils/url';

/**
 * Registration page config endpoint
 * @constant {string} REG_PAGE_URL_ENDPOINT
 */
const REG_PAGE_URL_ENDPOINT = `${API_BASE_PATH}/appservice/assets/{{branding}}/config/{{slug}}`;

/**
 * Registration page config endpoint for templates
 * @constant {string} TEMPLATE_REG_PAGE_URL_ENDPOINT
 */
const TEMPLATE_REG_PAGE_URL_ENDPOINT = `${API_BASE_PATH}/appservice/assets/config/template/{{templateEid}}`;

/**
 * Branding api endpoint
 * @constant {string} BRANDING_API_URL
 */
const BRANDING_API_URL = window.APP.brandingAssetsUrl;

/**
 * The API endpoint to load the legal requirements for the branding
 * @constant {string} APP_LEGAL_REQUIREMENT_API_URL
 */
const APP_LEGAL_REQUIREMENT_API_URL = `${window.APP.publicUrl}${API_BASE_PATH}/legal/requirements/{{branding}}`;

/**
 * The API endpoint to load the legal requirements specific for the event
 * @constant {string} EVENT_LEGAL_REQUIREMENT_API_URL
 */
const EVENT_LEGAL_REQUIREMENT_API_URL = `${APP_LEGAL_REQUIREMENT_API_URL}/{{shortcode}}`;

/**
 * Provides methods necessary for managing apps branding
 *
 * @example
 *
 * import BrandingService from 'libs/services/branding';
 * ...
 * const brand = new BrandingService();
 */
export default class BrandingService extends BaseService {

    constructor() {
        super();

        /** @type {string|undefined} */
        this.registrationUrl = undefined;
    }

    /**
     * Fetches cluster url from infra if not defined
     * if defined returns it
     *
     * @param {string} branding the name of the branding to load the config for
     * @param {string} slug the unique path name used for the registration
     * @returns {Promise<string>}
     */
    async getRegistrationUrl(branding, slug) {
        if (this.registrationUrl) { return this.registrationUrl; }
        const url = this.buildUrl(addQueryParamsToUrl(REG_PAGE_URL_ENDPOINT, { url: true }), { branding, slug });
        const { data: { registrationUrl } } = await this.get(url);
        if (!registrationUrl) {
            throw new Error('[BrandingService] registrationUrl is not defined');
        }
        this.registrationUrl = registrationUrl;
        return registrationUrl;
    }


    /**
     * Fetches cluster url from infra if not defined
     * if defined returns it
     *
     * @param {string} branding the name of the branding to load the config for
     * @param {string} slug the unique path name used for the registration
     * @returns {Promise<string>}
     */
    async getRegistrationUrlForTemplate(templateEid) {
        if (this.registrationUrl) { return this.registrationUrl; }
        const url = this.buildUrl(addQueryParamsToUrl(TEMPLATE_REG_PAGE_URL_ENDPOINT, { url: true }), { templateEid });
        const { data: { registrationUrl } } = await this.get(url);
        if (!registrationUrl) {
            throw new Error('[BrandingService] registrationUrl is not defined');
        }
        this.registrationUrl = registrationUrl;
        return registrationUrl;
    }

    /**
     * Loads the branded app configuration
     *
     * @param {string} branding the name of the branding to get the config for
     *
     * @returns {Promise<object>} the branded app configuration
     */
    async getAppConfig(branding) {
        const url = this.buildUrl(BRANDING_API_URL, { asset: 'config', branding });
        const { data } = await this.getCached(url);
        return data;
    }

    /**
     * Returns the registration page configuration
     *
     * @param {string} branding the name of the branding to load the config for.
     * @param {string} slug the unique path name used for the registration.
     * @param {object} session the user's session information
     * @param {string} [templateEid] the eid (only for templates)
     * @param {boolean} [bustCache] bust cache when coming from backstage
     *
     * @returns {Promise<object>} the registration page configuration
     */
    async getRegistrationConfig(branding, slug, session, templateEid, bustCache) {
        let url = templateEid
            ? await this.getRegistrationUrlForTemplate(templateEid)
            : await this.getRegistrationUrl(branding, slug);

        const registrationUrl = url;

        if (bustCache) {
            url = addQueryParamsToUrl(url, { _: new Date().getTime() });
        }

        const { data } = await this.get(url);

        if (templateEid) {
            data.templateEid = templateEid;
            return data;
        }

        await this.decorateWithLegals(branding, data);
        this.decorateWithInvitation(data, session.invites, session.webinars);
        if (templateEid) { data.templateEid = templateEid; }
        data.registrationUrl = registrationUrl;

        return data;
    }

    /**
     * Loads the legal requirements for the branding
     *
     * @param {string} branding the name of the branding to load the config for.
     *
     * @returns {Promise<object[]>} the event's legal requirements
     */
    async getAppLegalRequirements(branding) {
        const result = await this.get(this.buildUrl(APP_LEGAL_REQUIREMENT_API_URL, { branding }));
        return get(result, 'data.requirements', []);
    }

    /**
     * Loads the legal requirements for the workspace or webinar
     *
     * @param {string} branding the name of the branding to load the config for.
     * @param {string} shortcode the event's short code
     * @param {boolean} [all] do not remove docs in container legal docs
     *
     * @returns {Promise<object[]>} the event's legal requirements
     */
    async getEventLegalRequirements(branding, shortcode, all) {
        let url = this.buildUrl(EVENT_LEGAL_REQUIREMENT_API_URL, { branding, shortcode });
        const params = {};
        if (all) {
            params.all = true;
        }
        const result = await this.get(url, { params });
        return get(result, 'data.requirements', []);
    }

    /**
     * Tries to load and enrich the configuration with legal documents
     *
     * @param {string} branding the branding to load legal docs for
     * @param {object} config the configuration object to enrich
     *
     * @private
     */
    async decorateWithLegals(branding, config) {
        try {
            const requirements = await this.getEventLegalRequirements(branding, config.event_details.shortcode, true);

            Object.assign(config.event_details, { requirements: uniq(requirements, 'url') });
        } catch (error) {
            console.error('[BrandingService] Could not decorate config with legal documents:', error.message);
        }
    }

    /**
     * Decorates the event details with the event's corresponding
     * invitation, if it exists
     *
     * @param {object} config the configuration object to enrich
     * @param {Invitation[]} invitations a list of user's invitation
     *
     * @private
     */
    decorateWithInvitation(config, invitations, webinars) {
        let invitation = invitations.find(i => i.eid === config.event_details._id);
        if (!invitation) {
            invitation = webinars.find(i => i.eid === config.event_details._id);
        }
        Object.assign(config.event_details, { invitation });
    }
}
