<template lang="pug">
control-decorator(:label="label" :tip="tip" :hint="hint" :errors="errors" :uid="uid")
    textarea.form-control(
        v-on="listeners"
        ref="input"
        :id="uid"
        @input="input"
        :name="name"
        :value="value"
        :disabled="disabled"
        :rows="rows"
        :class="{'has-error': errors.length}"
        :placeholder="placeholder")
</template>
<script>
// Components
import BaseControl from './BaseControl.vue';

/**
 * Base component to wrap textarea with a common markup.
 *
 * It has all `BaseControl` options plus:
 *
 * @vue-prop {String} [type="text"] the type of the input
 *
 * @example
 *
 * base-textarea(:label="t('name')" :errs="errors" type="textarea" v-model="form.name" v-validate="'required'" name="name")
 *
 * @category ui.components.form-elements
 * @exports BaseTextarea
 * @component
 */
export default {
    name: 'BaseTextarea',

    extends: BaseControl,

    props: {
        rows: {
            type: Number,
            default: 3
        }
    }
};
</script>
