<template lang="pug">
section.presenter(v-if="visible")
    h3(v-if="title") {{ title }}
    .description(v-if="description" v-html="description")

    expandable-list(
        item-key="speaker"
        list-class="speakers"
        :items="presenters"
        :component="component"
        :max-items="maxItems"
        :params="{ config }")

</template>
<script>
// Utils
import { get } from 'lodash';

// Mixins
import maxItems from './max-items.mixin';
import section from './section.mixin';

// Components
import SpeakerCard from '@/components/registration/sections/SpeakerCard.vue';

export default {
    name: 'Presenter',

    mixins: [section, maxItems],

    data() {
        return {
            component: SpeakerCard,
            presenters: get(this, 'section.presenters', [])
        }
    }
};
</script>
