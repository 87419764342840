<template lang="pug">
.standard-login

  .login-methods(v-if="step == 1")
    h2 {{$t(page.title || 'login.magic_link_title')}}
    p {{$t(page.email_description || 'login.magic_link')}}
    a.btn.email.btn-block(@click.prevent="magickLink") {{$t(page.magick_link || 'login.account_send_email_activation')}}
    a.btn.btn-tertiary(@click.prevent="manualLogin") {{$t(page.manual_login || 'login.manual_login')}}

  .login-with-password(v-if="step == 2")
    .account-group(v-if="!emailValidated")
      .form-group(v-if="allowNonUniqueEmail && requestInvitation")
        label {{$t(page.fname_label || 'activation.account_login_screen.fname_label')}}
        input.form-control.validate(
          name="fname"
          type="text"
          v-model="fname"
          :class="{ 'is-invalid': emailError }"
          :disabled="submitting"
          :placeholder="$t(page.fname_placeholder || 'activation.account_login_screen.fname_placeholder')"
          maxlength="255",
          autocomplete="given-name"
          required)
      .form-group(v-if="allowNonUniqueEmail && requestInvitation")
        label {{$t(page.lname_label || 'activation.account_login_screen.lname_label')}}
        input.form-control.validate(
          name="lname"
          type="text"
          v-model="lname"
          :class="{ 'is-invalid': emailError }"
          :disabled="submitting"
          :placeholder="$t(page.lname_placeholder || 'activation.account_login_screen.lname_placeholder')"
          maxlength="255",
          autocomplete="family-name"
          required)
      .form-group
        label {{$t(page.email_label || 'activation.account_login_screen.email_label')}}
        input.form-control.validate(
          name="email"
          type="email"
          v-model="email"
          :class="{ 'is-invalid': emailError }"
          :disabled="submitting || emailValidated || preventautocomplete"
          :placeholder="$t(page.email_placeholder || 'activation.account_login_screen.email_placeholder')"
          maxlength="255",
          autocomplete="false"
          required)
        .invalid-feedback(v-if="loginErrors")
          p(v-for="(error) in loginErrors") {{$t(error)}}

    small.form-description.text-muted(v-if="requestInvitation") {{$t( page.magic_link_hint || 'activation.account_login_screen.magic_link_hint' )}}

    .pass-group(v-if="emailValidated && !requestNewPasswd")

      .form-group
        label {{$t(page.password_label || 'login.password')}}
        input.form-control.validate(
          name="password"
          type="password"
          v-model="password"
          :class="{ 'is-invalid': passwdError }"
          :disabled="submitting"
          :placeholder="$t(page.password_placeholder)"
          maxlength="255",
          autocomplete="false"
          required
          @keyup="touch('password')")
        .invalid-feedback
          p {{$t('login.change_password_description')}}
        password-meter(v-if="!existingEmail" :password='password')

        .service-links(v-if="existingEmail")
          a.forgot(@click.prevent="resetPassword") {{$t(page.forgotten_password_label || 'login.forgotten_password')}}


      .form-group(v-if="!existingEmail")
        label {{$t(page.confirm_password_label || 'login.confirm_password')}}
        input.form-control.validate(
          type="password"
          v-model="passwordConfirmation"
          :class="{ 'is-invalid': passwdConfirmError }"
          :disabled="submitting"
          :placeholder="$t(page.password_placeholder)"
          maxlength="255",
          autocomplete="false"
          required
          @keyup="touch('passwordConfirmation')")
        small.form-text.text-muted {{$t( page.hint_password_match )}}
        .invalid-feedback
          p {{$t('login.password_error_no_match')}}

  .restore-password(v-if="requestNewPasswd && step != 4")
    h2 {{$t('login.restore_password_title')}}
    p {{$t('login.restore_password_text')}}

    .form-group
      label {{$t(page.email_label || 'activation.account_login_screen.email_label')}}
      input.form-control.validate(
        type="email"
        v-model="email"
        :class="{ 'is-invalid': emailError }"
        :placeholder="$t(page.email_placeholder || 'activation.account_login_screen.email_placeholder')"
        maxlength="255",
        autocomplete="false"
        required)
      .invalid-feedback(v-if="loginErrors")
        p(v-for="(error) in loginErrors") {{$t(error)}}

  .email-sent(v-if="step == 4")
    h2 {{$t(labels.title)}}
    p {{$t(labels.emailSentMessage, [email])}}
    hr
    p {{$t(labels.resendMessage)}}
    a(@click.prevent="submit") {{$t(labels.resendLinkMessage)}}
</template>
<script>
import { LoginException } from '@/exceptions';
import PasswordMeter from '@/components/PasswordMeter.vue';

export default {
    name: 'Account',

    props: [ 'page', 'isRequesting', 'allowNonUniqueEmail' ],

    components: { PasswordMeter },

    data() {
        const p = this.page;
        return {
            labels: {},
            email: this.$route.query.email || '',
            fname: this.$route.query.fname || '',
            lname: this.$route.query.lname || '',
            emailError: false,
            password: '',
            passwordConfirmation: '',
            type: p.type,
            submitting: false,
            loginErrors: [],
            existingEmail: null,
            emailValidated: false,
            requestInvitation: false,
            requestNewPasswd: false,
            preventautocomplete: false,
            step: 1,
            touched: { password: false, passwordConfirmation: false }
        };
    },

    computed: {
        userData() {
            return {
                email: this.email,
                fname: this.fname,
                lname: this.lname,
                password: this.password,
                branding: this.$route.params.branding
            };
        }
    },

    watch: {
        email() {
            this.validate();
        },
        fname() {
            this.validate();
        },
        lname() {
            this.validate();
        },
        password() {
            this.validate();
        },
        passwordConfirmation() {
            this.validate();
        }
    },

    activated() {
        this.back();
        this.$emit('nextChanged', true);
    },

    methods: {
        touch(field) {
            this.touched[field] = true;
        },

        validate(addFlags) {
            this.loginErrors = [];
            let valid = this.$strings.validateEmail(this.email);

            if (this.allowNonUniqueEmail) {
                valid &&= this.fname && this.lname;
            }

            if (!this.requestInvitation && !this.requestNewPasswd) {
                if (this.emailValidated && this.password.length === 0) {
                    valid = false;
                    if (addFlags && this.touched['password'].touched) {
                        this.passwdError = true;
                        this.loginErrors.push(
                            'login.change_password_description'
                        );
                    }
                }

                if (
                    this.existingEmail === false &&
                    (this.passwordConfirmation.length === 0 ||
                        this.password !== this.passwordConfirmation)
                ) {
                    valid = false;
                    if (
                        addFlags &&
                        this.touched['passwordConfirmation'].touched
                    ) {
                        this.passwdConfirmError = true;
                    }
                }
            }

            this.$emit('validity', valid);
            return valid;
        },

        manualLogin() {
            this.back();
            this.step = 2;
            this.$emit('backChanged', true);
            this.$emit('acceptLegals', true);

            if (this.page.require_legal_consent) {
                this.$emit('toggleLegals', true);
                this.$emit('acceptLegals', false);
            }

            if (this.email && this.email.length !== 0) {
                this.validate();
            }
        },

        // ===============
        // Remote requests
        // ===============

        async checkEmail() {
            try {
                if (this.submitting) {
                    return;
                }
                this.submitting = true;
                const { exists } = await this.$services.account.accountExists(this.email, this.$route.params.branding);

                this.existingEmail = exists;
                this.emailValidated = true;

                if (!this.existingEmail) {
                    this.$emit('toggleLegals', true);
                    this.$emit('acceptLegals', false);
                } else {
                    this.$emit('toggleLegals', false);
                }

                if (this.page.require_legal_consent) {
                    this.$emit('acceptLegals', true);
                    this.$emit('toggleLegals', false);
                }

            } catch (error) {
                this.onRequestError(error);

            } finally {
                this.submitting = false;

                this.$emit('validity', false);
                this.$emit(
                    'nextChanged',
                    (this.existingEmail
                        ? this.page.login_button_title
                        : this.page.signup_button_title) || 'login.submit'
                );
                this.validate();
            }
        },

        requestInvitationsLink() {
            return this.$services.account.requestInvitationsLink(this.userData);
        },

        signup() {
            return this.$services.account.signUp(this.userData);
        },

        signin() {
            return this.$services.account.signIn(this.userData);
        },

        passwd() {
            return this.$services.account.requestPasswordReset(this.userData);
        },

        resetPassword() {
            if (!this.requestNewPasswd) {
                this.requestNewPasswd = true;
                this.validate();
                this.$emit('nextChanged', 'login.submit');
                this.$emit('toggleLegals', false);
            } else {
                // We request a login process this way because we want the login
                // controller to perform proper callbacks afterwards.
                this.$emit('requestLogin');
            }
        },

        magickLink() {
            this.manualLogin();

            this.$emit('toggleLegals', true);
            this.$emit('acceptLegals', false);

            this.requestInvitation = true;
        },

        // =================
        // Request callbacks
        // =================

        onRequestSuccess(labels) {
            this.labels = labels || {
                title: this.page.confirmation_title || 'login.check_email',
                emailSentMessage: this.page.confirmation_text || 'login.email_sent',
                resendMessage: this.page.confirmation_secondary_text || 'login.magic_link_not_received',
                resendLinkMessage: this.page.confirmation_secondary_button_title || 'login.resend'
            };

            this.$track.endTrackLogin(true);

            this.$emit('toggleLegals', false);
            this.$emit('nextChanged', true);

            this.step = 4;
        },

        onRequestError(error, reset) {
            const rsp = error.response || {};

            // This is needed because of a running condition bug on Chrome
            // browsers. Autocomplete is broken and cannot be disabled anymore
            // on Chrome.
            this.preventautocomplete = true;

            if (reset) {
                this.back();
            }

            this.step = 2;
            this.emailError = true;

            this.$emit('toggleLegals', true);
            this.$emit('nextChanged', 'login.next');
            this.loginErrors.push('login.email_send_failed');

            setTimeout(() => (this.preventautocomplete = false), 1000);

            throw new LoginException(rsp.data?.error || error.message, rsp.status, this.type);
        },

        // ==============
        // Form callbacks
        // ==============

        back() {
            // Change next button label
            this.$emit('nextChanged', 'login.next');
            this.$emit('backChanged', false);
            this.$emit('toggleLegals', false);

            if (this.step !== 1) {
                this.step = 1;
                this.$emit('nextChanged', true);
            }

            this.existingEmail = null;
            this.emailValidated = false;
            this.emailError = false;
            this.submitting = false;
            this.requestInvitation = false;
            this.requestNewPasswd = false;
        },

        async submit() {
            if (!this.validate(true)) {
                return false;
            }

            if (this.requestInvitation) {
                if (this.step === 4) {
                    return this.back();
                }
                return this.requestInvitationsLink()
                    .then(() => this.onRequestSuccess(), this.onRequestError)
                    .finally(() => (this.submitting = false));
            }

            if (this.requestNewPasswd) {
                if (this.step === 4) {
                    this.$emit('nextChanged', this.page.login_button_title);
                    return (this.step = 3);
                }

                return this.passwd()
                    .then(() =>  this.onRequestSuccess({
                        title: this.page.password_reset_confirmation_title || 'login.check_email',
                        emailSentMessage: this.page.password_reset_confirmation_text || 'login.forgotten_password_sent_description',
                        resendMessage: this.page.password_reset_confirmation_secondary_text || 'login.forgotten_password_link_not_received',
                        resendLinkMessage: this.page.password_reset_confirmation_secondary_button_title || 'login.forgotten_password_resend'
                    }), this.onRequestError)
                    .finally(() => (this.submitting = false));
            }

            if (this.existingEmail === null) {
                this.$emit('backChanged', true);
                this.$emit('nextChanged', this.page.login_button_title);
                return this.checkEmail();
            }

            if (this.passwordConfirmation) {
                return this.signup()
                    .then(() => this.onRequestSuccess({
                        title: this.page.sign_up_confirmation_title || 'activation.account_login_screen.sign_up_confirmation_title',
                        emailSentMessage: this.page.sign_up_confirmation_text || 'activation.account_login_screen.sign_up_confirmation_text',
                        resendMessage: this.page.sign_up_confirmation_secondary_text || 'activation.account_login_screen.sign_up_confirmation_secondary_text',
                        resendLinkMessage: this.page.sign_up_confirmation_secondary_button_title || 'activation.account_login_screen.sign_up_confirmation_secondary_button_title'
                    }), this.onRequestError)
                    .finally(() => (this.submitting = false));
            }

            if (this.existingEmail) {
                try {
                    const { token } = await this.signin();
                    const url = `${window.APP.appRootUrl}/invitations/${this.$route.params.branding}?invitationToken=${token}`;
                    this.$track.endTrackLogin(true);
                    this.$services.routing.redirect(url);
                } catch (error) {
                    this.onRequestError(error, true)
                }
            }
        }
    }
};
</script>
