<template lang="pug">
.summary: main: .container.page-wrapper
    .page
        event-card(:config="config" :show-calendar-button="false" :main-title="false")

        .body
            h3 {{ $t(`registration.form.submissions.summary.title`) }}

            session-details(
                v-for="regSession in sessions"
                :key="regSession._id"
                :config="config"
                :session="regSession"
                :condensed="true")

            .waitlist(v-if="waitlistedSessions.length > 0")
                h3.wl-title {{ $t(`registration.form.submissions.summary_waitlisted.title`) }}

                session-details(
                    v-for="wlSession in waitlistedSessions"
                    :key="wlSession._id"
                    :config="config"
                    :session="wlSession"
                    :condensed="true")

        .form-actions(v-if="showEditButton")
            router-link.btn.btn-medium.btn-primary(:to="{ name: 'registration-form'}") {{ $t('registration.actions.edit') }}
</template>
<script>
// Utils
import SessionsRegistrationOrchestrator from '@/libs/utils/sessions-registration-orchestrator';

// Components
import EventCalendarButton from '@/components/EventCalendarButton.vue';
import EventCard from '@/components/registration/EventCard.vue';
import SessionDetails from '@/components/registration/sections/SessionDetails.vue';

export default {
    name: 'Summary',

    components: {
        EventCard,
        EventCalendarButton,
        SessionDetails
    },

    props: {
        config: {
            type: Object,
            required: true
        },

        session: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            sessions: [],
            waitlistedSessions: [],
            showEditButton: this.$services.publicLogin.canUpdateRegistration(this.config),
            orchestrator: new SessionsRegistrationOrchestrator(this.$services, this.config, this.$i18n.locale)
        };
    },

    async created() {
        await this.orchestrator.init();

        this.sessions = this.orchestrator.getAllSelected();

        const waitlistedSessionsIds = this.config.user?.waitlist || [];
        this.waitlistedSessions = this.orchestrator.sessions.filter(session => waitlistedSessionsIds.includes(session.fp_ext_id));
    },
}
</script>
