/**
 * APIs base endpoint.
 * @constant {string} API_BASE_PATH
 */
export const API_BASE_PATH = '/api/v1';

/**
 * The default brand name
 * @constant {string} DEFAULT_APP_BRANDING
 */
export const DEFAULT_APP_BRANDING = 'eventspace';

/**
 * The name of the cookie where we save the last visited branding
 * @constant {string} BRANDING_COOKIE_NAME
 */
export const BRANDING_COOKIE_NAME = '_branding';

/**
 * The ID of the web application.
 * @constant {string} APP_ID
 */
export const APP_ID = 'webapp';
