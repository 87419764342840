<template lang="pug">
.install(v-if="enabled")

    p {{ $t('login.native_app_install_instructions') }}

    .showoff
        .badges(v-if="playStoreID && appStoreID")
            a.app-store-link(v-if="appStoreID" :href="appStoreURL" target="_blank")
                img(:src="appStoreBadge" :alt="$t('store.apple.app-store-install')")

            a.play-store-link(v-if="playStoreID" :href="playStoreURL" target="_blank")
                img(src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" :alt="$t('store.google.play-store-install')")
</template>
<script>
export default {
    name: 'Install',

    data() {
        return {
            enabled: false,
            appStoreBadge: this.$utils.assets.getStaticAssetSrc('appstore-badge.svg'),
            appStoreBg: `url(${this.$utils.assets.getStaticAssetSrc('iphone-frame.png')})`,
            version: process.env.version,
            playStoreID: false,
            appStoreID: false
        };
    },

    computed: {
        /** @returns {string} */
        playStoreURL() {
            return `https://play.google.com/store/apps/details?id=${this.playStoreID}`;
        },

        /** @returns {string} */
        appStoreURL() {
            return `https://itunes.apple.com/app/id${this.appStoreID}`;
        }
    },

    created() {
        this.$brand.get().then(({ body }) => {
            if (body.webapp.install_instructions && body.store) {
                this.enabled = true;
                this.appStoreID = body.store.appStoreId;
                this.playStoreID = body.store.playStoreId;
            }
        });

        this.enabled = this.enabled || Boolean(this.$route.query.install_instructions);
    }

};
</script>
