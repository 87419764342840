// Utils
import { interpolateUrl } from '@/libs/utils/url';

// Constants

/** @const {string} ROOT_ASSET_URL the asset url relative to the app root instance */
const ROOT_ASSET_URL = window.APP.brandingAssetsUrl;

/** @const {string} EVENT_ASSETS_ENDPOINT the endpoint for getting the event's assets */
const EVENT_ASSETS_ENDPOINT = '{{node_url}}/api/v1/eid/{{eid}}/{{asset}}';

/** @const {string} EVENT_REG_ASSETS_ENDPOINT the endpoint for getting the event's assets */
const EVENT_REG_ASSETS_ENDPOINT = '/api/v1/appservice/assets/{{branding}}/config/{{path_name}}/{{asset}}';

/** @const {string} TEMPLATE_REG_ASSETS_ENDPOINT the endpoint for getting the event's assets */
const TEMPLATE_REG_ASSETS_ENDPOINT = '/api/v1/appservice/assets/config/template/{{eid}}/{{asset}}';

/** @const {string} STATIC_ASSETS_PATH the local app static assets path */
const STATIC_ASSETS_PATH = `${window.APP.assetsPath}/images/{{asset}}`;

// Methods

/**
 * Given an asset name this method returns the url of the asset
 * relative to the root app.
 *
 * @param {string} asset the asset name
 * @param {string} branding the name of the branded app
 *
 * @returns {string} the compiled url to the root app asset
 */
export function getAssetSrc(asset, branding) {
    return transformUrl(ROOT_ASSET_URL, asset, null, branding);
}

/**
 * Given an asset name this method returns the path to the
 * desired static asset.
 *
 * @param {string} asset the asset name
 *
 * @returns {string} the compiled URL to the desired asset
 */
export function getStaticAssetSrc(asset) {
    return transformUrl(STATIC_ASSETS_PATH, asset);
}

/**
 * Given an asset name and the event object, this method
 * build and returns the URL to the requested resource.
 *
 * @param {InvitationEvent} event
 * @param {string} asset the asset name
 *
 * @returns {string} the compiled URL to the desired asset
 */
export function getEventAssetSrc(asset, event) {
    return transformUrl(EVENT_ASSETS_ENDPOINT, asset, event);
}

/**
 * Given an asset name, branding, and the event object, this method
 * build and returns the URL to the requested resource specific
 * for the registration page.
 *
 * @param {string} asset the asset name
 * @param {string} branding the name of the branded app
 * @param {InvitationEvent} event
 *
 * @returns {string} the compiled URL to the desired asset
 */
export function getBrandingAssetSrc(asset, branding, event) {
    return transformUrl(EVENT_REG_ASSETS_ENDPOINT, asset, event, branding);
}

/**
 * Given an asset name, branding, and the template eid, this method
 * build and returns the URL to the requested resource specific
 * for the registration page.
 *
 * @param {string} asset the asset name
 * @param {string} eid the eid of the template
 *
 * @returns {string} the compiled URL to the desired asset
 */
export function getTemplateRegAssetSrc(asset, eid) {
    return interpolateUrl(TEMPLATE_REG_ASSETS_ENDPOINT, { asset, eid });
}

/**
 * Given an uncompiled URL, a branding, asset name and an optional event, this method
 * reconstructs the correct asset URL.
 *
 * @param {string} url the uncompiled base URL
 * @param {string} asset the asset name
 * @param {InvitationEvent} [event] an event object
 * @param {string} [branding] the branding name
 *
 * @returns {string} the compiled URL to the desired asset
 */
export function transformUrl(url, asset, event, branding) {
    if (event) {
        event.eid = event.eid || event._id;

        // Backward compat
        event.node = event.node || event.node_url;
        return interpolateUrl(url, { ...event, asset, branding });
    }

    return interpolateUrl(url, { asset, branding });
}
