<template lang="pug">
button.btn.login-button(v-if="enabled" @click="logUser")
    | {{ buttonText }}
    i.icon-arrow-right(v-if="canAccessEvent")
</template>
<script>
// Utils
import { get } from 'lodash';

// Components
import LoginModal from '@/components/registration/LoginModal.vue';

export default {
    name: 'LoginButton',

    props: {
        config: {
            type: Object,
            required: true
        },
    },

    data() {
        return {
            enabled: false
        };
    },

    computed: {
        /** @returns {boolean} */
        canAccessEvent() {
            return Boolean(get(this.config, 'user.activationUrl'));
        },

        /** @returns {import('vue-i18n').TranslateResult} */
        buttonText() {
            if (this.canAccessEvent) {
                return this.$i18n.t('registration.actions.continue_to_event');
            }

            return this.$i18n.t('registration.actions.login');
        },
    },

    watch: {
        config: {
            immediate: true,
            handler() {
                this.enabled = this.$services.publicLogin.canLogin(this.config);
            }
        }
    },

    methods: {
        /**
         * Applies different logics depending on the user
         * status in order to log the user into the event.
         */
        logUser() {
            if (this.canAccessEvent) {
                this.goToApp();
            } else if (this.config.settings.continue_to_branding) {
                this.redirectToBranding();
            }
            else {
                this.openLoginModal();
            }
        },

        /**
         * Logs the user in the event's app
         */
        async goToApp() {
            try {
                const url = get(this.config, 'user.activationUrl');
                if (url) {
                    this.$services.routing.redirect(url);
                    return;
                }
                throw new Error();

            } catch (error) {
                console.error('[LoginButton] Could not login:', error.message);

                this.$modal.show('dialog', {
                    text: this.$i18n.t('login.error-internal-error'),
                    buttons: [
                        {
                            title: this.$i18n.t('a11y.actions.close'),
                            default: true,
                            class: 'btn btn-primary btn-medium'
                        }
                    ]
                });
            }
        },

        /**
         * Prompts the user for email
         */
        openLoginModal() {
            this.$modal.show(LoginModal, {
                eventDetails: this.config.event_details,
                settings: this.config.settings
            }, {
                height: 'auto',
                reset: true,
            });
        },

        /**
         * Redirects the user to the branding page
         */
        redirectToBranding() {
            const branding = this.config.event_details.branded_app;
            this.$services.routing.redirect(`/login/${branding}`);
        }
    }
}
</script>
